import { FC } from 'react';
import { Box, Grid, Button } from '@mui/material';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import {
	TFundsAndCommitments,
	ICapitalActivityDataProps,
	ISingleContributionProps,
	ISingleFundAndCommitment,
	ISingleCommitment,
} from './types';

export const CapitalActivityData: FC<ICapitalActivityDataProps> = ({
	commitementsToShow,
}) => {
	const classes = useStyles();

	const fundsAndCommitments: TFundsAndCommitments = commitementsToShow.map(
		(c: ISingleCommitment) => {
			return {
				fundName: c.fundName,
				amount: c.amount || '-',
			};
		},
	);

	const Headings: FC = () => (
		<Grid container sx={{ marginTop: '1rem' }}>
			<Grid item xs={6}>
				<Typography className={classes.investorTitle}>Investment</Typography>
			</Grid>
			<Grid item xs={6}>
				<Typography className={classes.investorTitle}>Commitment</Typography>
			</Grid>
		</Grid>
	);

	const SingleContribution: FC<ISingleContributionProps> = ({ commitment }) => (
		<Grid container className={classes.commitmentEntryGrid}>
			<Grid item xs={6}>
				<Typography className={classes.typographyData}>
					{commitment.fundName}
				</Typography>
			</Grid>
			<Grid item xs={6}>
				<Typography className={classes.typographyData}>
					{commitment.amount}
				</Typography>
			</Grid>
		</Grid>
	);

	const Contributions: FC = () => (
		<Box>
			{fundsAndCommitments?.map(
				(commitment: ISingleFundAndCommitment, i: number) => (
					<SingleContribution commitment={commitment} key={i} />
				),
			)}
		</Box>
	);

	return (
		<Box>
			<Typography variant="h4">Investment Summary</Typography>
			<Headings />
			<Contributions />
		</Box>
	);
};
