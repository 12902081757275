import React from 'react';
import { makeStyles, Container, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

interface ILinkItem {
	text: string;
	link?: string;
}
interface IAdminBreadcrumbs {
	list: ILinkItem[];
}

const useStyles = makeStyles(() => ({
	root: {
		margin: 0,
		display: 'flex',
		flexDirection: 'row',
	},
	seperator: {
		marginLeft: 10,
		marginRight: 20,
	},
	link: {
		textDecoration: 'underline',
		cursor: 'pointer',
	},
	last: {
		cursor: 'pointer',
	},
}));

const AdminBreadcrumbs: React.FC<IAdminBreadcrumbs> = ({ list }) => {
	const classes = useStyles();
	const history = useHistory();
	return (
		<Container classes={{ root: classes.root }}>
			{list.map((item, i) => {
				return (
					<div
						key={i}
						className={classes.root}
						onClick={() => (item.link ? history.push(item.link) : () => {})}
					>
						<Typography
							className={i + 1 < list.length ? classes.link : classes.last}
							variant="h5"
						>
							{item.text}
						</Typography>
						{i + 1 < list.length && (
							<Typography className={classes.seperator} variant="h5">
								/
							</Typography>
						)}
					</div>
				);
			})}
		</Container>
	);
};

export default AdminBreadcrumbs;
