import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Button, DialogActions } from '@mui/material';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { IActionButtonsProps } from './types';

export const ActionButtons: FC<IActionButtonsProps> = ({
	inviting,
	handleClose,
	handleSubmit,
}) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	return (
		<DialogActions>
			<Button
				onClick={handleClose}
				sx={{ color: `${sponsorColor}`, textTransform: 'none' }}
			>
				Cancel
			</Button>
			<Button
				variant="contained"
				onClick={handleSubmit}
				disabled={inviting}
				sx={{ backgroundColor: `${sponsorColor}`, textTransform: 'none' }}
			>
				{inviting ? 'Please wait...' : 'Invite'}
			</Button>
		</DialogActions>
	);
};
