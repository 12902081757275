import {
	Button,
	Box,
	Typography,
	LinearProgress,
	makeStyles,
	TextField,
	IconButton,
} from '@material-ui/core';
import { Paper } from '@mui/material';
import {
	useGetOrganizationQuery,
	useUpdateHeaderColorMutation,
} from 'api/redux/services/organizations.service';
import { FC, useEffect, useState } from 'react';
import { Edit } from '@material-ui/icons';
import { ISponsor } from 'app/types/sponsor.types';
import { defaultColor } from 'api/redux/siteColorReducer';

const useStyles = makeStyles({
	wrapper: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
		gap: '1em',
	},
	container: {
		marginLeft: 'auto',
		marginRight: 'auto',

		marginTop: 15,
		marginBottom: 15,
		padding: 15,
	},
	buttonContainer: {
		marginTop: 15,
		marginRight: 55,
		display: 'flex',
		justifyContent: 'flex-end',
	},
	progress: {
		width: '80%',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	uneditable: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
	},
	colorBox: {
		width: 30,
		height: 30,
		border: '2px solid black',
		padding: 2,
		backgroundColor: 'white',
		flexShrink: 0,
	},
});

interface UpdateHeaderColorProps {
	sponsor: ISponsor;
}

export const UpdateHeaderColor: FC<UpdateHeaderColorProps> = ({ sponsor }) => {
	const classes = useStyles();
	const [isEditing, setIsEditing] = useState(false);
	const [color, setColor] = useState(sponsor.headerColor ?? '');
	const [updateHeaderColor, { data: updatedColor, isLoading }] =
		useUpdateHeaderColorMutation();
	const { refetch } = useGetOrganizationQuery(sponsor.id);

	useEffect(() => {
		refetch();
	}, [updatedColor, refetch]);

	async function handleSubmit() {
		await updateHeaderColor({ data: color || undefined, sponsor: sponsor });
		setIsEditing(false);
	}

	function toggleEdit() {
		const willBeEditing = !isEditing;
		setIsEditing(willBeEditing);
		if (!willBeEditing) {
			setColor(sponsor.headerColor ?? '');
		}
	}

	function ColorBox() {
		return (
			<Box className={classes.colorBox} onClick={toggleEdit}>
				<div
					style={{
						width: '100%',
						height: '100%',
						backgroundColor: color || defaultColor,
					}}
				></div>
			</Box>
		);
	}

	return (
		<Paper>
			{isEditing ? (
				<div className={classes.container}>
					<Box className={classes.progress}>
						{isLoading && <LinearProgress color="secondary" />}
					</Box>

					<div>
						<div className={classes.wrapper}>
							<Typography variant={'h4'} style={{ whiteSpace: 'nowrap' }}>
								Header Color
							</Typography>
							<ColorBox />
							<TextField
								label="Color"
								variant="outlined"
								fullWidth
								inputProps={{
									style: {
										borderBottom: 'none',
										paddingLeft: 20,
									},
								}}
								value={color}
								onChange={(event) => setColor(event.target.value)}
							/>
							<IconButton onClick={toggleEdit}>
								<Edit />
							</IconButton>
						</div>

						<div className={classes.buttonContainer}>
							<Button variant="contained" onClick={handleSubmit}>
								Save
							</Button>
						</div>
					</div>
				</div>
			) : (
				<div className={classes.container}>
					<div className={classes.uneditable}>
						<div className={classes.wrapper}>
							<Typography variant={'h4'}>Header Color</Typography>
							<ColorBox />
							<span>{sponsor.headerColor}</span>
						</div>
						<IconButton size={'small'} onClick={() => setIsEditing(!isEditing)}>
							<Edit />
						</IconButton>
					</div>
				</div>
			)}
		</Paper>
	);
};

export default UpdateHeaderColor;
