import { ERoutes } from 'App';
import { useLocation } from 'react-router-dom';
import { FC, ReactElement } from 'react';
import { SponsorDropdown } from './SponsorDropdown';
import { FundDropdown } from './FundDropdown';
import { InvestorDropdown } from './InvestorDropdown';
import { matchPath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { FlexDirection } from '../types';

export const DropDowns: FC = (): ReactElement => {
	const { pathname } = useLocation();
	const grants = useSelector(selectDropdown);
	const { availableSponsors } = grants.grants;
	const dropdownsFlexDirection: FlexDirection = FlexDirection.ROW;
	const isSpecificDocumentPage = matchPath(pathname, ERoutes.SpecificDocument);
	const isBulkUploadUsersPage = matchPath(pathname, ERoutes.BulkUploadUsers);

	if (
		pathname.includes('admin') ||
		pathname.includes('Super Admin') ||
		!availableSponsors.length ||
		isSpecificDocumentPage ||
		isBulkUploadUsersPage
	)
		return <></>;

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: dropdownsFlexDirection,
				flexWrap: 'wrap',
				justifyContent: 'right',
			}}
		>
			<SponsorDropdown />
			<FundDropdown />
			<InvestorDropdown />
		</div>
	);
};
