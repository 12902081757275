import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
	root: {
		width: '100%',
		paddingRight: 5,
		paddingLeft: 5,
		paddingTop: 5,
	},
	filtersContainer: {
		padding: 5,
		height: '100%',
		overflow: 'auto',
		borderRadius: '0px',
		borderLeft: '1px solid #D0D7DE',
	},
});
