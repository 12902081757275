import { GetApp } from '@mui/icons-material';
import { IconBtn } from 'common/components/IconBtn';
import { FC, useState } from 'react';
import { ExportUsersCSVModal } from './export-users-csv-modal/ExportUsersCSVModal';

export const DownloadUsersButton: FC = () => {
	const [openModal, setOpenModal] = useState<boolean>(false);

	return (
		<>
			<IconBtn
				onClick={() => setOpenModal(true)}
				customColor={true}
				tooltip="Download users CSV"
			>
				<GetApp />
			</IconBtn>
			<ExportUsersCSVModal
				open={openModal}
				onClose={() => setOpenModal(false)}
			/>
		</>
	);
};
