import { useAuth0 } from '@auth0/auth0-react';
import {
	useLazyGetInvestorCapitalActivityQuery,
	useLazyGetFundCapitalActivityQuery,
} from 'api/redux/services/capitalActivityApi';
import { FC, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { Alert } from '@mui/material';
import { CapitalAccountStatementTable } from './Deprecated/CapitalAccountStatementTable';
import { CapitalAccountStatementHeader } from './CapitalAccountStatementHeader';
import { CapitalAccountStatementDisclaimer } from './CapitalAccountStatementDisclaimer';
import { useDispatch, useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { useAuth0Grants } from 'common/helpers/permissions/useGetAuth0Grants';
import { useLazyGetInvestorPermissionsQuery } from 'api/redux/services/investors.service';
import { ReportTypeNames } from '../ui/Reports';
import { useStyles } from './styles';
import {
	ICapitalAccountStatementProps,
	ICapitalActivityTableProps,
} from './types';
import ICapitalActivity, { ITableViewProps } from './capital-activity.types';
import { CASTable } from './CASTable/CASTable';
import {
	onRemoveRedirectURL,
	redirectURLState,
} from 'api/redux/RedirectURLReducer';

const CapitalActivityTable: FC<ICapitalActivityTableProps> = ({
	visible,
	capitalActivityData,
	fundCapitalActivityData,
}) => {
	const classes = useStyles();

	if (
		!visible ||
		capitalActivityData === undefined ||
		fundCapitalActivityData === undefined
	)
		return <></>;

	const InactiveTableView: FC = () => {
		return (
			<Box className={classes.tableBox}>
				<CapitalAccountStatementTable
					data={capitalActivityData}
					fundData={fundCapitalActivityData}
				/>
				<CapitalAccountStatementDisclaimer />
			</Box>
		);
	};

	const ActiveTableView: FC = () => {
		return (
			<Box className={classes.tableBox}>
				<CASTable
					data={capitalActivityData}
					fundData={fundCapitalActivityData}
				/>
				<CapitalAccountStatementDisclaimer />
			</Box>
		);
	};

	if (!capitalActivityData.capitalActivityTemplate.active) {
		return <InactiveTableView />;
	}

	return <ActiveTableView />;
};

const TableView: FC<ITableViewProps> = ({
	currentReportingPeriod,
	setError,
	capitalActivityData,
	loadCapitalActivity,
	fetchCapitalActivity,
	hasPermission,
	canShowTable,
	foundCapitalActivity,
	fundCapitalActivityData,
	investorPermissions,
}) => {
	const classes = useStyles();

	return (
		<Box className={classes.reportsBox}>
			<CapitalAccountStatementHeader
				currentReportingPeriod={currentReportingPeriod || ''}
				setError={setError}
				capitalActivityData={capitalActivityData}
				loadCapitalActivity={loadCapitalActivity}
				investorAllowedReportTypes={investorPermissions?.reportTypes || []}
				afterDelete={fetchCapitalActivity}
				hasPermission={hasPermission}
			/>
			<CapitalActivityTable
				visible={canShowTable}
				capitalActivityData={foundCapitalActivity}
				fundCapitalActivityData={fundCapitalActivityData}
			/>
		</Box>
	);
};

export const CapitalAccountStatement: FC<ICapitalAccountStatementProps> = ({
	period,
}) => {
	const dispatch = useDispatch();
	const redirectURL = useSelector(redirectURLState);
	const { user } = useAuth0();
	const grants = useSelector(selectDropdown);
	const { currentInvestor, currentFund } = grants.grants;
	const { isAdmin } = useAuth0Grants();
	const [getCapitalActivity, { data: capitalActivityData = [] }] =
		useLazyGetInvestorCapitalActivityQuery();
	const [getFundCapitalActivity, { data: fundCapitalActivityData }] =
		useLazyGetFundCapitalActivityQuery();
	const [error, setError] = useState<string | undefined>(undefined);
	const [currentReportingPeriod, setCurrentReportingPeriod] = useState<
		string | undefined
	>(period);
	const [fetchInvestorPermissions, investorPermissions] =
		useLazyGetInvestorPermissionsQuery();
	const [hasPermission, setHasPermission] = useState<boolean>(false);
	const [foundCapitalActivity, setFoundCapitalActivity] = useState<
		ICapitalActivity | undefined
	>(undefined);

	const canShowTable: boolean =
		(isAdmin || hasPermission) &&
		currentInvestor.id !== 0 &&
		foundCapitalActivity !== undefined;

	const loadCapitalActivity = (e) => {
		setCurrentReportingPeriod(e.target.value as string);
	};

	const fetchCapitalActivity = () => {
		if (currentInvestor.id === 0 || currentInvestor.id === 0) return;

		getCapitalActivity({
			investorId: currentInvestor.id,
			fundId: currentFund.id,
		});
	};

	useEffect(() => {
		if (isAdmin) setHasPermission(true);

		if (isAdmin || currentInvestor?.id === 0) return;

		fetchInvestorPermissions({ investorId: currentInvestor.id });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentInvestor.id]);

	useEffect(() => {
		if (!investorPermissions.data?.reportTypes) return;

		const { reportTypes } = investorPermissions.data;

		setHasPermission(
			reportTypes.find(
				(rt) => rt.name === ReportTypeNames.CAPITAL_ACCOUNT_STATEMENT,
			) !== undefined,
		);
	}, [investorPermissions.data]);

	useEffect(() => {
		if (currentInvestor?.id !== 0) {
			getCapitalActivity({
				investorId: currentInvestor.id,
				fundId: currentFund.id,
			});
		}
	}, [user, currentInvestor, currentFund.id, getCapitalActivity]);

	useEffect(() => {
		if (!capitalActivityData.length || (currentReportingPeriod && period))
			return;

		setCurrentReportingPeriod(capitalActivityData[0].reportingPeriod);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [capitalActivityData, period]);

	useEffect(() => {
		let foundCas = capitalActivityData.find(
			(f) => f.reportingPeriod === currentReportingPeriod,
		);

		if (foundCas === undefined)
			foundCas = capitalActivityData.find(
				(f) => f.reportingPeriod === capitalActivityData[0].reportingPeriod,
			);

		if (foundCas === undefined) {
			setFoundCapitalActivity(undefined);
			return;
		}

		setFoundCapitalActivity(foundCas);
		getFundCapitalActivity(foundCas.fundCapitalActivityId);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentReportingPeriod, capitalActivityData]);

	if (error)
		return (
			<Alert severity="error" variant="filled">
				{error}
			</Alert>
		);

	if (redirectURL) {
		dispatch(onRemoveRedirectURL({}));
	}

	return (
		<TableView
			currentReportingPeriod={currentReportingPeriod}
			setError={setError}
			capitalActivityData={capitalActivityData}
			loadCapitalActivity={loadCapitalActivity}
			fetchCapitalActivity={fetchCapitalActivity}
			hasPermission={hasPermission}
			canShowTable={canShowTable}
			foundCapitalActivity={foundCapitalActivity}
			fundCapitalActivityData={fundCapitalActivityData}
			investorPermissions={investorPermissions.data}
		/>
	);
};
