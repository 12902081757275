import { useAuth0 } from '@auth0/auth0-react';
import { Alert } from '@mui/material';
import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';
import { useAuth0Grants } from 'common/helpers/permissions/useGetAuth0Grants';
import { Redirect, Route } from 'react-router-dom';

interface IProtectedRouteParams {
	component: any;
	roles: string[];
	errorMessage?: string;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ProtectedRoute: any = ({
	component: Component,
	roles,
	errorMessage,
	...rest
}: IProtectedRouteParams) => {
	const { isAuthenticated } = useAuth0();
	const { currentRoles: userRoles } = useAuth0Grants();

	if (isAuthenticated) {
		return (
			<Route
				{...rest}
				render={(props) =>
					userRoles.some((role) => roles.includes(role)) ? (
						<Component {...props} />
					) : errorMessage ? (
						<MainSidebarContainer>
							<Alert severity="error" variant="filled">
								{errorMessage}
							</Alert>
						</MainSidebarContainer>
					) : (
						<Redirect to={{ pathname: '/', state: { from: props.location } }} />
					)
				}
			/>
		);
	}

	return <Redirect to={{ pathname: '/' }} />;
};

export default ProtectedRoute;
