import React, { ReactElement, useEffect, useState } from 'react';
import { Home } from '../Home';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { useAuth0 } from '@auth0/auth0-react';

type AuthWrapperProps = {
	children: ReactElement<unknown>;
};

const AuthWrapper = ({ children }: AuthWrapperProps): JSX.Element => {
	const { isAuthenticated, isLoading, getAccessTokenSilently, error } =
		useAuth0();
	const [accessToken, setAccessToken] = useState<string | undefined>();

	useEffect(() => {
		if (isAuthenticated) {
			getAccessTokenSilently().then((token: string) => {
				sessionStorage.setItem('token', `${token}`);
				if (token) setAccessToken(token);
			});
		}
	}, [isAuthenticated, getAccessTokenSilently]);

	if (isLoading) return <LoadingSpinner />;
	if (error) return <>{error}</>;
	if (!isAuthenticated) return <Home />;

	return accessToken ? children : <LoadingSpinner />;
};

export default AuthWrapper;
