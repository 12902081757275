import { Table as MuiTable, withStyles } from '@material-ui/core';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
	messageParagraph: {
		paddingRight: '10px',
	},
	messageBox: {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'flex-end',
		paddingRight: '10px',
		textAlign: 'right',
	},
	reportsBox: {
		height: '100%',
		width: '100%',
		display: 'flex',
		paddingTop: '5px',
		paddingLeft: '5px',
		paddingBottom: '10px',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	tableBox: {
		width: 'min(100%, 1200px)',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		flexGrow: 9,
	},
	tablePaper: {
		paddingBottom: 10,
		border: '1px solid #D0D7DE',
		borderRadius: '5px 5px 0px 0px',
		overflow: 'hidden',
	},
	headerPaper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexGrow: 4,
		height: '7vh',
		padding: '5px',
		overflow: 'hidden',
		border: '1px solid #D0D7DE',
		alignSelf: 'flex-start',
	},
	headerBox: {
		width: 'min(100%, 1200px)',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexGrow: 2,
	},
	headerText: {
		paddingLeft: '12px',
	},
	headerOptions: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '2rem',
	},
	periodDropdown: {
		minWidth: 350,
		fontSize: '1rem',
	},
	periodSelector: {
		width: 350,
		color: '#000000 !important',
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: '#FFFFFF !important',
		},
		'& :focus': {
			backgroundColor: '#FFFFFF',
		},
		textAlign: 'right',
	},
	buttons: {
		float: 'right',
		height: '100%',
	},
	rowLabels: {
		fontSize: 'min(2vh, 1rem)',
	},
	columnLabels: {
		fontSize: 'min(2vh, .9rem)',
		color: '#FFFFFF',
		textAlign: 'center',
	},
	columnSubLabels: {
		fontSize: 'min(2vh, 1rem)',
		textAlign: 'center',
	},
	disclaimerBox: {
		width: 'min(100%, 1200px)',
	},
	disclaimerPaper: {
		height: '100%',
		display: 'flex',
		textAlign: 'center',
		justifyContent: 'center',
		alignItems: 'center',
		borderTopStyle: 'none',
		border: '1px solid #D0D7DE',
		borderRadius: '0px 0px 5px 5px',
	},
});

export const Table = withStyles({
	root: {
		borderCollapse: 'separate',
		'& tr.content-row:hover': {
			backgroundColor: '#eee',
		},
		'& .MuiTableCell-sizeSmall': {
			padding: '0px 10px 0px 10px',
		},
	},
})(MuiTable);

export const TableCell = withStyles({
	root: {
		borderBottom: 'none',
		fontSize: 'min(2vh, 1rem)',
		borderRadius: '0px',
	},
})(MuiTableCell);

export const TableRow = withStyles({
	root: {
		borderBottom: 'none',
		fontSize: 'min(2vh, 1rem)',
	},
})(MuiTableRow);
