import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import { FC } from 'react';
import { useStyles } from './styles';
import EditIcon from '@mui/icons-material/Edit';
import PermissionsWrapper from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { IInvestorMetadataProps } from './types';

export const InvestorMetaData: FC<IInvestorMetadataProps> = ({
	investor,
	onEditClick,
}) => {
	const classes = useStyles();

	return (
		<>
			<Box className={classes.investorTitle}>
				<Typography variant="h4" display="inline">
					{investor.name}
				</Typography>
				<PermissionsWrapper scopes={[SCOPES.canEditInvestorInfo]}>
					<IconButton
						color="inherit"
						className={classes.editIcon}
						onClick={onEditClick}
					>
						<EditIcon />
					</IconButton>
				</PermissionsWrapper>
			</Box>

			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Typography className={classes.dataTitles}>Phone: </Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography className={classes.investorTypographyData}>
						{investor.phone}
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography className={classes.dataTitles}>Email: </Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography className={classes.investorTypographyData}>
						{investor.email}
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography className={classes.dataTitles}>Address: </Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography className={classes.investorTypographyData}>
						{investor.addressLine1}
						{investor.addressLine1 && (
							<Typography display="inline">, </Typography>
						)}
						{investor.addressLine2}
						{investor.addressLine2 && (
							<Typography display="inline">, </Typography>
						)}
						{investor.city}
						{!!investor.city && <Typography display="inline">, </Typography>}
						{investor.state}
						{!!investor.state && <Typography display="inline">, </Typography>}
						{investor.zipcode}
						{!!investor.country && <Typography display="inline">, </Typography>}
						{investor.country}
					</Typography>
				</Grid>
			</Grid>
		</>
	);
};
