import { FC } from 'react';
import { IDropdownProps } from './types';
import { MenuItem, Select } from '@mui/material';
import { useStyles } from './styles';

export const DropDown: FC<IDropdownProps> = ({
	value,
	onChange,
	objects,
	onBlur,
}) => {
	const classes = useStyles();

	return (
		<Select
			className={classes.dropdownInput}
			value={value}
			onChange={onChange}
			onBlur={onBlur}
		>
			{objects.map((object, index) => (
				<MenuItem key={index} value={object.id}>
					{object.name}
				</MenuItem>
			))}
		</Select>
	);
};
