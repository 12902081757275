import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
	orgMemberPaper: {
		width: 'min(100%, 1300px)',
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#FFFFFF',
		borderRadius: '5px',
	},
});
