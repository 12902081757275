import { createApi } from '@reduxjs/toolkit/query/react';
import dynamicBaseQuery from 'app/dynamic-base-query';
import { Organization } from 'app/types/organization.types';
import { Investor as IInvestor } from 'app/types/investor.types';
import { ISponsor } from 'app/types/sponsor.types';
import { ROLES } from 'common/helpers/permissions/Roles';
import { IUsersWithInvestors } from './userApi';
import { DocumentType } from 'app/types/documentTypes.types';
import { ISortStrategy } from '../types';

interface ICreateOrganization {
	name: string;
	subdomain: string;
}

interface IRemoveUserFromSponsorParams {
	userId: number;
	sponsorId: number;
}

interface IUploadLogoParams {
	formData: FormData;
}

interface IUpdateSponsorParams {
	sponsorId: number;
	name: string;
}

interface IUploadWebsiteUrlParams {
	sponsorId: number;
	websiteUrl: string;
}
interface IGetOrganizationsParams {
	auth0UserId?: string;
	role?: string;
}
interface IRemoveUserFromInvestorParams {
	sponsorId: number;
	investorId: number;
	userId: number;
}

export interface ILogo {
	url: string;
}

export interface IGetFundsOfInvestorParams {
	investorId: number;
	sponsorId: number;
}

export interface IDisclaimerResponse {
	disclaimer: string;
}

export interface IGetOrganizationUsersParams {
	sponsorId: number;
	page: number;
	searchString?: string;
	sortStrategy: ISortStrategy[];
}

export interface IUpdateWatermarkPdfParams {
	sponsorId: number;
	watermarkPdf: boolean;
}
export interface IUpdateWatermarkCasPdfParams {
	sponsorId: number;
	watermarkCasPdf: boolean;
}

export interface IUpdateWaterMarkDocTypesParams {
	sponsorId: number;
	waterMarkDocTypes: number[];
}

export enum IExportUsersByRelationShipColumns {
	USER_NAME = 'User Name',
	USER_EMAIL = 'User Email',
	ROLE = 'Role',
	INVESTOR_NAME = 'Investor Name',
	FUND_NAME = 'Fund Name',
	ADDRESS_LINE_1 = 'Address Line 1',
	ADDRESS_LINE_2 = 'Address line 2',
	COUNTRY = 'Country',
	STATE = 'State',
	ZIP = 'Zip',
	PHONE = 'Phone',
	EMAIL = 'Email',
	LAST_LOGIN = 'Last Login',
	DOCUMENT_TYPES = 'Document Types',
	REPORT_TYPES = 'Report Types',
}

export enum IExportUsersByUserColumns {
	USER_NAME = 'User Name',
	USER_EMAIL = 'User Email',
	ROLE = 'Role',
	INVESTORS = 'Investors',
	FUNDS = 'Funds',
	LAST_LOGIN = 'Last Login',
	FUND_INVESTORS = 'Fund:Investors',
}

export interface IExportUsersByRelationShipCSVParams {
	sponsorId: number;
	roles: ROLES[];
	columns: IExportUsersByRelationShipColumns[];
	fundIds: number[];
}

export interface IExportUsersByUserCSVParams {
	sponsorId: number;
	roles: ROLES[];
	columns: IExportUsersByUserColumns[];
}

export const organizationsApi = createApi({
	reducerPath: 'organizationsApi',
	baseQuery: dynamicBaseQuery('organizations'),
	tagTypes: ['Organizations', 'Funds', 'Users', 'Investor', 'SponsorInvestors'],
	endpoints: (builder) => ({
		getOrganizations: builder.query<ISponsor[], IGetOrganizationsParams>({
			query: ({ auth0UserId, role = 'Super Admin' }: IGetOrganizationsParams) =>
				`?auth0UserId=${auth0UserId}&role=${role}`,
			providesTags: [{ type: 'Organizations', id: 'LIST' }],
		}),
		getOrganization: builder.query<ISponsor, number>({
			query: (sponsorId) => `${sponsorId}`,
			providesTags: (result, error, id) => [{ type: 'Organizations', id }],
		}),
		createOrganization: builder.mutation<Organization, ICreateOrganization>({
			query: ({ name, subdomain }) => ({
				url: ``,
				method: 'POST',
				body: { name, subdomain },
			}),
			invalidatesTags: [{ type: 'Organizations', id: 'LIST' }],
		}),
		getOrganizationUsers: builder.query<
			IUsersWithInvestors,
			IGetOrganizationUsersParams
		>({
			query: ({ sponsorId, page, searchString, sortStrategy }) =>
				`${sponsorId}/users?page=${page}&searchString=${searchString}&sortBy=${sortStrategy.map(
					(ss: ISortStrategy) => `${ss.column}:${ss.ascdesc}`,
				)}`,
		}),
		getInvestorById: builder.query<IInvestor, string>({
			query: (investorId) => `investor-by-id?investor=${investorId}`,
			providesTags: (result, error, id) => [{ type: 'Investor', id }],
		}),
		removeUserFromInvestor: builder.mutation<
			void,
			IRemoveUserFromInvestorParams
		>({
			query: ({ sponsorId, investorId, userId }) => ({
				url: `remove-user-from-investor?sponsorId=${sponsorId}&investorId=${investorId}&userId=${userId}`,
				method: 'DELETE',
			}),
			invalidatesTags: [{ type: 'Users' }],
		}),
		removeUserFromSponsor: builder.mutation<void, IRemoveUserFromSponsorParams>(
			{
				query: ({ sponsorId, userId }) => ({
					url: `remove-user-from-sponsor?sponsorId=${sponsorId}&userId=${userId}`,
					method: 'DELETE',
				}),
				invalidatesTags: [{ type: 'Users' }],
			},
		),
		getAllInvestors: builder.query<IInvestor[], void>({
			query: () => `all-investors`,
		}),
		uploadLightLogo: builder.mutation<void, IUploadLogoParams>({
			query: ({ formData }) => ({
				url: `light-logo`,
				method: 'POST',
				body: formData,
			}),
		}),
		uploadDarkLogo: builder.mutation<void, IUploadLogoParams>({
			query: ({ formData }) => ({
				url: `dark-logo`,
				method: 'POST',
				body: formData,
			}),
		}),
		getDarkLogo: builder.query<ILogo, number>({
			query: (sponsorId) => `${sponsorId}/dark-logo`,
		}),
		getLightLogo: builder.query<ILogo, number>({
			query: (sponsorId) => `${sponsorId}/light-logo`,
		}),
		getWatermarkDocTypes: builder.query<DocumentType[], number>({
			query: (sponsorId) => `${sponsorId}/watermarkDocTypes`,
		}),
		updateWaterMarkPdf: builder.mutation<void, IUpdateWatermarkPdfParams>({
			query: ({ sponsorId, watermarkPdf }) => ({
				url: `${sponsorId}/watermarkPdfs`,
				method: 'PATCH',
				body: {
					watermarkPdf,
				},
			}),
		}),
		updateWaterMarkCasPdf: builder.mutation<void, IUpdateWatermarkCasPdfParams>(
			{
				query: ({ sponsorId, watermarkCasPdf }) => ({
					url: `${sponsorId}/watermarkCasPdf`,
					method: 'PATCH',
					body: {
						watermarkCasPdf,
					},
				}),
			},
		),
		updateWatermarkDocTypes: builder.mutation<
			void,
			IUpdateWaterMarkDocTypesParams
		>({
			query: ({ sponsorId, waterMarkDocTypes }) => ({
				url: `${sponsorId}/waterMarkDocTypes`,
				method: 'POST',
				body: {
					docTypeIds: waterMarkDocTypes.join(','),
				},
			}),
		}),
		deleteSponsor: builder.mutation<void, number>({
			query: (sponsorId) => ({
				url: `${sponsorId}`,
				method: 'DELETE',
			}),
			invalidatesTags: [{ type: 'Organizations', id: 'LIST' }],
		}),
		updateSponsor: builder.mutation<void, IUpdateSponsorParams>({
			query: ({ name, sponsorId }) => ({
				url: `${sponsorId}`,
				method: 'PUT',
				body: { name },
			}),
		}),
		updateWebsiteUrl: builder.mutation<void, IUploadWebsiteUrlParams>({
			query: ({ websiteUrl, sponsorId }) => ({
				url: `update-website-url?sponsorId=${sponsorId}`,
				method: 'PATCH',
				body: { websiteUrl },
			}),
		}),
		updateHeaderColor: builder.mutation<
			string,
			{ data: string | undefined; sponsor: ISponsor }
		>({
			query: ({ data, sponsor }) => ({
				url: 'header-color',
				method: 'POST',
				body: { data },
				params: { sponsor: sponsor.id },
			}),
		}),
		getDisclaimer: builder.query<IDisclaimerResponse, number>({
			query: (sponsorId) => `disclaimer?sponsorId=${sponsorId}`,
		}),
		getOrganizationUsersByRelationshipCsv: builder.query<
			string,
			IExportUsersByRelationShipCSVParams
		>({
			query: ({ sponsorId, roles, columns, fundIds }) => ({
				url: `${sponsorId}/export-users-by-relationship`,
				method: 'POST',
				headers: {
					Accept: 'text/csv',
				},
				body: {
					roles,
					columns,
					fundIds: fundIds.join(','),
				},
				responseHandler: (response) => response.text(),
			}),
		}),
		getOrganizationUsersByUserCsv: builder.query<
			string,
			IExportUsersByUserCSVParams
		>({
			query: ({ sponsorId, roles, columns }) => ({
				url: `${sponsorId}/export-users-by-user`,
				method: 'POST',
				headers: {
					Accept: 'text/csv',
				},
				body: {
					roles,
					columns,
				},
				responseHandler: (response) => response.text(),
			}),
		}),
		updateDisclaimer: builder.mutation<
			string,
			{ data: string; sponsor: ISponsor }
		>({
			query: ({ data, sponsor }) => ({
				url: 'disclaimer',
				method: 'POST',
				body: { data },
				params: { sponsorId: sponsor.id },
			}),
		}),
		getOrganizationBySubdomain: builder.query<ISponsor, string>({
			query: (subdomain) => `subdomain?subdomain=${subdomain}`,
			providesTags: (result, error, subdomain) => [
				{ type: 'Organizations', subdomain },
			],
		}),
	}),
});

export const {
	useGetOrganizationsQuery,
	useLazyGetOrganizationQuery,
	useLazyGetOrganizationsQuery,
	useGetOrganizationQuery,
	useCreateOrganizationMutation,
	useLazyGetAllInvestorsQuery,
	useUploadLightLogoMutation,
	useUploadDarkLogoMutation,
	useLazyGetInvestorByIdQuery,
	useGetDarkLogoQuery,
	useLazyGetDarkLogoQuery,
	useGetLightLogoQuery,
	useLazyGetWatermarkDocTypesQuery,
	useUpdateWaterMarkPdfMutation,
	useUpdateWaterMarkCasPdfMutation,
	useUpdateWatermarkDocTypesMutation,
	useLazyGetLightLogoQuery,
	useLazyGetOrganizationUsersQuery,
	useDeleteSponsorMutation,
	useUpdateSponsorMutation,
	useRemoveUserFromInvestorMutation,
	useRemoveUserFromSponsorMutation,
	useUpdateWebsiteUrlMutation,
	useUpdateHeaderColorMutation,
	useLazyGetDisclaimerQuery,
	useUpdateDisclaimerMutation,
	useLazyGetOrganizationUsersByRelationshipCsvQuery,
	useLazyGetOrganizationUsersByUserCsvQuery,
	useGetOrganizationBySubdomainQuery,
	useLazyGetOrganizationBySubdomainQuery,
} = organizationsApi;
