import { FC, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, TextField, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { InvestorRowProps } from './types';
import {
	validateInvestorId,
	validateName,
} from 'modules/investor-view/investor-info/utils';

export const InvestorRow: FC<InvestorRowProps> = ({
	investor,
	index,
	updateInvestor,
	existingInvestors,
}) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const [selectedName, setSelectedName] = useState(investor.name);
	const [selectedInvestorId, setSelectedInvestorId] = useState(
		investor.investorId,
	);
	const [overrideData, setOverrideData] = useState(false);
	const [alreadyExists, setAlreadyExists] = useState(investor.alreadyExists);
	const [investorIdError, setInvestorIdError] = useState<boolean>(false);
	const [nameError, setNameError] = useState<boolean>(false);
	const [emptyNameError, setEmptyNameError] = useState(false);
	const [emptyIdError, setEmptyIdError] = useState(false);

	const triggerInvestorUpdate = () => {
		updateInvestor(index, {
			...investor,
			alreadyExists,
			overrideData,
			name: selectedName,
			investorId: selectedInvestorId,
			hasError: investorIdError || nameError || emptyIdError || emptyNameError,
		});
	};

	useEffect(() => {
		triggerInvestorUpdate();
	}, [
		overrideData,
		alreadyExists,
		selectedName,
		selectedInvestorId,
		investorIdError,
		nameError,
		emptyIdError,
		emptyNameError,
	]);

	const validateInvestorName = () => {
		if (selectedName === '') {
			setEmptyNameError(true);
			return;
		}

		setEmptyNameError(false);

		validateName({
			name: selectedName,
			setNameError,
			investor: undefined,
			investors: existingInvestors,
			investorId: selectedInvestorId,
		});
	};

	const validateId = () => {
		if (selectedInvestorId === 'INV') {
			setEmptyIdError(true);
			return;
		}

		setEmptyIdError(false);

		validateInvestorId({
			investorId: selectedInvestorId,
			setInvestorIdError,
			investor: undefined,
			investors: existingInvestors,
			name: selectedName,
			setInvestorId: setSelectedInvestorId,
		});
	};

	const validateExists = () => {
		setAlreadyExists(
			existingInvestors?.some(
				(inv) =>
					inv.name === selectedName || inv.investorId === selectedInvestorId,
			),
		);
	};

	useEffect(() => {
		validateInvestorName();
		validateId();
		validateExists();
	}, [selectedName, selectedInvestorId]);

	return (
		<>
			<tr key={index}>
				<td>
					<TextField
						value={selectedName}
						onChange={(e) => setSelectedName(e.target.value)}
						error={nameError}
						helperText={
							emptyNameError
								? "Name can't be empty"
								: nameError
								? 'Already exists'
								: ''
						}
						inputProps={{
							style: {
								borderBottom: 'none',
								paddingLeft: 20,
								fontSize: 12,
								height: '2rem',
								marginBottom: 0,
							},
						}}
						variant="outlined"
					/>
				</td>
				<td>
					<TextField
						value={selectedInvestorId}
						onChange={(e) => setSelectedInvestorId(e.target.value)}
						error={investorIdError}
						helperText={
							emptyIdError
								? "investorId can't be empty"
								: investorIdError
								? 'Already exists'
								: ''
						}
						inputProps={{
							style: {
								borderBottom: 'none',
								paddingLeft: 20,
								fontSize: 12,
								height: '2rem',
								marginBottom: 0,
							},
						}}
						variant="outlined"
					/>
				</td>
				<td>{investor.phone}</td>
				<td>{investor.email}</td>
				<td>{investor.address1}</td>
				<td>{investor.address2}</td>
				<td>{investor.city}</td>
				<td>{investor.state}</td>
				<td>{investor.zip}</td>
				<td>{investor.country}</td>
				<td>{investor.note}</td>
				<td>
					{alreadyExists && !nameError && !investorIdError ? (
						<Tooltip
							title={
								'This investor already exists. Check this to override its data'
							}
						>
							<FormControlLabel
								label={''}
								id="existing-investor-label"
								style={{
									padding: '10px 0px 0px 5px',
								}}
								control={
									<Checkbox
										size="small"
										aria-labelledby="existing-investor-label"
										checked={overrideData}
										onChange={() => setOverrideData(!overrideData)}
										style={{
											color: sponsorColor,
										}}
									/>
								}
							/>
						</Tooltip>
					) : (
						<></>
					)}
				</td>
			</tr>
		</>
	);
};
