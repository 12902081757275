import { FC, useState } from 'react';
import { Box, Paper } from '@mui/material';
import { Typography } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import { useProcessBulkUsersInviteCsvMutation } from 'api/redux/services/userApi';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { ErrorMessage } from 'common/components/UsersTable/add-user-modal/ErrorMessage';
import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';
import { BulkUploadUserTemplate } from './BulkUploadUserTemplate';
import { UserRows } from './UserRows';
import { useStyles } from './styles';
import { IconBtn } from 'common/components/IconBtn';
import { PersonAddAlt1 } from '@mui/icons-material';
import { ROLES } from 'common/helpers/permissions/Roles';
import { ICSVUser } from './types';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { useSelector } from 'react-redux';

export const BulkUploadUsers: FC = () => {
	const grants = useSelector(selectDropdown);
	const { currentSponsor } = grants.grants;
	const classes = useStyles();
	const [csvUploadError, setCsvUploadError] = useState<string>('');
	const [processBulkUsersInviteCsv, csvResult] =
		useProcessBulkUsersInviteCsvMutation();
	const [users, setUsers] = useState<ICSVUser[]>([]);

	const onUpdateUser = (updatedVersion: ICSVUser) => {
		const newUsers = users.map((u) =>
			u.id === updatedVersion.id ? updatedVersion : u,
		);

		setUsers([...newUsers]);
	};

	const onDeleteUser = (userId: number) => {
		const newUsers = users.filter((u) => u.id !== userId);

		setUsers([...newUsers]);
	};

	const onAddUser = () => {
		const newUser = (): ICSVUser => {
			const userIds = users.map((u) => u.id);
			const maxId = Math.max(...userIds);

			return {
				id: maxId + 1,
				name: '',
				email: '',
				role: ROLES.INVESTOR_USER,
				errors: [],
			};
		};

		setUsers([...users, newUser()]);
	};

	const processFile = async (acceptedFile) => {
		const formData = new FormData();
		formData.append('file', acceptedFile[0]);
		await processBulkUsersInviteCsv({
			formData,
			sponsorId: currentSponsor.id,
		})
			.unwrap()
			.then((response) =>
				setUsers(
					response
						.filter(
							(csvUser) =>
								![
									ROLES.SUPER_ADMIN,
									ROLES.SPONSOR_ADMIN,
									ROLES.SPONSOR_USER,
								].includes(csvUser.role as ROLES),
						)
						.map((e, i) => {
							return { ...e, id: i };
						}),
				),
			);
	};

	const Header: FC = () => {
		return (
			<>
				{csvUploadError !== '' && (
					<ErrorMessage setError={setCsvUploadError} error={csvUploadError} />
				)}
				<Paper
					elevation={0}
					className={classes.uploadCard}
					sx={{ minHeight: '35vh' }}
				>
					<Box className={classes.headerBox}>
						<Typography variant="h3" className={classes.headerTypography}>
							Bulk Upload Users
						</Typography>
						<BulkUploadUserTemplate />
					</Box>
					<Box className={classes.dropZoneContainer}>
						{csvResult.isLoading ? (
							<LoadingSpinner />
						) : (
							<Dropzone
								accept={{ 'text/csv': ['.csv'] }}
								maxFiles={1}
								onDropRejected={() =>
									setCsvUploadError(
										'There was an error uploading the CSV document',
									)
								}
								onDrop={processFile}
							>
								{({ getRootProps, getInputProps }) => (
									<div {...getRootProps()} className="dropzone">
										<input {...getInputProps()} />
										<Typography
											variant="h5"
											className={classes.dropZoneTypography}
											style={{ marginTop: '25%' }}
										>
											Click here to browse files or drag-and-drop the populated
											CSV template here.
										</Typography>
									</div>
								)}
							</Dropzone>
						)}
					</Box>
				</Paper>
			</>
		);
	};

	const Body: FC = () => {
		return (
			<Paper elevation={0} className={classes.inviteCard}>
				<UserRows
					sponsorId={currentSponsor.id}
					users={users}
					onDelete={onDeleteUser}
					onUpdate={onUpdateUser}
				/>
				<Box>
					<IconBtn
						tooltip={'Add a user at the bottom of the list'}
						onClick={() => onAddUser()}
						customColor={true}
					>
						<PersonAddAlt1 />
					</IconBtn>
				</Box>
			</Paper>
		);
	};

	return (
		<MainSidebarContainer>
			<Box className={classes.bulkUploadContainer}>
				<Header />
				<Body />
			</Box>
		</MainSidebarContainer>
	);
};
