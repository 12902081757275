import { Alert } from '@mui/material';
import {
	docsUploadState,
	onSetErrorMessage,
	onSetSuccessMessage,
	onSetWarningMessage,
} from 'api/redux/DocsUploadReducer';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const DocumentUploadAlerts: FC = () => {
	const dispatch = useDispatch();
	const { successMessage, errorMessage, warningMessage } =
		useSelector(docsUploadState);

	return (
		<>
			{successMessage && (
				<Alert
					onClose={() => dispatch(onSetSuccessMessage(false))}
					sx={{ mt: 2, width: '100%' }}
				>
					Document successfully uploaded
				</Alert>
			)}
			{errorMessage && (
				<Alert
					severity="error"
					onClose={() => dispatch(onSetErrorMessage(false))}
					sx={{ mt: 2, width: '100%' }}
				>
					{`An error occured while uploading. Please try again. Details: ${errorMessage}`}
				</Alert>
			)}
			{warningMessage && (
				<Alert
					severity="warning"
					onClose={() => dispatch(onSetWarningMessage(false))}
					sx={{ mt: 2, width: '100%' }}
				>
					By selecting fund level, the documents will be sent to all investors
					in the current fund.
				</Alert>
			)}
		</>
	);
};
