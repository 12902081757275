import { FC } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDropdown, onChangeUserGrants } from 'api/redux/DropdownReducer';
import { onChangeSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { useGetCurrentUserGrantsQuery } from 'api/redux/services/userApi';
import { useLazyGetOrganizationQuery } from 'api/redux/services/organizations.service';
import { matchPath, useLocation } from 'react-router-dom';
import { ERoutes } from 'App';

export const NavbarLoader: FC = ({ children }) => {
	const dispatch = useDispatch();
	const grants = useSelector(selectDropdown);
	const { data: currentUserGrants } = useGetCurrentUserGrantsQuery();
	const { currentSponsor } = grants.grants;
	const [getSponsor, sponsor] = useLazyGetOrganizationQuery();
	const { pathname } = useLocation();
	const isSpecificDocumentPage = matchPath(pathname, ERoutes.SpecificDocument);
	const isCasReportsPage = matchPath(pathname, ERoutes.CasReports);
	const shouldLoadCookies = !isSpecificDocumentPage && !isCasReportsPage;

	useEffect(() => {
		if (currentSponsor.id !== 0) getSponsor(currentSponsor.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSponsor]);

	useEffect(() => {
		if (sponsor.data) {
			dispatch(onChangeSponsorPrimaryColor(sponsor.data?.headerColor));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sponsor.data]);

	useEffect(() => {
		if (currentUserGrants) {
			dispatch(
				onChangeUserGrants({
					userGrants: currentUserGrants,
					loadCookies: shouldLoadCookies,
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUserGrants]);

	return <>{children}</>;
};
