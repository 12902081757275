/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
	Box,
	Modal,
	Typography,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Button,
	IconButton,
} from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import Dropzone from 'react-dropzone';
import { useUploadCSVMutation } from 'api/redux/services/capitalActivityApi';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { NotifyCheckbox } from './NotifyCheckbox';
import { NotificationDelaySelector } from 'modules/admin/UploadDocuments/NotificationDelaySelector';
import { docsUploadState } from 'api/redux/DocsUploadReducer';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		buttonBox: {
			backgroundColor: 'white',
			border: '#bfbfbf 1px solid',
			maxWidth: 'fit-content',
			margin: '10px',
			padding: '3px',
		},
		select: {
			width: '100%',
			minWidth: 200,
			padding: '10px',
		},
		selectLabel: {
			padding: '4px',
		},
		modalBox: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			width: 400,
			backgroundColor: 'white',
			textAlign: 'center',
			boxShadow: '24',
			p: 4,
		},
		modalText: {
			padding: '20px',
		},
		fileText: {
			marginTop: '15%',
		},
		dropZoneBox: {
			display: 'flex',
			justifyContent: 'center',
			margin: '20px',
			height: '200px',
			border: '2px dashed black',
		},
		actionsContainer: {
			marginBottom: theme.spacing(2),
		},
		nextButton: {
			backgroundColor: '#6559f4',
			color: '#FFFFFF',
			marginLeft: 8,
		},
		backButton: {
			backgroundColor: theme.palette.primary.light,
			color: 'black',
		},
		background: {
			width: '100%',
			height: '100%',
			padding: '3.5em',
		},
		step: {
			color: '#FFFFFF',
		},
		button: {
			backgroundColor: 'white !important',
			textTransform: 'inherit',
			height: '30px !important',
			marginTop: '15px !important',
			marginBottom: '15px !important',
			marginLeft: '10px !important',
			width: '150px !important',
			fontWeight: 'bold',
			padding: '2px !important',
			fontSize: 14,
			color: '#6558f5 !important',
			border: '1px solid #cfcdf9 !important',
		},
		buttonContainer: {
			padding: 2,
			width: '100%',
			display: 'flex',
			justifyContent: 'flex-end',
		},
		notificationDelaySelector: {
			fontSize: '14px !important',
		},
	}),
);

interface IFile {
	filename: string;
	template: string;
	reportingPeriod: string;
	file: File;
	validData: boolean;
}
export const CSVUpload: FC = () => {
	const grants = useSelector(selectDropdown);
	const { currentFund } = grants.grants;
	const classes = useStyles();
	const [csvError, setCSVError] = useState(false);
	const [emptyData, setEmptyData] = useState(false);
	const [missingColumn, setMissingColumn] = useState(false);
	const [incorrectType, setIncorrectType] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState<boolean>(false);
	const [errorOpen, setErrorOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [reportingPeriod, setReportingPeriod] = useState('');
	const [files, setFiles] = useState<IFile[]>([]);
	const [invalidFiles, setInvalidFiles] = useState<IFile[]>([]);
	const [notifyInvestorUsers, setNotifyInvestorUsers] =
		useState<boolean>(false);
	const [uploadCSVDocument, { error: uploadError, isSuccess }] =
		useUploadCSVMutation();
	const { notificationDelay } = useSelector(docsUploadState);

	const handleClose = () => {
		if (csvError === true) {
			setCSVError(false);
		}
		if (emptyData === true) {
			setEmptyData(false);
		}
		if (missingColumn === true) {
			setMissingColumn(false);
		}
		if (incorrectType === true) {
			setIncorrectType(false);
		}
		setErrorOpen(false);
	};
	const handleRejection = () => {
		setCSVError(true);
		setErrorOpen(true);
	};

	const setPreview = async (acceptedFiles) => {
		setLoading(true);
		if (acceptedFiles.length > 0) {
			const fileList: any[] = await Promise.all(
				acceptedFiles.map(async (file): Promise<any> => {
					return {
						filename: file.name,
						reportingPeriod: reportingPeriod,
						file: file,
						validData: true,
					};
				}),
			);
			fileList.push(...files);
			const filesWithError = fileList.filter((file) => file.validData != true);
			setInvalidFiles(filesWithError);
			setFiles(fileList.filter((file) => !filesWithError.includes(file)));
		}
		acceptedFiles.length = 0;
		setLoading(false);
	};

	const uploadCSV = async () => {
		if (files.length > 0) {
			files.forEach(async (file) => {
				const formData = new FormData();
				formData.append('file', file.file);
				formData.append('reportingPeriod', file.reportingPeriod);
				formData.append('fundId', currentFund.id.toString());
				formData.append('notifyInvestors', JSON.stringify(notifyInvestorUsers));
				formData.append('notificationDelayId', notificationDelay.id.toString());

				uploadCSVDocument({
					formData,
				});
			});
		}

		setFiles([]);
	};

	const deleteFile = (file) => {
		const list = files.filter((f) => f != file);
		setFiles(list);
	};

	const deleteInvalidFile = (file) => {
		const list = invalidFiles.filter((f) => f != file);
		setInvalidFiles(list);
	};

	useEffect(() => {
		if (uploadError) {
			setSuccess(false);
			setError(true);
		}
	}, [uploadError]);

	useEffect(() => {
		if (isSuccess) {
			setSuccess(true);
			setError(false);
		}
	}, [isSuccess]);

	const DocumentsPreview = () => {
		return (
			<Box>
				<div>
					<TableContainer>
						{files.length > 0 && (
							<Table aria-label="simple table">
								<TableHead sx={{ backgroundColor: '#d4d9ec' }}>
									<TableRow>
										<TableCell>Name</TableCell>
										<TableCell>Reporting Period</TableCell>

										<TableCell />
									</TableRow>
								</TableHead>
								<TableBody>
									{files.map((file, i) => (
										<TableRow key={(file.filename, i)}>
											<TableCell>{file.filename}</TableCell>
											<TableCell>{file.reportingPeriod}</TableCell>
											<TableCell>
												<IconButton
													color="inherit"
													onClick={() => deleteFile(file)}
												>
													<DeleteIcon />
												</IconButton>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						)}
						{invalidFiles.length > 0 && (
							<Table>
								<TableHead sx={{ backgroundColor: '#FF313150' }}>
									<TableRow>
										<TableCell>
											<Typography variant="h5">
												These files contain an error and will not be uploaded.
												Please check the contents of the file.
											</Typography>
										</TableCell>
										<TableCell />
										<TableCell>
											<Button
												onClick={() => setInvalidFiles([])}
												endIcon={<DeleteIcon />}
											>
												Remove All
											</Button>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{invalidFiles.map((file, i) => (
										<TableRow key={(file.filename, i)}>
											<TableCell>{file.filename}</TableCell>
											<TableCell>{file.reportingPeriod}</TableCell>
											<TableCell>
												<IconButton
													color="inherit"
													onClick={() => deleteInvalidFile(file)}
												>
													<DeleteIcon />
												</IconButton>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						)}
					</TableContainer>
				</div>
			</Box>
		);
	};

	const UploadCSVSection = () => {
		return (
			<Box>
				<Box className={classes.dropZoneBox}>
					<Dropzone
						accept={{ 'text/csv': ['.csv'] }}
						onDropRejected={handleRejection}
						onDrop={setPreview}
					>
						{({ getRootProps, getInputProps }) => (
							<div {...getRootProps()} className="dropzone">
								<input {...getInputProps()} />
								<Typography variant="h4" className={classes.fileText}>
									Click here to browse files or drag-and-drop the populated CSV
									template here.
								</Typography>
							</div>
						)}
					</Dropzone>
				</Box>
				<DocumentsPreview />
				<Box sx={{ display: 'flex' }}>
					<NotifyCheckbox
						checked={notifyInvestorUsers}
						onClick={() => setNotifyInvestorUsers(!notifyInvestorUsers)}
					/>
					<Box sx={{ mt: '9px' }}>
						<NotificationDelaySelector show={notifyInvestorUsers} />
					</Box>
				</Box>
				{success && (
					<Alert
						action={
							<IconButton
								aria-label="close"
								color="inherit"
								size="small"
								onClick={() => {
									setSuccess(false);
								}}
							>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						}
						sx={{ mt: 2, width: '100%' }}
					>
						Document successfully uploaded
					</Alert>
				)}
				{error && (
					<Alert
						variant="filled"
						onClose={() => setError(false)}
						severity="error"
					>
						An error occurred
					</Alert>
				)}
				<Modal
					open={errorOpen}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Box className={classes.modalBox}>
						<Typography
							id="modal-modal-title"
							variant="h5"
							component="h2"
							className={classes.modalText}
						>
							{csvError && 'Please input a CSV File'}
							{emptyData &&
								'One of your data points is empty please fix and re-upload'}
							{missingColumn && 'You are missing one or more columns'}
							{incorrectType && 'One of your data points is of the wrong type'}
							{success && 'File uploaded successfully!'}
						</Typography>
					</Box>
				</Modal>
			</Box>
		);
	};

	const ReportingPeriod = () => (
		<Box>
			<FormControl className={classes.select}>
				<InputLabel
					className={classes.selectLabel}
					id="reportingPeriod-dropdown-label"
				>
					Reporting Period
				</InputLabel>
				<Select
					labelId="reportingPeriod-dropdown-label"
					id="reportingPeriod-dropdown"
					value={reportingPeriod}
					label="Select Reporting Period"
					onChange={(e) => setReportingPeriod(e.target.value as string)}
				>
					{[
						'Period ending March 31, 2020',
						'Period ending June 30, 2020',
						'Period ending September 30, 2020',
						'Period ending December 31, 2020',
						'Period ending March 31, 2021',
						'Period ending June 30, 2021',
						'Period ending September 30, 2021',
						'Period ending December 31, 2021',
						'Period ending March 31, 2022',
						'Period ending June 30, 2022',
						'Period ending September 30, 2022',
						'Period ending December 31, 2022',
						'Period ending December 31, 2022 - DRAFT',
						'Period ending March 31, 2023',
						'Period ending June 30, 2023',
						'Period ending September 30, 2023',
						'Period ending December 31, 2023',
						'Period ending December 31, 2023 - DRAFT',
						'Period ending March 31, 2024',
						'Period ending June 30, 2024',
						'Period ending September 30, 2024',
						'Period ending December 31, 2024',
						'Period ending December 31, 2024 - DRAFT',
					].map((tpl) => (
						<MenuItem value={tpl} key={tpl}>
							{tpl}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	);

	return (
		<Box>
			<Backdrop sx={{ color: '#fff', zIndex: 2 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>

			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
				}}
			>
				<ReportingPeriod />
			</div>
			{reportingPeriod && (
				<div>
					<UploadCSVSection />
					<div className={classes.buttonContainer}>
						<Button
							onClick={uploadCSV}
							className={classes.button}
							variant="outlined"
						>
							Upload
						</Button>
					</div>
				</div>
			)}
		</Box>
	);
};
