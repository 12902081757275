import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
	header: {},
	align: {
		alignContent: 'left',
	},
	summaryHeading: {
		fontSize: 12,
	},
	summaryValue: {
		fontSize: 12,
	},
	graphBox: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '80%',
		padding: 20,
		fontFamily: 'lato !important',
	},
	noDataText: {
		textAlign: 'center',
	},
});
