import { FC, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Avatar, Box } from '@mui/material';
import { Typography } from '@material-ui/core';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { User } from '../../../app/types/user.types';
import { hasPermission } from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import moment from 'moment';
import { ERoutes } from 'App';
import { ColHeaders, IColumn, IUsersTableLoaderProps } from './types';
import { useStyles } from './styles';
import { IInvestorGrant } from 'api/redux/services/userApi';
import { UserActions } from './UserActions';
import { usersState } from 'api/redux/UsersReducer';

export const UsersTableLoader: FC<IUsersTableLoaderProps> = ({
	refetchUsers,
	children,
	setPage,
	pages,
	loading,
	searchString,
	setSearchString,
}) => {
	const classes = useStyles();
	const grants = useSelector(selectDropdown);
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const { currentInvestor, allAvailableInvestors } = grants.grants;
	const { path } = useRouteMatch();
	const [selectedUserId, setSelectedUserId] = useState<number | undefined>(
		undefined,
	);
	const { users } = useSelector(usersState);
	const selectedUser = selectedUserId
		? users.find((u) => u.id === selectedUserId)
		: undefined;
	const [investors, setInvestors] = useState<IInvestorGrant[]>([]);
	const [selectedInvestorId, setSelectedInvestorId] = useState<
		number | undefined
	>(undefined);
	const [editUserPermissions, setEditUserPermissions] = useState(false);
	const [editInvestor, setEditInvestor] = useState(false);
	const [addSponsor, setAddSponsor] = useState(false);
	const [deleteInvestor, setDeleteInvestor] = useState(false);
	const canSeeInvestorColumn = hasPermission({
		scopes: [SCOPES.canSeeInvestorsColumn],
	});
	const canSeeInvestorLastLoginColumn = hasPermission({
		scopes: [SCOPES.canSeeInvestorLastLoginColumn],
	});

	const handleSponsorUserPermissions = (id, role, investors) => {
		if (role == 'Sponsor') {
			setSelectedUserId(id);
			setEditUserPermissions(true);
			setInvestors(investors);
			setSelectedInvestorId(investors[0].id);
		}
	};

	const handleDeleteUserFromInvestor = (userId) => {
		setSelectedUserId(userId);
		setDeleteInvestor(true);
	};

	const handleEditUser = (id, userInvestors) => {
		const view = {
			[ERoutes.InvestorsView]: () => currentInvestor.id,
			[ERoutes.OrganizationView]: () => userInvestors[0].id,
		};
		setInvestors(userInvestors);
		setSelectedUserId(id);
		setSelectedInvestorId(view[path]());
		setEditUserPermissions(true);
	};

	const onUserRowClick = (user: User) => {
		const { id, role, investors } = user;

		handleSponsorUserPermissions(id, role, investors);
	};

	const columns: IColumn[] = useMemo(
		() => [
			{
				Header: ColHeaders.Name,
				accessor: 'name',
				isSorted: true,
				Cell: ({ row }) => (
					<Box
						sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}
					>
						<Avatar sx={{ marginRight: '5px' }} />
						<Typography className={classes.tableTypography}>
							{row.original.name}
						</Typography>
					</Box>
				),
			},
			{
				Header: ColHeaders.Role,
				accessor: 'role',
				isSorted: true,
				Cell: ({ row }) => (
					<Box style={{ display: 'flex', alignItems: 'center' }}>
						<Typography className={classes.tableTypography}>
							{row.original.role}
						</Typography>
					</Box>
				),
			},
			{
				Header: ColHeaders.Email,
				accessor: 'email',
				isSorted: true,
				Cell: ({ row }) => (
					<Box style={{ display: 'flex', alignItems: 'center' }}>
						<Typography className={classes.tableTypography}>
							{row.original.email}
						</Typography>
					</Box>
				),
			},
			{
				Header: ColHeaders.Investors,
				accessor: 'investors',
				isVisible: canSeeInvestorColumn,
				Cell: ({ row }) => (
					<Box style={{ display: 'flex', alignItems: 'center' }}>
						<Typography className={classes.tableTypography}>
							{row.original.investors
								.filter((inv) =>
									allAvailableInvestors.map((i) => i.id).includes(inv.id),
								)
								.map((inv) => inv.name)
								.join(', ')}
						</Typography>
					</Box>
				),
			},
			{
				Header: ColHeaders.LastLogin,
				accessor: 'lastLogin',
				isVisible: canSeeInvestorLastLoginColumn,
				Cell: ({ row }) => (
					<Box style={{ display: 'flex', alignItems: 'center' }}>
						<Typography className={classes.tableTypography}>
							{row.original.lastLogin
								? moment(row.original.lastLogin).format(
										'MMMM Do YYYY, h:mm:ss a',
								  )
								: '-'}
						</Typography>
					</Box>
				),
			},
			{
				Header: ColHeaders.Actions,
				accessor: 'more',
				minWidth: 150,
				Cell: ({ row }) => {
					const { original: user } = row;
					return (
						<Box
							sx={{
								display: 'flex',
								width: '100%',
								justifyContent: 'flex-end',
								overflow: 'hidden',
							}}
						>
							<UserActions
								user={user}
								onEdit={() => handleEditUser(user.id, user.investors)}
								onDelete={() => handleDeleteUserFromInvestor(user.id)}
							/>
						</Box>
					);
				},
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[grants.grants, sponsorColor],
	);

	return (
		<>
			{children({
				refetchUsers,
				columns,
				addSponsor,
				setAddSponsor,
				onUserRowClick,
				editUserPermissions,
				setEditUserPermissions,
				editInvestor,
				setEditInvestor,
				selectedInvestorId,
				setSelectedInvestorId,
				selectedUser,
				investors,
				deleteInvestor,
				setDeleteInvestor,
				selectedUserId,
				setPage,
				pages,
				loading,
				searchString,
				setSearchString,
			})}
		</>
	);
};
