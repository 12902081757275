import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		paddingRight: 0,
		paddingLeft: 0,
		marginRight: 0,
		marginTop: 0,
		maxWidth: '100vw',
		overflow: 'hidden',
		height: 'calc(100vh - 80px)',
	},
	sidebar: {
		width: '20.5rem',
		marginLeft: 0,
		marginRight: 0,
		paddingLeft: 0,
		paddingRight: 0,
		height: '100%',
		position: 'sticky',
	},
	table: {
		width: '100%',
		paddingRight: 0,
		paddingLeft: 0,
	},
	contentSection: {
		alignItems: 'center',
		height: '100%',
		overflow: 'auto',
		padding: 30,
	},
	paper: {
		width: '100%',
		marginTop: 16,
		borderRadius: 10,
		padding: 16,
	},
	breadCrumbContainer: {
		margin: 16,
		display: 'flex',
	},
	button: {
		marginLeft: 2,
		marginRight: 2,
	},
}));
