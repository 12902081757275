import { selectDropdown } from 'api/redux/DropdownReducer';
import { Container, Grid, Paper } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	ITotalCommitmentReport,
	useLazyGetInvestorQuery,
	useLazyGetInvestorTotalCommitmentQuery,
} from 'api/redux/services/investors.service';
import { useStyles } from './styles';
import { CapitalActivityData } from './CapitalActivityData';
import { InvestorMetaData } from './InvestorMetadata';
import { EditInvestorModal } from './EditInvestorModal';
import { Investor } from 'app/types/investor.types';
import numberFormat from 'common/helpers/Math';
import { ISingleCommitment } from './types';
import { selectNavbarTablet } from 'api/redux/NavBarStateReducer';
import { InvestorNotes } from './InvestorNotes';
import PermissionsWrapper, {
	hasPermission,
} from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { GridSize } from '@mui/material';

export const InvestorInfo: FC = () => {
	const classes = useStyles();
	const grants = useSelector(selectDropdown);
	const { currentInvestor, currentSponsor } = grants.grants;
	const tablet = useSelector(selectNavbarTablet);
	const [fetchTotalCommitment, totalCommitment] =
		useLazyGetInvestorTotalCommitmentQuery();
	const [capitalActivityData, setCapitalActivityData] = useState<
		ISingleCommitment[]
	>([]);
	const [showAll, setShowAll] = useState<boolean>(false);
	const [editInvestor, setEditInvestor] = useState<boolean>(false);
	const [fetchInvestorInfo, investorInfo] = useLazyGetInvestorQuery();
	const [investor, setInvestor] = useState<Investor | undefined>(undefined);
	const columnWidth: GridSize = hasPermission({
		scopes: [SCOPES.canSeeInvestorNotes],
	})
		? 4
		: 6;

	useEffect(() => {
		if (currentInvestor.id === 0) return;

		fetchInvestorInfo(currentInvestor.id);
		fetchTotalCommitment({
			investorId: currentInvestor.id,
			sponsorId: currentSponsor.id,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentInvestor.id]);

	useEffect(() => {
		if (investorInfo.data) {
			setInvestor(investorInfo.data);
		}
	}, [investorInfo.data]);

	useEffect(() => {
		if (totalCommitment.data) {
			const info: ISingleCommitment[] = [];
			const commitmentData: ITotalCommitmentReport[] = totalCommitment.data;

			commitmentData.forEach((cData: ITotalCommitmentReport) => {
				info.push({
					fundName: cData.fund.name,
					fundId: cData.fund.id,
					amount: cData.totalCommitment
						? numberFormat(cData.totalCommitment)
						: '-',
				});
			});

			setCapitalActivityData(info);
		}
	}, [totalCommitment.data]);

	const handleAfterSave = async () => {
		if (!investor) return;
		await fetchInvestorInfo(investor.id);
		setEditInvestor(false);
	};

	if (investor?.id === 0) return <></>;

	return (
		<Container
			style={{ paddingLeft: `${tablet ? '0px' : '55px'}` }}
			maxWidth={false}
			classes={{ root: classes.root }}
		>
			<Grid container className={classes.grid} spacing={1}>
				<Grid item xs={columnWidth} style={{ paddingLeft: 0 }}>
					<Paper
						elevation={0}
						className={classes.paper}
						style={{ height: '21rem' }}
					>
						{investor && (
							<InvestorMetaData
								investor={investor}
								onEditClick={() => setEditInvestor(true)}
							/>
						)}
					</Paper>
				</Grid>
				<Grid item xs={columnWidth} style={{ paddingRight: 0 }}>
					<Paper
						elevation={0}
						className={classes.paper}
						style={{
							minHeight: '21rem',
							height: showAll ? 'auto' : '21rem',
							overflow: 'auto',
						}}
					>
						<CapitalActivityData commitementsToShow={capitalActivityData} />
					</Paper>
				</Grid>
				<PermissionsWrapper scopes={[SCOPES.canSeeInvestorNotes]}>
					<Grid item xs={columnWidth}>
						<Paper
							elevation={0}
							className={classes.paper}
							style={{
								minHeight: '21rem',
								height: showAll ? 'auto' : '21rem',
								overflow: 'auto',
							}}
						>
							<InvestorNotes investorId={investor?.id || 0} />
						</Paper>
					</Grid>
				</PermissionsWrapper>
			</Grid>

			{editInvestor && investor && (
				<EditInvestorModal
					isOpen={editInvestor}
					onClose={() => setEditInvestor(false)}
					investor={investor}
					afterSave={handleAfterSave}
					editContactInfo={false}
				></EditInvestorModal>
			)}
		</Container>
	);
};
