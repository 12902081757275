import { makeStyles } from '@material-ui/core/styles';
import { defaultColor } from 'api/redux/siteColorReducer';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		padding: 0,
		margin: 0,
		height: '100%',
	},
	activitySummaryBox: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: 0,
	},
	headerContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		flexGrow: 1,
		padding: 0,
	},
	capitalSummaryTitle: {
		padding: 0,
	},
	viewMoreDetailsContainer: {
		padding: 0,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
	viewMoreDetailsItems: {
		padding: 0,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	viewMoreDetailsIcon: {
		color: defaultColor,
	},
	viewMoreDetailsText: {
		color: defaultColor,
	},
	container: {
		padding: theme.spacing(2),
		color: theme.palette.text.secondary,
		height: '100%',
		display: 'flex',
		flexDirection: 'row',
		borderRadius: 10,
	},
	imageIcon: {
		height: '100%',
	},
	iconRoot: {
		textAlign: 'center',
	},
	grid: {
		display: 'flex',
		flexDirection: 'column',
		width: '80%',
		height: '100%',
	},
	gridItem: {
		display: 'flex',
		flexDirection: 'column',
	},
	periodEndingText: {
		fontSize: '1rem',
		fontWeight: 400,
		color: '#1F1F1F',
		display: 'flex',
	},
	listRow: {
		display: 'flex',
		flexDirection: 'row',
	},
	listItem: {
		maxWidth: '10%',
	},
	outerColumn: {
		border: '4px solid #c3cfd9',
		borderRadius: '100px',
		width: '12rem',
		height: '3.8rem',
	},
	capitalActivityItemContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	capitalActivityHeader: {
		fontSize: '1rem',
		fontWeight: 500,
		color: '#1F1F1F',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	capitalActivitySubHeader: {
		fontSize: '1rem',
		fontWeight: 500,
		color: '#1F1F1F',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: '1rem',
	},
	capitalActivityContent: {
		fontSize: '1.6rem',
		fontWeight: 400,
		color: defaultColor,
		display: 'flex',
		justifyContent: 'center',
	},
	bottomPush: {
		display: 'flex',
		justifyContent: 'center',
		marginLeft: '30vw',
		marginTop: '2rem',
	},
	summaryGrid: {
		flexGrow: 19,
	},
	summaryGridItem: {},
	summaryCard: {
		margin: 5,
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
}));
