import { makeStyles } from '@material-ui/core/styles';
import { defaultColor } from 'api/redux/siteColorReducer';

export const useStyles = makeStyles(() => ({
	loginBox: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '60vh',
	},
	loginNotMobile: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100vw',
		height: '60vh',
	},
	loginMobile: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '60vh',
		width: '100vw',
		padding: 0,
		margin: 0,
	},
	loginButton: {
		backgroundColor: '#FFFFFF',
		color: `${defaultColor}`,
		border: `2px solid ${defaultColor}`,
		textTransform: 'none',
		fontSize: '1.0rem',
		width: 135,
		boxShadow: 'none',
		'&.MuiButton-root:hover': {
			backgroundColor: `#FFFFFF !important`,
			boxShadow: 'none',
		},
		'& .MuiButton-root:focus': {
			backgroundColor: `#FFFFFF !important`,
		},
	},
	disclaimerText: {
		color: '#FFFFFF',
		textAlign: 'center',
		padding: '1rem',
		align: 'center',
	},
	contactText: {
		padding: '1rem',
		position: 'absolute',
		bottom: 0,
	},
	sponsorLogo: {
		maxWidth: '250px',
		maxHeight: '250px',
		height: 'auto',
		paddingBottom: '1rem',
	},
}));
