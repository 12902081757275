import { FC } from 'react';
import { Typography } from '@material-ui/core';
import { Box } from '@mui/material';
import { TableCell, TableRow } from './styles';
import { useStyles } from './styles';
import { ICASTableRowProps, RowLabels } from './types';
import { IColumn } from '../types';
import { CASTableCell } from './CASTableCell';
import { cellLabelStyle } from './styles';

export const CASTableRow: FC<ICASTableRowProps> = ({
	row,
	columns,
	CASData,
	fundData,
	templateType,
}) => {
	const classes = useStyles();

	return (
		<TableRow className={row.valueFormats.length ? 'content-row' : 'blank-row'}>
			<TableCell>
				<Box>
					<Typography className={classes.rowLabels} style={cellLabelStyle(row)}>
						{RowLabels[row.label.text]}
					</Typography>
				</Box>
			</TableCell>
			{columns.map((column: IColumn, j) => (
				<CASTableCell
					key={j}
					row={row}
					column={column}
					CASData={CASData}
					fundData={fundData}
					templateType={templateType}
				/>
			))}
		</TableRow>
	);
};
