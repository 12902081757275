import { FC } from 'react';
import { useStyles } from './styles';
import PermissionsWrapper from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { Box, Grid, InputLabel, Paper, ToggleButton } from '@mui/material';
import { Check } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import {
	docsUploadState,
	onSetNotifyInvestorUsers,
	onSetNotifySponsorUsers,
} from 'api/redux/DocsUploadReducer';
import { useFileContext } from './FileContext';
import { NotificationDelaySelector } from './NotificationDelaySelector';

export const NotificationOptions: FC = () => {
	const classes = useStyles();
	const { notifyInvestorUsers, notifySponsorUsers } =
		useSelector(docsUploadState);
	const dispatch = useDispatch();
	const { uploadedFiles } = useFileContext();

	if (uploadedFiles.length === 0) return <></>;

	return (
		<Paper className={classes.paper}>
			<Grid container direction="row" spacing={2} sx={{ padding: '2rem' }}>
				<Grid item xs={4} md={4}>
					<PermissionsWrapper
						scopes={[SCOPES.canNotifyInvestorUsersOnUploadedDocuments]}
					>
						<>
							<ToggleButton
								aria-labelledby="notify-investors-check-label"
								value="check"
								selected={notifyInvestorUsers}
								color={'primary'}
								onChange={() =>
									dispatch(onSetNotifyInvestorUsers(!notifyInvestorUsers))
								}
							>
								{notifyInvestorUsers ? (
									<Check sx={{ fontSize: 10 }} />
								) : (
									<div style={{ height: 10, width: 10 }} />
								)}
							</ToggleButton>
							<InputLabel
								id="notify-investors-check-label"
								className={classes.notificationText}
							>
								Notify <i>investor level users</i> about this upload
							</InputLabel>
						</>
					</PermissionsWrapper>
				</Grid>
				<Grid item xs={4} md={4}>
					<PermissionsWrapper
						scopes={[SCOPES.canNotifySponsorUsersOnUploadedDocuments]}
					>
						<>
							<ToggleButton
								aria-labelledby="notify-sponsors-check-label"
								value="check"
								selected={notifySponsorUsers}
								color={'primary'}
								onChange={() =>
									dispatch(onSetNotifySponsorUsers(!notifySponsorUsers))
								}
							>
								{notifySponsorUsers ? (
									<Check sx={{ fontSize: 10 }} />
								) : (
									<div style={{ height: 10, width: 10 }} />
								)}
							</ToggleButton>
							<InputLabel
								id="notify-sponsors-check-label"
								className={classes.notificationText}
							>
								Notify <i>sponsor level users</i> about this upload
							</InputLabel>
						</>
					</PermissionsWrapper>
				</Grid>
				<Grid item xs={4} md={4} sx={{ mt: '-13px' }}>
					<PermissionsWrapper
						scopes={[
							SCOPES.canNotifyInvestorUsersOnUploadedDocuments,
							SCOPES.canNotifySponsorUsersOnUploadedDocuments,
						]}
					>
						<NotificationDelaySelector
							show={notifyInvestorUsers || notifySponsorUsers}
						/>
					</PermissionsWrapper>
				</Grid>
			</Grid>
		</Paper>
	);
};
