import { Box, FormControlLabel, Paper, Typography } from '@material-ui/core';
import { ChangeEvent, FC } from 'react';
import { useStyles } from './styles';
import _ from 'lodash';
import { Checkbox, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import {
	documentsState,
	onSetSelectedDocTypeIds,
} from 'api/redux/DocumentsReducer';
import { selectNavbarMobile } from 'api/redux/NavBarStateReducer';

export const DocumentFilters: FC = () => {
	const classes = useStyles();
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const docs = useSelector(documentsState);
	const { userAllowedDocTypes, selectedDocTypeIds } = docs;
	const dispatch = useDispatch();
	const isMobile = useSelector(selectNavbarMobile);

	const handleDocTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
		const selectedIds = [...selectedDocTypeIds];
		if (e.target.checked) {
			dispatch(
				onSetSelectedDocTypeIds([...selectedIds, Number(e.target.value)]),
			);
			return;
		}

		const index = selectedIds.indexOf(Number(e.target.value));
		selectedIds.splice(index, 1);
		dispatch(onSetSelectedDocTypeIds(selectedIds));
	};

	const handleAllDocumentsClick = () => {
		if (
			!_.isEqual(
				selectedDocTypeIds,
				userAllowedDocTypes.map((d) => d.id),
			)
		) {
			dispatch(onSetSelectedDocTypeIds(userAllowedDocTypes.map((d) => d.id)));
			return;
		}
		dispatch(onSetSelectedDocTypeIds([]));
	};

	const DocumentTypeOptions: FC = () => {
		const docs = useSelector(documentsState);
		const { userAllowedDocTypes, selectedDocTypeIds } = docs;

		return (
			<Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
				{userAllowedDocTypes.map((docType) => (
					<FormControlLabel
						key={docType.id}
						label={docType.name}
						style={{
							padding: '10px 0px 0px 5px',
						}}
						control={
							<Checkbox
								size="small"
								value={docType.id}
								checked={selectedDocTypeIds.includes(docType.id)}
								onChange={handleDocTypeChange}
								sx={{
									color: sponsorColor,
									'&.Mui-checked': {
										color: sponsorColor,
									},
									padding: '0px 5px 0px 0px',
								}}
							/>
						}
					/>
				))}
			</Box>
		);
	};

	const DocumentTypesFilter: FC = () => {
		const docs = useSelector(documentsState);
		const { userAllowedDocTypes, selectedDocTypeIds } = docs;
		const sortedSelectedDocTypeIds = [...selectedDocTypeIds].sort(
			(a, b) => a - b,
		);
		const sortedAvailableDocTypeIds = [
			...userAllowedDocTypes.map((dt) => dt.id),
		].sort((a, b) => a - b);
		const allSelected: boolean = _.isEqual(
			sortedSelectedDocTypeIds,
			sortedAvailableDocTypeIds,
		);

		return (
			<Box>
				<FormControlLabel
					label="All Documents"
					control={
						<Checkbox
							checked={allSelected}
							indeterminate={!allSelected}
							onChange={handleAllDocumentsClick}
							size="small"
							sx={{
								color: sponsorColor,
								'&.Mui-checked': {
									color: sponsorColor,
								},
								'&.MuiCheckbox-indeterminate': {
									color: sponsorColor,
								},
								padding: '0px 5px 0px 10px',
							}}
						/>
					}
				/>
				<DocumentTypeOptions />
			</Box>
		);
	};

	if (isMobile) return <></>;

	return (
		<Grid item lg={2} sx={{ height: '100%' }}>
			<Box sx={{ height: '100%', maxHeight: '100%' }}>
				<Paper elevation={0} className={classes.filtersContainer}>
					<Typography>Filters</Typography>
					<DocumentTypesFilter />
				</Paper>
			</Box>
		</Grid>
	);
};
