import { FC, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@mui/material';
import { Button } from '@material-ui/core';
import { useStyles } from './styles';
import { useCookies } from 'react-cookie';
import { HomeLoader } from './HomeLoader';
import { THomeProps, URLS_TO_SAVE } from './types';
import { ISponsor } from 'app/types/sponsor.types';

export const Home: FC = () => {
	const buildBrandedUrl = (
		org: ISponsor | undefined,
		subdomain: string,
		darkLogoUrl: string,
		baseUrl: string,
	) => {
		const url = new URL(baseUrl);

		const redirectUrl = getRedirectUrl();
		if (redirectUrl) {
			url.searchParams.append('redirectUrl', redirectUrl);
		}

		if (!org) return url.toString();

		url.searchParams.append('orgName', org.name);
		url.searchParams.append('logo', darkLogoUrl || '');
		url.searchParams.append('auth0OrgId', org.auth0Id || '');
		url.searchParams.append('orgId', org.id.toString() || '');
		url.searchParams.append('sub', subdomain);
		return url.toString();
	};

	const getRedirectUrl = () => {
		let redirectUrl = '';
		const { pathname, search } = window.location;

		if (URLS_TO_SAVE.some((url) => pathname.includes(url))) {
			redirectUrl = `${pathname}${search}`;
		}
		return redirectUrl;
	};

	return (
		<HomeLoader>
			{(injectedProps: THomeProps) => (
				<>
					{injectedProps.isShowLoading ? (
						<>Loading...</>
					) : injectedProps.isRedirectToBrandedUrl &&
					  (window.location.href = buildBrandedUrl(
							injectedProps.org,
							injectedProps.subdomain,
							injectedProps.logoUrl,
							injectedProps.baseUrl,
					  )) ? (
						<></>
					) : (
						<Login
							orgName={injectedProps.orgName}
							logo={injectedProps.logo}
							subdomain={injectedProps.subdomainParam}
							redirectUrl={injectedProps.redirectUrl}
						/>
					)}
				</>
			)}
		</HomeLoader>
	);
};

interface ILoginProps {
	logo?: string;
	orgName?: string;
	subdomain?: string;
	redirectUrl?: string;
}

export const Login: FC<ILoginProps> = (props) => {
	const clearUrlParams = () => {
		const url = new URL(window.location.href);
		url.searchParams.delete('orgName');
		url.searchParams.delete('logo');
		url.searchParams.delete('auth0OrgId');
		url.searchParams.delete('orgId');
		url.searchParams.delete('sub');
		url.searchParams.delete('subdomain');
		url.searchParams.delete('redirectUrl');
		return url.toString();
	};

	history.replaceState(null, '', clearUrlParams());
	const classes = useStyles();
	const { loginWithRedirect } = useAuth0();
	const [logo] = useState<string | undefined>(props.logo || undefined);
	const [, setCookie] = useCookies(['subdomain']);

	if (props.subdomain) {
		setCookie('subdomain', props.subdomain, { path: '/' });
	}

	const saveURL = () => {
		const { pathname, search } = window.location;

		if (URLS_TO_SAVE.some((url) => pathname.includes(url))) {
			sessionStorage.setItem('redirectUrl', `${pathname}${search}`);
		}
	};

	const handleLogin = () => {
		saveURL();
		if (props.redirectUrl) {
			sessionStorage.setItem('redirectUrl', props.redirectUrl);
		}
		loginWithRedirect();
	};

	return (
		<Box className={classes.loginBox}>
			<Box className={classes.loginNotMobile}>
				{logo && <img src={logo} className={classes.sponsorLogo} />}
				<Button
					variant="contained"
					size="large"
					disableRipple
					className={classes.loginButton}
					onClick={handleLogin}
				>
					Log In
				</Button>
			</Box>
		</Box>
	);
};

export default Home;
