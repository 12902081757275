import { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DROPDOWNS_TOTAL_LENGTH } from './types';
import { AppBar, Box, Toolbar } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import {
	onNavbarMobile,
	selectScreenWidth,
	onScreenWidthChange,
} from 'api/redux/NavBarStateReducer';
import { StandardMenu } from './StandardMenu';
import { useCookies } from 'react-cookie';
import { SponsorLogo } from './SponsorLogo';
import { MenuButton } from './MenuButton';
import { DropDowns } from './dropdowns/DropDowns';
import { useStyles } from './styles';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { getCookie } from 'common/helpers/Cookies';
import { useLocalUser } from 'common/helpers/permissions/useLocalUser';

export const NavbarElements: FC = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { logout } = useAuth0();
	const screenWidth = useSelector(selectScreenWidth);
	const isMobile = screenWidth < DROPDOWNS_TOTAL_LENGTH;
	const [anchorEl, setAnchorEl] = useState(null);
	const isMenuOpen = Boolean(anchorEl);
	const { currentUser } = useLocalUser();
	const [, , removeCookies] = useCookies([
		'fundId',
		'sponsorId',
		'investorId',
		'subdomain',
	]);
	const sponsorColor = useSelector(selectSponsorPrimaryColor);

	useEffect(() => {
		const handleWindowResize = () => {
			dispatch(onScreenWidthChange(window.innerWidth));
		};

		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	});

	const handleMenuOpen = (event: React.BaseSyntheticEvent) => {
		setAnchorEl(event.currentTarget);
	};

	const handleLogOut = () => {
		const url = new URL(window.location.origin);
		const subdomain = getCookie('subdomain');
		if (subdomain) {
			url.searchParams.append('subdomain', subdomain);
		}

		logout({ returnTo: url.toString() });
		removeCookies('fundId', { path: '/' });
		removeCookies('sponsorId', { path: '/' });
		removeCookies('investorId', { path: '/' });
		removeCookies('subdomain', { path: '/' });
	};

	const blurStyle = !currentUser?.initialLogin ? { filter: 'blur(5px)' } : {};

	useEffect(() => {
		dispatch(onNavbarMobile(isMobile));
	}, [isMobile, dispatch]);

	return (
		<>
			<AppBar
				className={classes.appbar}
				style={{
					backgroundColor: sponsorColor,
					...blurStyle,
					width: '100%',
				}}
				elevation={0}
				position="fixed"
			>
				<Toolbar
					disableGutters={true}
					style={{ width: '100%', height: '100%' }}
				>
					<Box className={classes.toolbarBox}>
						<SponsorLogo />

						<Box className={classes.dropdownBox}>
							<DropDowns />

							<Box className={classes.menuBox}>
								<MenuButton
									windowWidth={screenWidth}
									handleMenuOpen={handleMenuOpen}
								/>
							</Box>
						</Box>
					</Box>
				</Toolbar>
			</AppBar>

			<StandardMenu
				anchorEl={anchorEl}
				handleClose={() => setAnchorEl(null)}
				isOpen={isMenuOpen}
				logout={handleLogOut}
			/>
		</>
	);
};
