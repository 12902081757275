import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Button, Grid, List, ListItem } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
	selectNavbarTablet,
	onNavbarTablet,
} from 'api/redux/NavBarStateReducer';
import {
	IMenuButtonProps,
	standardMenuId,
	LENGTH_TO_HIDE_MY_ACCOUNT,
} from './types';
import { useStyles } from './styles';

export const MenuButton: FC<IMenuButtonProps> = ({
	windowWidth,
	handleMenuOpen,
}) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const tablet = useSelector(selectNavbarTablet);

	useEffect(() => {
		const isTablet = windowWidth < LENGTH_TO_HIDE_MY_ACCOUNT;
		dispatch(onNavbarTablet(isTablet));
	}, [dispatch, windowWidth]);

	if (tablet) {
		return (
			<div className={classes.sectionMobile}>
				<IconButton
					aria-label="show more"
					aria-controls={standardMenuId}
					aria-haspopup="true"
					onClick={handleMenuOpen}
					color="inherit"
				>
					<MoreIcon style={{ color: '#FFFFFF' }} />
				</IconButton>
			</div>
		);
	}
	return (
		<>
			<div className={classes.sectionDesktop}>
				<Grid container alignItems="center">
					<Button className={classes.buttonText} onClick={handleMenuOpen}>
						<Grid item>
							<List>
								<ListItem className={classes.nameListItem}>
									<Typography variant="h5" style={{ color: '#f1f2f9' }}>
										My Account
									</Typography>
								</ListItem>
							</List>
						</Grid>
						<Grid item>
							<Badge color="secondary">
								<KeyboardArrowDownIcon
									fontSize="large"
									style={{ color: '#f1f2f9' }}
								/>
							</Badge>
						</Grid>
					</Button>
				</Grid>
			</div>
		</>
	);
};
