import { Typography } from '@material-ui/core';
import { Alert, Box } from '@mui/material';
import {
	onChangeFund,
	onChangeInvestor,
	onChangeSponsor,
	selectDropdown,
} from 'api/redux/DropdownReducer';
import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CapitalAccountStatement } from '../CapitalAccountStatement/CapitalAccountStatement';

export const CasReports: FC = () => {
	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const sponsorId = params.get('sponsorId');
	const fundId = params.get('fundId');
	const investorId = params.get('investorId');
	const period = params.get('period');
	const grants = useSelector(selectDropdown);
	const { availableSponsors, availableFunds, availableInvestors } =
		grants.grants;

	const dispatch = useDispatch();
	const [error, setError] = useState<string | undefined>(undefined);

	useEffect(() => {
		if (!availableSponsors.length) return;

		if (
			!sponsorId ||
			!availableSponsors.map((s) => s.id).includes(parseInt(sponsorId))
		) {
			setError('Invalid or missing sponsor ID');
			return;
		}

		const selectedSponsor = availableSponsors.find(
			(s) => s.id === parseInt(sponsorId),
		);

		if (selectedSponsor) {
			dispatch(onChangeSponsor(selectedSponsor));
			setError(undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [availableSponsors, sponsorId]);

	useEffect(() => {
		if (!availableFunds.length) return;

		if (
			!fundId ||
			!availableFunds.map((s) => s.id).includes(parseInt(fundId))
		) {
			setError('Invalid or missing fund ID');
			return;
		}

		const selectedFund = availableFunds.find((f) => f.id === parseInt(fundId));

		if (selectedFund) {
			dispatch(onChangeFund(selectedFund));
			setError(undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [availableFunds, fundId]);

	useEffect(() => {
		if (!availableInvestors.length) return;

		if (
			!investorId ||
			!availableInvestors.map((i) => i.id).includes(parseInt(investorId))
		) {
			setError('Invalid or missing investor ID');
			return;
		}

		const selectedInvestor = availableInvestors.find(
			(i) => i.id === parseInt(investorId),
		);

		if (selectedInvestor) {
			dispatch(onChangeInvestor(selectedInvestor));
			setError(undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [availableInvestors, investorId]);

	if (error)
		return (
			<MainSidebarContainer>
				<Alert variant="filled" severity="error">
					{error}
				</Alert>
			</MainSidebarContainer>
		);

	return (
		<MainSidebarContainer>
			<CapitalAccountStatement period={period || ''} />
		</MainSidebarContainer>
	);
};
