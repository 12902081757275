import { Box, Dialog, DialogTitle, DialogActions, Button } from '@mui/material';
import { TextField } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import {
	useCreateOrganizationMutation,
	useLazyGetOrganizationBySubdomainQuery,
} from 'api/redux/services/organizations.service';

interface ICreateOrganizationProps {
	open: boolean;
	onClose: () => void;
}

export const CreateOrganization: FC<ICreateOrganizationProps> = ({
	open,
	onClose,
}) => {
	const [createOrganization] = useCreateOrganizationMutation();
	const [fetchOrganization, existingOrganization] =
		useLazyGetOrganizationBySubdomainQuery();
	const [sponsorName, setSponsorName] = useState('');
	const [subdomain, setSubdomain] = useState('');
	const [subdomainError, setSubdomainError] = useState(false);

	const handleSubmit = async () => {
		await createOrganization({ name: sponsorName, subdomain });
		onClose();
	};

	const handleNameChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		setSponsorName(e.target.value);
		setSubdomain(buildSubdomain(e.target.value));
	};

	const buildSubdomain = (name: string) => {
		return name
			.toLowerCase()
			.replace(/ /g, '-')
			.replace(/[^\w-]+/g, '');
	};

	useEffect(() => {
		fetchOrganization(subdomain);
	}, [fetchOrganization, subdomain]);

	useEffect(() => {
		if (existingOrganization.data) {
			setSubdomainError(true);
		} else {
			setSubdomainError(false);
		}
	}, [existingOrganization.data]);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle>Create Sponsor</DialogTitle>
			<Box sx={{ pl: 3, pr: 3, pb: 3 }}>
				<TextField
					autoFocus
					label="Sponsor Name"
					variant="outlined"
					fullWidth
					inputProps={{
						style: {
							borderBottom: 'none',
							paddingLeft: 20,
						},
					}}
					value={sponsorName}
					onChange={(e) => handleNameChange(e)}
				/>
				<TextField
					label="Subdomain"
					variant="outlined"
					fullWidth
					margin="normal"
					inputProps={{
						style: {
							borderBottom: 'none',
							paddingLeft: 20,
						},
					}}
					value={subdomain}
					onChange={(e) => setSubdomain(e.target.value)}
					error={subdomainError}
					helperText={subdomainError ? 'Subdomain already exists' : ''}
				/>
			</Box>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button
					variant="contained"
					onClick={handleSubmit}
					disabled={subdomainError}
				>
					Create
				</Button>
			</DialogActions>
		</Dialog>
	);
};
