import { FC } from 'react';
import { ITitleBarProps } from './types';
import { useSelector } from 'react-redux';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Grid, Typography } from '@mui/material';

export const TitleBar: FC<ITitleBarProps> = ({ onClose }) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);

	return (
		<Grid container direction="row">
			<Grid item xs={10} md={10}>
				<Box>
					<Typography variant="h4" sx={{ fontSize: '1.6rem' }}>
						Permissions
					</Typography>
				</Box>
			</Grid>

			<Grid item xs={2} md={2}>
				<Box display="flex" justifyContent="flex-end">
					<Button
						variant="text"
						onClick={onClose}
						sx={{
							color: `${sponsorColor}`,
						}}
					>
						<CloseIcon />
					</Button>
				</Box>
			</Grid>
		</Grid>
	);
};
