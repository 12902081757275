import { EColumnTitle, ICapitalActivityTemplate, IColumn } from './types';

export const getInvestorColumns = (
	template: ICapitalActivityTemplate,
): IColumn[] => {
	return template.columns.filter(
		(column) =>
			column.title[0].text &&
			column.title[0].text === EColumnTitle.INVESTOR_LEVEL,
	);
};

export const getFundColumns = (
	template: ICapitalActivityTemplate,
): IColumn[] => {
	return template.columns.filter(
		(column) =>
			column.title[0].text && column.title[0].text === EColumnTitle.FUND_LEVEL,
	);
};
