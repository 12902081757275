import { FC } from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { ICapitalAccountStatementHeader } from './types';
import { useStyles } from './styles';
import { HeaderOptions } from './HeaderOptions';
import { EdgeCaseMessage } from './EdgeCaseMessage';
import { useSelector } from 'react-redux';
import { selectNavbarMobile } from 'api/redux/NavBarStateReducer';

export const CapitalAccountStatementHeader: FC<ICapitalAccountStatementHeader> =
	({
		setError,
		currentReportingPeriod,
		capitalActivityData,
		loadCapitalActivity,
		investorAllowedReportTypes,
		afterDelete,
		hasPermission,
	}) => {
		const classes = useStyles();
		const mobile = useSelector(selectNavbarMobile);

		return (
			<Box className={classes.headerBox}>
				<Paper elevation={0} className={classes.headerPaper}>
					<Typography
						variant="h4"
						className={classes.headerText}
						{...(mobile && { style: { display: 'none' } })}
					>
						Capital Account Statement
					</Typography>
					<HeaderOptions
						loadCapitalActivity={loadCapitalActivity}
						currentReportingPeriod={currentReportingPeriod}
						setError={setError}
						capitalActivityData={capitalActivityData}
						afterDelete={afterDelete}
						hasPermission={hasPermission}
					/>
					<EdgeCaseMessage
						investorAllowedReportTypes={investorAllowedReportTypes}
						capitalActivityData={capitalActivityData}
					/>
				</Paper>
			</Box>
		);
	};
