import { Modal, Box } from '@material-ui/core';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useStyles } from './styles';
import { UserPermissionsHeader } from './user-permissions-header/UserPermissionsHeader';
import { UserPermissionsBody } from './UserPermissionsBody';
import { IInvestorGrant, IUserWithInvestors } from 'api/redux/services/userApi';
import { useLazyGetUserInvestorQuery } from 'api/redux/services/investors.service';

interface IUserPermissionsModalProps {
	isOpen: boolean;
	handleClose: () => void;
	user: IUserWithInvestors;
	investors: IInvestorGrant[];
	selectedInvestorId: number;
	setSelectedInvestorId: Dispatch<SetStateAction<number | undefined>>;
	onEditUserClick: () => void;
	handleReload: () => void;
	onClose: (cancel: boolean) => void;
}

export const UserPermissionsModal: FC<IUserPermissionsModalProps> = ({
	isOpen,
	handleClose,
	user,
	investors,
	selectedInvestorId,
	setSelectedInvestorId,
	onEditUserClick,
	handleReload,
	onClose,
}) => {
	const classes = useStyles();
	const [getUserInvestor, { data: userInvestorData }] =
		useLazyGetUserInvestorQuery();
	const [investorUser, setInvestorUser] = useState<IUserWithInvestors>(user);

	useEffect(() => {
		if (isOpen && selectedInvestorId) {
			getUserInvestor({ investorId: selectedInvestorId, userId: user.id });
		}
	}, [getUserInvestor, isOpen, selectedInvestorId, user.id]);

	useEffect(() => {
		if (!userInvestorData) return;

		const updatedInvestorUser = {
			...user,
			name: userInvestorData.name,
			role: userInvestorData.role,
		};

		setInvestorUser(updatedInvestorUser);
	}, [user, userInvestorData]);

	const handleUserPermissionsReload = () => {
		getUserInvestor({ investorId: selectedInvestorId, userId: user.id });
		handleReload();
	};

	return (
		<Modal open={isOpen} onClose={handleClose}>
			<Box className={classes.boxContainer}>
				<UserPermissionsHeader
					user={investorUser}
					investors={investors}
					selectedInvestorId={selectedInvestorId}
					setSelectedInvestorId={setSelectedInvestorId}
					handleClose={handleClose}
					onEditUserClick={onEditUserClick}
					handleReload={handleUserPermissionsReload}
					onClose={(cancel) => onClose(cancel)}
				/>
				<UserPermissionsBody
					handleClose={handleClose}
					user={user}
					selectedInvestorId={selectedInvestorId}
				/>
			</Box>
		</Modal>
	);
};
