import { FC, useEffect } from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { useLazyGetDisclaimerQuery } from 'api/redux/services/organizations.service';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { useStyles } from './styles';

export const CapitalAccountStatementDisclaimer: FC = () => {
	const classes = useStyles();
	const grants = useSelector(selectDropdown);
	const [getDisclaimer, { data: disclaimer }] = useLazyGetDisclaimerQuery();

	useEffect(() => {
		getDisclaimer(grants.grants.currentSponsor.id);
	}, [grants.grants.currentSponsor.id, getDisclaimer]);

	return (
		<Box className={classes.disclaimerBox}>
			<Paper elevation={0} className={classes.disclaimerPaper}>
				<Typography
					variant="h6"
					style={{ color: 'black', fontStyle: 'italic', fontSize: '1rem' }}
				>
					{disclaimer?.disclaimer}
				</Typography>
			</Paper>
		</Box>
	);
};
