import {
	FetchArgs,
	FetchBaseQueryError,
	FetchBaseQueryMeta,
	fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import {
	BaseQueryApi,
	QueryReturnValue,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { RootState } from '../api/redux/store';
import { configApi } from '../api/redux/services/config';

const rawBaseQuery = fetchBaseQuery({
	baseUrl: '',
	prepareHeaders: (headers: Headers) => {
		const token = sessionStorage.getItem('token');
		if (token) headers.set('Authorization', `Bearer ${token}`);
		return headers;
	},
});

export const dynamicBaseQuery =
	(stem: string) =>
	async (
		args: FetchArgs | string,
		api: BaseQueryApi,
		// eslint-disable-next-line @typescript-eslint/ban-types
		extraOptions: {},
	): Promise<
		QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>
	> => {
		const state: RootState = api.getState();
		const { data: config } = configApi.endpoints.getConfig.select()(state);

		const urlEnd = typeof args === 'string' ? args : args.url;
		const adjustedUrl = `${config?.apiBaseUrl}/${stem}/${urlEnd}`;

		const adjustedArgs =
			typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl };

		return rawBaseQuery(adjustedArgs, api, extraOptions);
	};

export default dynamicBaseQuery;
