import { Button, makeStyles } from '@material-ui/core';
import { DocumentType } from 'app/types/documentTypes.types';
import React, { MouseEventHandler } from 'react';
import { FC } from 'react';

interface IOvalButtonProps {
	label: string;
	onClick: MouseEventHandler<HTMLButtonElement>;
	selectedDocumentType: DocumentType;
	id: number;
}

const useStyles = makeStyles({
	buttonWrapper: {
		color: 'black',
		width: 230,
		fontSize: 16,
		justifyContent: 'right',
		paddingRight: 25,
		flex: '1 0 auto',
		height: 62,
		textTransform: 'none',
		borderBottomLeftRadius: '25px 25px',
		borderTopLeftRadius: '25px 25px',

		'&:focus': {
			background: '#989898 !important',
		},
	},
	selectedButtonWrapper: {
		color: 'black',
		width: 230,
		fontSize: 16,
		justifyContent: 'right',
		paddingRight: 25,
		flex: '1 0 auto',
		height: 62,
		textTransform: 'none',
		borderBottomLeftRadius: '25px 25px',
		borderTopLeftRadius: '25px 25px',

		background: '#989898 !important',
	},
	buttonOval: {
		width: 10,
		height: 40,
		borderBottomLeftRadius: '25px 25px',
		borderTopLeftRadius: '25px 25px',
		position: 'relative',
		left: 25,
	},
	spanWidth: {
		width: 230,
		textAlign: 'right',
	},
	selectedLabel: {
		fontWeight: 'bold',
		width: 230,
		textAlign: 'right',
	},
});

export const OvalButton: FC<IOvalButtonProps> = ({
	label,
	onClick,
	id,
	selectedDocumentType,
}) => {
	const classes = useStyles();

	return (
		<Button
			className={
				selectedDocumentType.name == label
					? classes.selectedButtonWrapper
					: classes.buttonWrapper
			}
			onClick={onClick}
			title={label}
		>
			<span
				className={
					selectedDocumentType.name == label
						? classes.selectedLabel
						: classes.spanWidth
				}
				id={id.toString()}
			>
				{label}
			</span>
			<span
				id={id.toString()}
				className={classes.buttonOval}
				style={{ display: 'block' }}
			></span>
		</Button>
	);
};
