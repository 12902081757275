import { Box, Grid } from '@mui/material';
import { FC } from 'react';
import { IconBtn } from '../IconBtn';
import { hasPermission } from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { useSelector } from 'react-redux';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import {
	ArrowDownwardOutlined,
	ArrowUpwardOutlined,
	Delete,
	Edit,
	VisibilityOutlined,
} from '@mui/icons-material';
import { User } from 'app/types/user.types';
import { useAuth0 } from '@auth0/auth0-react';
import { ROLES } from 'common/helpers/permissions/Roles';
import { useLocation } from 'react-router-dom';
import { ERoutes } from 'App';

interface IUserActionsProps {
	user: User;
	onEdit: () => void;
	onDelete: () => void;
}

interface IEditUserActionProps {
	user: User;
	onEdit: () => void;
}

interface IEditIconProps {
	canEdit: boolean;
}

interface IDeleteUserActionProps {
	user: User;
	onDelete: () => void;
}

const EditIcon: FC<IEditIconProps> = ({ canEdit }) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);

	if (canEdit) return <Edit sx={{ color: `${sponsorColor}` }} />;

	return <VisibilityOutlined sx={{ color: `${sponsorColor}` }} />;
};

const EditUserAction: FC<IEditUserActionProps> = ({ user, onEdit }) => {
	const canEditInvestorPermissions = hasPermission({
		scopes: [SCOPES.canEditInvestorPermissions],
	});
	const tooltip = canEditInvestorPermissions ? 'Edit User' : 'View User Info';

	if (user.role.includes('Sponsor')) return <></>;

	return (
		<Box style={{ display: 'flex', justifyContent: 'center' }}>
			<IconBtn onClick={onEdit} tooltip={tooltip}>
				<EditIcon canEdit={canEditInvestorPermissions} />
			</IconBtn>
		</Box>
	);
};

const DeleteUserAction: FC<IDeleteUserActionProps> = ({ user, onDelete }) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const { user: auth0User } = useAuth0();
	const { pathname } = useLocation();
	const isOrganizationPage = [ERoutes.OrganizationView].includes(
		pathname as ERoutes,
	);
	const subject: string = isOrganizationPage ? 'sponsor' : 'investor';

	if (
		!hasPermission({
			scopes: [SCOPES.canDeleteUsers],
		}) ||
		user.email === auth0User?.email
	)
		return <></>;

	return (
		<Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
			<IconBtn onClick={onDelete} tooltip={`Remove User From ${subject}`}>
				<Delete sx={{ color: `${sponsorColor}` }} />
			</IconBtn>
		</Box>
	);
};

export const UserActions: FC<IUserActionsProps> = ({
	user,
	onEdit,
	onDelete,
}) => {
	return (
		<Grid container direction="row" spacing={2} sx={{ maxWidth: '150px' }}>
			<Grid item xs={4} md={4}>
				<EditUserAction user={user} onEdit={onEdit} />
			</Grid>
			<Grid item xs={4} md={4}>
				<DeleteUserAction user={user} onDelete={onDelete} />
			</Grid>
		</Grid>
	);
};
