import { useAuth0 } from '@auth0/auth0-react';
import { Redirect, Route } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PrivateRoute: any = ({ component: Component, ...rest }) => {
	const { isAuthenticated } = useAuth0();
	return (
		<Route
			{...rest}
			render={(props) =>
				isAuthenticated ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: '/',
							state: { from: props.location },
						}}
					/>
				)
			}
		/>
	);
};

export default PrivateRoute;
