import { FC, useEffect } from 'react';
import { Box, Button, Paper } from '@mui/material';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
	docsUploadState,
	onSetDateDialogOpen,
} from 'api/redux/DocsUploadReducer';
import { useDropzone } from 'react-dropzone';
import { useStyles } from './styles';
import { useFileContext } from './FileContext';
import { findInvestorFromFileName } from './utils';
import { useLazyGetInvestorsQuery } from 'api/redux/services/investors.service';
import { selectDropdown } from 'api/redux/DropdownReducer';

export const DocumentsDropZone: FC = () => {
	const classes = useStyles();
	const grants = useSelector(selectDropdown);
	const { currentFund } = grants.grants;
	const [getInvestors, investors] = useLazyGetInvestorsQuery();
	const { selectedDocType, selectedDocDate, dateConfirmed } =
		useSelector(docsUploadState);
	const { acceptedFiles, getInputProps, getRootProps } = useDropzone({
		accept: { 'application/pdf': ['.pdf'] },
	});
	const { uploadedFiles, addUploadedFiles } = useFileContext();
	const dispatch = useDispatch();

	useEffect(() => {
		if (currentFund.id) getInvestors(currentFund.id);
	}, [currentFund.id]);

	useEffect(() => {
		if (!selectedDocDate) return;

		const selectedDate: Date = new Date(selectedDocDate);
		const today = new Date();

		if (
			selectedDate.getDate() == today.getDate() &&
			selectedDate.getMonth() == today.getMonth() &&
			selectedDate.getFullYear() == today.getFullYear()
		) {
			dispatch(onSetDateDialogOpen(true));
		}
	}, [selectedDocDate]);

	useEffect(() => {
		if (
			acceptedFiles.length > 0 &&
			selectedDocDate &&
			dateConfirmed &&
			investors.data
		) {
			buildFiles();
		}
	}, [acceptedFiles]);

	const buildFiles = () => {
		const fileList = acceptedFiles.map((file) => ({
			filename: file.name,
			date: selectedDocDate,
			investor: '',
			documentType: selectedDocType?.name || '',
			documentTypeId: selectedDocType?.id || 1,
			guessed: false,
			file: file,
		}));

		fileList.forEach(async (file) => {
			const foundInvestor = findInvestorFromFileName(
				investors.data || [],
				file.filename,
			);
			if (foundInvestor) {
				file.investor = foundInvestor.id.toString();
				file.guessed = true;
			}
		});

		addUploadedFiles(fileList);

		acceptedFiles.length = 0;
	};

	if (!selectedDocType) return <></>;

	return (
		<Paper className={classes.paper}>
			<Box {...getRootProps({ className: classes.dropzone })}>
				<input {...getInputProps({ disabled: !selectedDocType })} />
				<p className={`material-icons ${classes.icon}`}>cloud_upload</p>
				<Typography style={{ fontWeight: 'bold' }} variant={'h4'}>
					{selectedDocType && `Upload ${selectedDocType.name}`}
					<br />
				</Typography>
				<Typography style={{ textAlign: 'center' }}>
					Drag and drop your .pdf files here to upload it to the selected
					folder.
				</Typography>
				<Button className={classes.button} variant="outlined">
					Browse Files
				</Button>
			</Box>
			<Box>
				<Typography align="center">
					Accepted Files: {uploadedFiles.length}
				</Typography>
			</Box>
		</Paper>
	);
};
