import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { ERoutes } from 'App';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';

export const BackToDocuments: FC = () => {
	const history = useHistory();
	const sponsorColor = useSelector(selectSponsorPrimaryColor);

	return (
		<Box textAlign={'end'}>
			<Button
				variant="text"
				sx={{
					textTransform: 'none',
					padding: '5px 0px',
					color: sponsorColor,
				}}
				onClick={() => history.push(ERoutes.Documents)}
			>
				<ArrowBack sx={{ marginRight: '5px' }} /> Back to Documents
			</Button>
		</Box>
	);
};
