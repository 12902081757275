import { FC } from 'react';
import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';
import PermissionsWrapper from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { InvestorInfo } from './investor-info/InvestorInfo';
import { InvestorMembers } from './InvestorsMembers';
import { Box } from '@mui/material';

export const InvestorView: FC = () => {
	return (
		<MainSidebarContainer>
			<Box sx={{ height: '100%', margin: '5px', width: 'min(100%, 1300px)' }}>
				<PermissionsWrapper scopes={[SCOPES.canSeeInvestorInfo]}>
					<InvestorInfo />
				</PermissionsWrapper>

				<InvestorMembers />
			</Box>
		</MainSidebarContainer>
	);
};
