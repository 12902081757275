import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
	inputWrapper: {
		flex: '1 0 auto',
		overflow: 'auto',
		padding: 5,
		margin: 10,
		border: '1px solid #e3e3e3',
	},
});
