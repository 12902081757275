import { FC } from 'react';
import { Box } from '@mui/material';
import { Typography } from '@material-ui/core';

export const ByRelationshipInfo: FC = () => {
	return (
		<Box sx={{ padding: '2rem' }}>
			<Typography
				variant={'h3'}
				align="center"
				style={{ paddingBottom: '2rem' }}
			>
				Export By Relationship
			</Typography>
			<Typography variant="h6">
				This export mode will create a row for every combination of user,
				investor, and fund. If a user exists in one investor that is in 3 funds,
				the user will appear 3 times
			</Typography>
		</Box>
	);
};
