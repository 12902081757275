import { FC } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Clear, Search } from '@mui/icons-material';

export const useStyles = makeStyles({
	searchBarContainer: {
		display: 'flex',
		alignItems: 'center',
		paddingBottom: '5px',
		width: '300px !important',
		margin: '0 10px 0 0 !important',
	},
	searchBarIcon: {
		cursor: 'pointer',
		marginRight: '10px',
	},
	searchBarInput: {
		border: 'none',
		borderBottom: 'none !important',
		outline: 'none',
		margin: '0 30px 0 0 !important',
		flex: 1,
	},
});

interface ISearchBarProps {
	value: any;
	onChange: (val: string) => void;
	onCancelSearch: () => void;
}

export const SearchBar: FC<ISearchBarProps> = ({
	value,
	onChange,
	onCancelSearch,
}) => {
	const classes = useStyles();

	return (
		<div className={classes.searchBarContainer}>
			<input
				type="text"
				value={value}
				onChange={(e) => {
					onChange(e.target.value);
				}}
				className={classes.searchBarInput}
				placeholder="Search..."
			/>
			{value ? (
				<Clear className={classes.searchBarIcon} onClick={onCancelSearch} />
			) : (
				<Search className={classes.searchBarIcon} />
			)}
		</div>
	);
};
