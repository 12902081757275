import { FC } from 'react';
import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';
import OrganizationMembers from './OrganizationMembers';
import { Box } from '@mui/material';

const OrganizationView: FC = () => {
	return (
		<MainSidebarContainer>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					height: '100%',
					margin: '5px',
				}}
			>
				<OrganizationMembers />
			</Box>
		</MainSidebarContainer>
	);
};

export default OrganizationView;
