import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import {
	useLazyGetAnnouncementsForMultipleSponsorsQuery,
	useUpdateAnnouncementMutation,
	useDeleteAnnouncementMutation,
} from 'api/redux/services/announcementsApi';
import { Box, Button, Container } from '@mui/material';
import { Typography } from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import PermissionsWrapper from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { Announcement } from 'app/types/Announcements.types';
import { EditAnnouncementModal } from './EditAnnouncementModal';
import { useStyles } from './AnnouncmentsStyles';

export const Announcements: FC = () => {
	const classes = useStyles();
	const grants = useSelector(selectDropdown);
	const { currentSponsor } = grants.grants;
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const [getAnnouncementsForMultipleSponsors, announcements] =
		useLazyGetAnnouncementsForMultipleSponsorsQuery();
	const [updateAnnouncement] = useUpdateAnnouncementMutation();
	const [deleteAnnouncement, refetch] = useDeleteAnnouncementMutation();
	const [currentAnnouncement, setCurrentAnnouncement] =
		useState<Announcement>();
	const [announcementsList, setAnnouncementsList] = useState<Announcement[]>(
		[],
	);
	const [editModalOpen, setEditModalOpen] = useState(false);

	useEffect(() => {
		if (currentSponsor.id === 0) return;

		getAnnouncementsForMultipleSponsors([currentSponsor.id]);
	}, [
		getAnnouncementsForMultipleSponsors,
		editModalOpen,
		currentSponsor,
		refetch,
	]);

	useEffect(() => {
		if (!announcements.data) return;

		setAnnouncementsList(announcements.data);
	}, [announcements.data]);

	const handleEditModalOpen = (announcement) => {
		setEditModalOpen(true);
		setCurrentAnnouncement(announcement);
	};

	const handleEditModalClose = () => {
		setEditModalOpen(false);
	};

	const handleEditModalSave = async (text) => {
		if (!currentAnnouncement?.id) return;

		await updateAnnouncement({ id: currentAnnouncement?.id, text });

		setEditModalOpen(false);
	};

	return (
		<Box className={classes.root}>
			<Typography variant="h4">Announcements</Typography>
			<Container className={classes.scrollGrid}>
				{announcementsList.length === 0 && (
					<Typography>You do not currently have any announcements.</Typography>
				)}
				{announcementsList.map((announcement, index) => {
					return (
						<Box
							key={index}
							style={{ display: 'flex', justifyContent: 'space-between' }}
						>
							<Typography
								key={index}
								style={{
									whiteSpace: 'pre-line',
									width: '80%',
									overflowWrap: 'break-word',
									fontSize: '1rem',
								}}
							>
								{announcement.text}
							</Typography>
							<Box>
								<PermissionsWrapper scopes={[SCOPES.canEditAnnouncement]}>
									<Button
										className={classes.editButton}
										onClick={() => handleEditModalOpen(announcement)}
									>
										<Edit style={{ color: `${sponsorColor}` }} />
									</Button>
								</PermissionsWrapper>
								<PermissionsWrapper scopes={[SCOPES.canDeleteAnnouncement]}>
									<Button
										className={classes.deleteButton}
										onClick={() =>
											deleteAnnouncement({
												announcementId: announcement.id,
											})
										}
									>
										<Delete style={{ color: `${sponsorColor}` }} />
									</Button>
								</PermissionsWrapper>
							</Box>
						</Box>
					);
				})}
			</Container>
			{editModalOpen && currentAnnouncement && (
				<EditAnnouncementModal
					open={editModalOpen}
					announcement={currentAnnouncement}
					handleEditModalSave={handleEditModalSave}
					handleEditModalClose={handleEditModalClose}
				/>
			)}
		</Box>
	);
};

export default Announcements;
