import { FC } from 'react';
import { IExportButtonProps } from './types';
import { Box, Button, Tooltip } from '@mui/material';
import { useStyles } from './styles';

export const ExportButton: FC<IExportButtonProps> = ({
	exportDisabled,
	onExportClick,
}) => {
	const classes = useStyles();

	return (
		<Box className={classes.exportButton}>
			<Tooltip
				title={
					exportDisabled
						? 'Please select at least 1 role, column and fund to start exporting'
						: ''
				}
			>
				<span>
					<Button
						disabled={exportDisabled}
						onClick={onExportClick}
						variant="outlined"
					>
						Export
					</Button>
				</span>
			</Tooltip>
		</Box>
	);
};
