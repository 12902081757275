import { FC } from 'react';
import PermissionsWrapper from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { useSelector } from 'react-redux';
import { useStyles } from './styles';
import { Box, Button, CircularProgress } from '@mui/material';

interface ICancelOrSaveProps {
	changesMade: boolean;
	handleSave: () => void;
	saving: boolean;
}
export const SaveTabButton: FC<ICancelOrSaveProps> = ({
	changesMade,
	handleSave,
	saving,
}) => {
	const classes = useStyles();
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	return (
		<PermissionsWrapper scopes={[SCOPES.canEditInvestorPermissions]}>
			<Box className={classes.buttons}>
				<Button
					disabled={!changesMade}
					onClick={handleSave}
					className={classes.saveButton}
					variant="outlined"
					sx={{
						color: '#FFFFFF',
						backgroundColor: `${sponsorColor}`,
						'&.Mui-disabled': {
							backgroundColor: '#c3cfd9',
							color: 'white',
						},
					}}
				>
					{saving ? <CircularProgress size={20} /> : 'Save'}
				</Button>
			</Box>
		</PermissionsWrapper>
	);
};
