import { FC } from 'react';
import { IColumn } from '../types';
import { Box } from '@mui/material';
import { TableCell, TableRow, useStyles } from './styles';
import { useSelector } from 'react-redux';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { getInvestorColumns, getFundColumns } from './utils';
import { TableHead, Typography } from '@material-ui/core';
import {
	ISecondHeaderRowProps,
	ITableHeadersProps,
	TemplateType,
	TemplateTypesToColSpan,
} from './types';

const FirstHeaderRow: FC<ITableHeadersProps> = ({
	capitalActivityTemplate,
	reportingPeriod,
}) => {
	const classes = useStyles();
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const columns = [
		...getInvestorColumns(capitalActivityTemplate),
		...getFundColumns(capitalActivityTemplate),
	];

	return (
		<TableRow
			style={{
				backgroundColor: `${sponsorColor}`,
			}}
		>
			<TableCell></TableCell>
			{columns.map(
				(column: IColumn, i) =>
					column.title && (
						<TableCell
							key={i}
							style={{
								width:
									capitalActivityTemplate.templateId === TemplateType.NO_ITD
										? '25rem'
										: '35rem',
							}}
						>
							<Box sx={{ padding: '10px' }}>
								<Typography
									key={`${column.title[1].text}-1`}
									className={classes.columnLabels}
								>
									{column.title[1].text.replace('{{REPORTING_PERIOD}}', '')}
								</Typography>
								<Typography
									key={`${column.title[1].text}-2`}
									className={classes.columnLabels}
								>
									{reportingPeriod?.replace('Period ending ', '')}
								</Typography>
							</Box>
						</TableCell>
					),
			)}
		</TableRow>
	);
};

const SecondHeaderRow: FC<ISecondHeaderRowProps> = ({
	capitalActivityTemplate,
}) => {
	const classes = useStyles();

	return (
		<TableRow>
			<TableCell></TableCell>
			<TableCell
				colSpan={TemplateTypesToColSpan[capitalActivityTemplate.templateId]}
			>
				<Typography className={classes.columnSubLabels}>
					Investor Level
				</Typography>
			</TableCell>
			<TableCell
				colSpan={TemplateTypesToColSpan[capitalActivityTemplate.templateId]}
			>
				<Typography className={classes.columnSubLabels}>Fund Level</Typography>
			</TableCell>
		</TableRow>
	);
};

export const CASTableHeaders: FC<ITableHeadersProps> = ({
	capitalActivityTemplate,
	reportingPeriod,
}) => {
	return (
		<TableHead>
			<FirstHeaderRow
				capitalActivityTemplate={capitalActivityTemplate}
				reportingPeriod={reportingPeriod}
			/>
			<SecondHeaderRow capitalActivityTemplate={capitalActivityTemplate} />
		</TableHead>
	);
};
