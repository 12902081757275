import { FC, useEffect } from 'react';
import {
	Checkbox,
	FormControlLabel,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFileContext } from './FileContext';
import { formatFileDate } from './utils';
import { IconBtn } from 'common/components/IconBtn';
import { Check, Close, Delete } from '@mui/icons-material';
import { useStyles } from './styles';
import { Investor } from 'app/types/investor.types';
import { useSelector } from 'react-redux';
import { docsUploadState } from 'api/redux/DocsUploadReducer';
import { EDocumentLevels, IFile } from './types';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { Typography } from '@material-ui/core';
import { useLazyGetInvestorsQuery } from 'api/redux/services/investors.service';
import { selectDropdown } from 'api/redux/DropdownReducer';

interface IConfirmGuessedInvestor {
	index: number;
	investorId: string;
	file: IFile;
}

const ConfirmGuessedInvestor: FC<IConfirmGuessedInvestor> = ({
	index,
	investorId,
	file,
}) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const { setInvestorId } = useFileContext();

	return (
		<FormControlLabel
			style={{ width: 250 }}
			control={
				<Checkbox
					size="small"
					onChange={() => setInvestorId(index, investorId)}
					checked={!file.guessed}
					style={{
						color: sponsorColor,
						padding: 3,
						marginLeft: 5,
					}}
				/>
			}
			label={
				<Typography
					style={{
						fontSize: 14,
						color: 'black',
					}}
				>
					Confirm Investor
				</Typography>
			}
		></FormControlLabel>
	);
};

export const DocumentsTable: FC = () => {
	const classes = useStyles();
	const { selectedLevel } = useSelector(docsUploadState);
	const grants = useSelector(selectDropdown);
	const { currentFund } = grants.grants;
	const { uploadedFiles, removeUploadedFile, setInvestorId } = useFileContext();
	const [getInvestors, investors] = useLazyGetInvestorsQuery();

	const TC = styled(TableCell)({
		borderBottom: 'none',
	});
	const TR = styled(TableRow)({
		borderBottom: 'none',
	});

	useEffect(() => {
		if (currentFund.id) getInvestors(currentFund.id);
	}, [currentFund.id]);

	if (uploadedFiles.length === 0) return <></>;

	return (
		<Paper className={classes.paper}>
			<TableContainer>
				<Table aria-label="simple table">
					<TableHead sx={{ backgroundColor: '#d4d9ec' }}>
						<TR>
							<TC>Name</TC>
							<TC>Document Date</TC>
							{selectedLevel.name === EDocumentLevels.INVESTOR_LEVEL && (
								<TC>Investor</TC>
							)}
							{selectedLevel.name === EDocumentLevels.INVESTOR_LEVEL && (
								<TC>Assigned</TC>
							)}
							<TC>Document Type</TC>
							<TC />
						</TR>
					</TableHead>
					<TableBody>
						{uploadedFiles.map((file, i) => (
							<TR key={(file.filename, i)}>
								<TC>{file.filename}</TC>
								<TC>{formatFileDate(file)}</TC>
								{selectedLevel.name === EDocumentLevels.INVESTOR_LEVEL && (
									<TC>
										{
											<Select
												labelId="doc-type-select-label"
												id="doc-type-select-control"
												value={file.investor}
												sx={{
													width: '100%',
												}}
											>
												{investors.data?.map((inv: Investor) => (
													<MenuItem
														key={inv.id}
														value={inv.id.toString()}
														onClick={() => {
															setInvestorId(i, inv.id.toString());
														}}
													>
														{`${inv.name} (${inv.investorId})`}
													</MenuItem>
												))}
											</Select>
										}
									</TC>
								)}
								{selectedLevel.name === EDocumentLevels.INVESTOR_LEVEL && (
									<TC>
										{file.investor ? (
											file.guessed ? (
												<ConfirmGuessedInvestor
													index={i}
													investorId={file.investor}
													file={file}
												/>
											) : (
												<Check sx={{ color: 'green' }} />
											)
										) : (
											<Close sx={{ color: 'red' }} />
										)}
									</TC>
								)}
								<TC>{file.documentType}</TC>
								<TC>
									<IconBtn
										onClick={() => removeUploadedFile(i)}
										tooltip="Remove this file"
									>
										<Delete />
									</IconBtn>
								</TC>
							</TR>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
};
