import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
	paper: {
		height: 'max-content',
		width: '100%',
		marginTop: 16,
		marginBottom: 32,
		borderRadius: 5,
		padding: 16,
	},
	datePickerWrapper: {
		display: 'flex',
	},
	datePicker: {
		border: '1px solid #c0c0c0 !important',
		borderRadius: '4px !important',
		padding: '4px 4px 4px 10px !important',
		width: '100%',
	},
	modalPaper: {
		display: 'flex',
		flexDirection: 'column',
		border: '1px solid grey',
		width: '80%',
		marginLeft: 'auto',
		marginRight: 'auto',
		padding: 10,
	},
	dropzone: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		padding: 10,
		width: '100%',
		backgroundColor: 'white',
	},
	icon: {
		fontSize: '60px',
		margin: 0,
	},
	button: {
		backgroundColor: 'white !important',
		textTransform: 'inherit',
		height: '30px !important',
		marginTop: '15px !important',
		marginBottom: '15px !important',
		marginLeft: '10px !important',
		width: '150px !important',
		fontWeight: 'bold',
		padding: '2px !important',
		fontSize: 14,
		color: '#6558f5 !important',
		border: '1px solid #cfcdf9 !important',
	},
	notificationText: {
		margin: 10,
		display: 'inline !important',
	},
});
