import { Delete } from '@material-ui/icons';
import { IconButton } from '@mui/material';
import { FC, MouseEventHandler } from 'react';

interface IDeleteButtonProps {
	onClick: MouseEventHandler<HTMLButtonElement>;
}

export const DeleteButton: FC<IDeleteButtonProps> = ({ onClick }) => {
	return (
		<IconButton onClick={onClick} size="small">
			<Delete />
		</IconButton>
	);
};
