import { FC } from 'react';
import { CSVLink } from 'react-csv';
import { useStyles } from './styles';
import { IconBtn } from 'common/components/IconBtn';
import { GetApp } from '@mui/icons-material';

export const BulkUploadInvestorsTemplate: FC = () => {
	const classes = useStyles();

	const headers = [
		{ label: 'name', key: 'details.N' },
		{ label: 'investorId', key: 'details.II' },
		{ label: 'phone', key: 'details.P' },
		{ label: 'email', key: 'details.E' },
		{ label: 'address1', key: 'details.A1' },
		{ label: 'address2', key: 'details.A2' },
		{ label: 'city', key: 'details.C' },
		{ label: 'state', key: 'details.S' },
		{ label: 'zip', key: 'details.Z' },
		{ label: 'country', key: 'details.CN' },
		{ label: 'note', key: 'details.NT' },
	];
	const data = [
		{
			details: {
				N: '',
				II: '',
				P: '',
				E: '',
				A1: '',
				A2: '',
				C: '',
				CT: '',
				S: '',
				Z: '',
				CN: '',
				NT: '',
			},
		},
	];
	const csvReport = {
		data,
		headers,
		filename: 'BulkUploadInvestorsTemplate.csv',
	};

	return (
		<IconBtn tooltip="Download Bulk Template">
			<CSVLink {...csvReport} className={classes.csvLink}>
				<GetApp />
			</CSVLink>
		</IconBtn>
	);
};
