import { PERMISSIONS } from './Permissions';
import { useAuth0Grants } from './useGetAuth0Grants';

type TPermissionsWrapperParams = {
	children: JSX.Element;
	scopes: string[];
	checkAllSponsorRoles?: boolean;
};

type THasPermissionParams = {
	scopes: string[];
	checkAllSponsorRoles?: boolean;
};

export const hasPermission = ({
	scopes,
	checkAllSponsorRoles = false,
}: THasPermissionParams): boolean => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { currentRoles, allSponsorRoles } = useAuth0Grants();

	if (checkAllSponsorRoles) {
		return checkPermission(scopes, allSponsorRoles);
	}

	return checkPermission(scopes, currentRoles);
};

const checkPermission = (scopes: string[], roles: string[]): boolean => {
	if (!roles) return false;
	const permissions: string[] = [];
	roles.forEach((role) => permissions.push(...PERMISSIONS[role]));

	const scopesMap = {};
	scopes.forEach((scope) => {
		scopesMap[scope] = true;
	});

	return permissions.some((permission) => scopesMap[permission]);
};

const PermissionsWrapper = ({
	children,
	scopes,
	checkAllSponsorRoles = false,
}: TPermissionsWrapperParams): JSX.Element => {
	const permissionGranted = hasPermission({ scopes, checkAllSponsorRoles });
	if (!permissionGranted) return <></>;

	return <>{children}</>;
};

export default PermissionsWrapper;
