import { selectDropdown } from 'api/redux/DropdownReducer';
import { useGetLightLogoQuery } from 'api/redux/services/organizations.service';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useStyles } from './styles';
import { ERoutes } from 'App';

export const SponsorLogo: FC = () => {
	const classes = useStyles();
	const grants = useSelector(selectDropdown);
	const { currentSponsor } = grants.grants;
	const { data: logo } = useGetLightLogoQuery(currentSponsor.id);

	if (!logo) return <div className={classes.links}></div>;

	return (
		<NavLink className={classes.links} to={ERoutes.Dashboard}>
			<img src={logo?.url} className={classes.imgSize} />
		</NavLink>
	);
};
