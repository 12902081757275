import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { IFundGrant, IInvestorGrant, ISponsorGrant } from './services/userApi';
import {
	emptyFund,
	emptyInvestor,
	emptySponsor,
	GrantState,
	IObjectWithName,
	ISimpleGrant,
} from './types';
import { getCookie } from 'common/helpers/Cookies';

const initialState: GrantState = {
	grants: {
		currentSponsor: emptySponsor,
		currentFund: emptyFund,
		currentInvestor: emptyInvestor,
		availableSponsors: [],
		availableFunds: [],
		availableInvestors: [],
		allAvailableInvestors: [],
	},
};

const sortByName = (collection: IObjectWithName[]): IObjectWithName[] => {
	const sortedCollection = [...collection];

	return sortedCollection.sort((a, b) => (a.name < b.name ? -1 : 1));
};

const getAllAvailableInvestors = (sponsor: ISponsorGrant): IInvestorGrant[] => {
	const investors: IInvestorGrant[] = [];

	if (!sponsor.funds || sponsor.funds.length === 0) return investors;

	for (let i = 0; i < sponsor.funds.length; i++) {
		const fund = sponsor.funds[i];
		if (!fund.investors) continue;

		for (let i = 0; i < fund.investors.length; i++) {
			const investor = fund.investors[i];
			if (!investors.find((inv) => inv.id === investor.id))
				investors.push(investor);
		}
	}

	return sortByName(investors) as IInvestorGrant[];
};

const loadFromCookies = (availableSponsors: ISponsorGrant[]): ISimpleGrant => {
	const sponsorIdSession: string =
		sessionStorage.getItem('initialSponsorId') || '';
	sessionStorage.removeItem('initialSponsorId');

	const sponsorIdCookie: string = sponsorIdSession || getCookie('sponsorId');
	const fundIdCookie: string = getCookie('fundId');
	const investorIdCookie: string = getCookie('investorId');
	let fund: IFundGrant | undefined;
	let investor: IInvestorGrant | undefined;

	let sponsor: ISponsorGrant | undefined =
		sponsorIdCookie !== ''
			? availableSponsors.find((s) => s.id === parseInt(sponsorIdCookie))
			: undefined;

	if (sponsor) {
		fund =
			fundIdCookie !== ''
				? sponsor.funds.find((f) => f.id === parseInt(fundIdCookie))
				: sponsor.funds.length > 0
				? sponsor.funds[0]
				: undefined;
		if (fund) {
			investor =
				investorIdCookie !== ''
					? fund.investors.find((i) => i.id === parseInt(investorIdCookie))
					: fund.investors.length > 0
					? fund.investors[0]
					: undefined;
		}
	}

	if (!sponsor) {
		[sponsor] = availableSponsors;
		if (sponsor) [fund] = sponsor.funds;
		if (fund) [investor] = fund?.investors;
	}

	return {
		sponsor,
		fund,
		investor,
	};
};

export const grantSlice: Slice = createSlice({
	name: 'grants',
	initialState,
	reducers: {
		onChangeSponsor: (state, action: PayloadAction<ISponsorGrant>) => {
			const { payload: selectedSponsor } = action;
			const [firstFund] = selectedSponsor.funds.length
				? selectedSponsor.funds
				: [emptyFund];
			const [firstInvestor] = firstFund.investors.length
				? firstFund.investors
				: [emptyInvestor];

			state.grants.currentSponsor = selectedSponsor;
			state.grants.currentFund = firstFund;
			state.grants.currentInvestor = firstInvestor;

			state.grants.allAvailableInvestors =
				getAllAvailableInvestors(selectedSponsor);
			state.grants.availableFunds = sortByName(selectedSponsor.funds);
			state.grants.availableInvestors = sortByName(firstFund.investors);
		},
		onChangeFund: (state, action: PayloadAction<IFundGrant>) => {
			const { payload: selectedFund } = action;
			const [firstInvestor] = selectedFund.investors.length
				? selectedFund.investors
				: [emptyInvestor];

			state.grants.currentFund = selectedFund;
			state.grants.currentInvestor = firstInvestor;
			state.grants.availableInvestors = sortByName(selectedFund.investors);
		},
		onChangeInvestor: (state, action) => {
			state.grants.currentInvestor = action.payload;
		},
		onChangeUserGrants: (state, action) => {
			const { userGrants, loadCookies } = action.payload;

			state.grants.availableSponsors = sortByName(userGrants);

			if (loadCookies) {
				const { sponsor, fund, investor } = loadFromCookies(userGrants);

				if (sponsor) {
					state.grants.currentSponsor = sponsor;
					state.grants.availableFunds = sortByName(sponsor.funds);
					state.grants.allAvailableInvestors =
						getAllAvailableInvestors(sponsor);
					if (fund) {
						state.grants.currentFund = fund;
						state.grants.availableInvestors = sortByName(fund.investors);
						if (investor) state.grants.currentInvestor = investor;
					}
				}
			}
		},
	},
});
export const {
	onChangeSponsor,
	onChangeFund,
	onChangeInvestor,
	onChangeUserGrants,
} = grantSlice.actions;

export const selectDropdown = ({ grants }: RootState): GrantState => grants;

export default grantSlice.reducer;
