import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
	tableBox: {
		width: 'min(100%, 1300px)',
	},
	headerPaper: {
		width: '100%',
		marginBottom: '3px',
		padding: '5px',
		display: 'flex',
		justifyContent: 'flex-end',
		border: '1px solid #e3e3e3',
	},
	labelBox: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		padding: 10,
	},
	tableTypography: {
		fontSize: '.85rem',
	},
});
