import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	singleLogoContainer: {
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: 15,
		marginBottom: 15,
		paddingLeft: 10,
	},
	paper: {
		padding: theme.spacing(1),
		borderRadius: 5,
	},
	fixedHeightPaper: {
		minHeight: '21rem',
	},
	root: {
		display: 'flex',
		paddingRight: 0,
		paddingLeft: 0,
		marginRight: 0,
		marginTop: 0,
		maxWidth: '100vw',
		overflow: 'hidden',
	},
	imgSize: {
		maxWidth: '150px',
		maxHeight: '75x',
	},
	constainer: {
		display: 'inline-block',
		width: '80%',
	},
	icon: {
		fontSize: '60px',
		margin: 0,
	},
	wrapper: {
		width: '40%',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: 15,
		marginBottom: 15,
		padding: 15,
		justifyContent: 'center',
		textAlign: 'center',
	},
	dropzone: {
		display: 'flex',
		flexDirection: 'column',
		height: 200,
		justifyContent: 'center',
		alignItems: 'center',
		padding: 20,
		width: '100%',
		backgroundColor: 'white',
		border: '1px dashed gray',
	},
	progress: {
		width: '80%',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	modalPaper: {
		display: 'flex',
		flexDirection: 'column',
		border: '1px solid #000',
		width: '80%',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	button: {
		backgroundColor: 'white !important',
		textTransform: 'inherit',
		height: '30px !important',
		marginTop: '15px !important',
		marginBottom: '15px !important',
		marginLeft: '10px !important',
		width: '150px !important',
		fontWeight: 'bold',
		padding: '2px !important',
		fontSize: 14,
		color: '#6558f5 !important',
		border: '1px solid #cfcdf9 !important',
	},
	buttonContainer: {
		padding: 2,
		flexDirection: 'row',
	},
	fileCardContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		width: '100%',
	},
	fileCard: {
		flexBasis: '45%',
		margin: 10,
		padding: 20,
		paddingBottom: 10,
	},
	select: {
		marginTop: 10,
		marginLeft: 30,
		width: 300,
		display: 'flex',
		fontSize: 16,
	},
	dropdownContainer: {
		'&.MuiFormControl-root': {
			display: 'flex',
			flexDirection: 'row',
			width: '80%',
		},
	},
}));
