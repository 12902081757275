import { Typography, Container, Grid, Paper } from '@material-ui/core';
import {
	useUploadDarkLogoMutation,
	useUploadLightLogoMutation,
} from 'api/redux/services/organizations.service';
import { FC } from 'react';
import { useStyles } from './styles';
import { ELogoTypes, IUploadLogoProps } from './types';
import { UploadLogoCard } from './UploadLogoCard';

export const UploadLogo: FC<IUploadLogoProps> = ({ sponsor }) => {
	const classes = useStyles();
	const [uploadLightLogo, { error: uploadLightLogoError }] =
		useUploadLightLogoMutation();
	const [uploadDarkLogo, { error: uploadDarkLogoError }] =
		useUploadDarkLogoMutation();

	const Header: FC = () => {
		return (
			<>
				<Typography align="center" variant={'h3'}>
					Upload logo
				</Typography>
				<Typography align="center" variant={'subtitle1'}>
					JPG, PNG, and GIF files are allowed
				</Typography>
			</>
		);
	};

	return (
		<Paper className={classes.paper}>
			<Header />
			<Container maxWidth={false} classes={{ root: classes.root }}>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<UploadLogoCard
							sponsor={sponsor}
							logoType={ELogoTypes.LIGHT}
							uploadMethod={uploadLightLogo}
							uploadError={uploadLightLogoError}
						/>
					</Grid>

					<Grid item xs={6}>
						<Paper className={classes.paper}>
							<UploadLogoCard
								sponsor={sponsor}
								logoType={ELogoTypes.DARK}
								uploadMethod={uploadDarkLogo}
								uploadError={uploadDarkLogoError}
							/>
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</Paper>
	);
};
