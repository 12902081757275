import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Chip, CircularProgress, Grid } from '@mui/material';
import { Button, TextField } from '@material-ui/core';
import { Check, Delete, Replay } from '@mui/icons-material';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { useStyles } from './styles';
import { ROLES } from 'common/helpers/permissions/Roles';
import { IActionsProps, IUserRowProps, IInviteButtonProps } from './types';
import { IconBtn } from 'common/components/IconBtn';
import { isEmail } from 'common/helpers/Validator';
import { ErrorMessages } from './ErrorMessages';
import { DropDown } from './Dropdown';
import {
	ICreateUserDto,
	useInviteUserMutation,
} from 'api/redux/services/userApi';
import { constants } from 'common/helpers/Constants';

export const UserRow: FC<IUserRowProps> = ({
	sponsorInvestors,
	sponsorId,
	user,
	onUpdate,
	onDelete,
}) => {
	const classes = useStyles();
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const [name, setName] = useState<string>(user?.name || '');
	const [email, setEmail] = useState<string>(user?.email || '');
	const [role, setRole] = useState<ROLES>(user?.role as ROLES);
	const [investorId, setInvestorId] = useState<number | undefined>(
		user?.investorId,
	);
	const [inviteUser, invite] = useInviteUserMutation();
	const [error, setError] = useState<string>('');
	const [nameError, setNameError] = useState<string>('');
	const [emailError, setEmailError] = useState<string>('');
	const [investorError, setInvestorError] = useState<string>('');
	const errored =
		invite.isError ||
		error !== '' ||
		nameError !== '' ||
		emailError !== '' ||
		investorError !== '';

	const handleInvite = async () => {
		setError('');
		const data: ICreateUserDto = {
			fullName: name,
			email: email,
			organizationId: sponsorId,
			role: role as ROLES,
			investorId: investorId,
		};

		inviteUser({ data })
			.unwrap()
			.then(() => {})
			.catch(() => {
				setError(
					`An error occurred. Please try again or contact support (${constants.SUPPORT_EMAIL})`,
				);
			});
	};

	const validateEmail = (email: string): void => {
		setEmailError('');
		if (email === '') {
			setEmailError('Please fill in the email');
			return;
		}

		if (!isEmail(email)) setEmailError('Invalid email format');
	};

	const updateUser = () => {
		onUpdate({
			id: user?.id,
			name,
			email,
			role,
			investorId,
		});
	};

	const handleEmailBlur = (e) => {
		validateEmail(e.target.value);
		updateUser();
	};

	const handleNameBlur = () => {
		if (name === '') {
			setNameError('Please fill in the name');
			return;
		}

		setNameError('');
		updateUser();
	};

	const handleInvestorBlur = () => {
		if (!investorId) {
			setInvestorError('Please select an investor form the list');
			return;
		}

		setInvestorError('');
		updateUser();
	};

	const InviteBtn: FC<IInviteButtonProps> = ({ disabled, loading }) => {
		return (
			<Box className={classes.inviteButtonBox}>
				<Button
					variant="outlined"
					className={classes.inviteButton}
					style={{ backgroundColor: `${sponsorColor}` }}
					onClick={handleInvite}
					disabled={loading || disabled}
				>
					{loading ? <CircularProgress color="inherit" size={15} /> : 'Invite'}
				</Button>
			</Box>
		);
	};

	const Actions: FC<IActionsProps> = ({
		invited,
		loading,
		errored,
		requestError,
	}) => {
		return (
			<>
				{!invited && <InviteBtn disabled={errored} loading={loading} />}
				{invited && (
					<Box className={classes.chipBox}>
						<Chip icon={<Check />} label="Invited" />
					</Box>
				)}
				{requestError && (
					<Box className={classes.chipBox}>
						<Chip
							icon={<Replay />}
							label="Retry invite"
							onClick={handleInvite}
						/>
					</Box>
				)}
			</>
		);
	};

	return (
		<Box className={classes.userRow}>
			<Grid container columns={24} spacing={1}>
				<Grid item xs={1}>
					<Box className={classes.deleteButtonBox}>
						<IconBtn
							onClick={() => onDelete(user.id)}
							customColor={true}
							tooltip={'Remove this user'}
						>
							<Delete />
						</IconBtn>
					</Box>
				</Grid>
				<Grid item xs={6}>
					<TextField
						fullWidth
						inputProps={{
							style: {
								paddingLeft: '5px',
								border: 'none',
								margin: '0px',
							},
						}}
						value={name}
						onChange={(e) => setName(e.target.value)}
						className={classes.nameOrEmailInput}
						onBlur={handleNameBlur}
					/>
				</Grid>
				<Grid item xs={7}>
					<TextField
						fullWidth
						inputProps={{
							style: {
								paddingLeft: '5px',
								border: 'none',
								margin: '0px',
							},
						}}
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						onBlur={handleEmailBlur}
						className={classes.nameOrEmailInput}
					/>
				</Grid>
				<Grid item xs={4}>
					<Box className={classes.dropdownBox}>
						<DropDown
							value={role}
							onChange={(e) => setRole(e.target.value as ROLES)}
							objects={Object.values(ROLES)
								.filter(
									(r) =>
										![
											ROLES.SUPER_ADMIN,
											ROLES.SPONSOR_ADMIN,
											ROLES.SPONSOR_USER,
										].includes(r),
								)
								.map((role) => {
									return { id: role, name: role };
								})}
							onBlur={updateUser}
						/>
					</Box>
				</Grid>
				<Grid item xs={4}>
					<Box className={classes.dropdownBox}>
						<DropDown
							value={investorId}
							onChange={(e) => {
								setInvestorError('');
								setInvestorId(e.target.value);
							}}
							objects={sponsorInvestors.map((investor) => {
								return { id: investor.id, name: investor.name };
							})}
							onBlur={handleInvestorBlur}
						/>
					</Box>
				</Grid>
				<Grid item xs={2}>
					<Actions
						invited={invite.isSuccess}
						loading={invite.isLoading}
						errored={errored}
						requestError={invite.isError}
					/>
				</Grid>
			</Grid>
			<ErrorMessages
				error={error}
				nameError={nameError}
				emailError={emailError}
				investorError={investorError}
			/>
		</Box>
	);
};
