import { Box, Grid } from '@mui/material';
import { ICASTableCellProps } from './types';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import {
	TableCell,
	cellFontWeight,
	topLineCell,
	underlineCell,
} from './styles';
import { cellValue, renderDollarSign } from './cellValue';

export const CASTableCell: FC<ICASTableCellProps> = ({
	row,
	column,
	CASData,
	fundData,
	templateType,
}) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);

	return (
		<TableCell
			style={{
				textAlign: 'right',
				fontWeight: cellFontWeight(row.label),
			}}
		>
			<Box
				sx={{
					py: 0.5,
					mx: 3,
					borderBottom: underlineCell(row, column, sponsorColor, templateType),
					borderTop: topLineCell(row, column, sponsorColor, templateType),
				}}
			>
				<Grid
					container
					direction="row"
					spacing={2}
					sx={{ justifyContent: 'space-between' }}
				>
					<Grid item>{renderDollarSign(row, column, templateType)}</Grid>
					<Grid
						item
						sx={{
							display: 'flex',
							alignItems: 'flex-end',
							justifyContent: 'flex-end',
						}}
					>
						{cellValue(row, column, CASData, fundData, templateType)}
					</Grid>
				</Grid>
			</Box>
		</TableCell>
	);
};
