import { FC, useState } from 'react';
import { IUploadLogoCardProps } from './types';
import Alert from '@mui/material/Alert';
import {
	Box,
	Typography,
	LinearProgress,
	IconButton,
	Grid,
} from '@material-ui/core';
import { Cancel, Edit } from '@material-ui/icons';
import { useStyles } from './styles';
import { LogoPreview } from './LogoPreview';
import { capitalize } from 'lodash';
import { DropArea } from './DropArea';

export const UploadLogoCard: FC<IUploadLogoCardProps> = ({
	sponsor,
	logoType,
	uploadMethod,
	uploadError,
}) => {
	const classes = useStyles();
	const [isUploading, setIsUploading] = useState<boolean>(false);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [uploadFinished, setUploadFinished] = useState<boolean>(false);

	const EditButton: FC = () => {
		if (isEditing) return <></>;

		return (
			<div style={{ paddingLeft: 20 }}>
				<IconButton size={'small'} onClick={() => setIsEditing(!isEditing)}>
					<Edit />
				</IconButton>
			</div>
		);
	};

	const CancelButton: FC = () => {
		if (!isEditing) return <></>;

		return (
			<div style={{ paddingLeft: 20 }}>
				<IconButton size={'small'} onClick={() => setIsEditing(!isEditing)}>
					<Cancel />
				</IconButton>
			</div>
		);
	};

	const Header: FC = () => {
		return (
			<Grid container spacing={2}>
				<Grid item xs={10}>
					<Typography variant={'h4'} style={{ marginTop: '5px' }}>
						{capitalize(logoType)} logo
					</Typography>
				</Grid>
				<Grid item xs={2}>
					<EditButton />
					<CancelButton />
				</Grid>
			</Grid>
		);
	};

	const ErrorMessage: FC = () => {
		if (!uploadError) return <></>;

		return (
			<Alert onClose={() => {}} variant="filled" severity="error">
				Upload failed - an error has occured
			</Alert>
		);
	};

	const SuccessMessage: FC = () => {
		if (!uploadFinished || uploadError) return <></>;

		return (
			<Alert
				onClose={() => setUploadFinished(false)}
				variant="filled"
				severity="success"
			>
				Successfully uploaded
			</Alert>
		);
	};

	const Alerts: FC = () => {
		return (
			<>
				<ErrorMessage />
				<SuccessMessage />
			</>
		);
	};

	return (
		<div className={classes.singleLogoContainer}>
			<Header />
			<LogoPreview
				sponsorId={sponsor.id}
				visible={!isEditing}
				logoType={logoType}
			/>
			<DropArea
				visible={isEditing}
				uploadMethod={uploadMethod}
				sponsor={sponsor}
				setIsUploading={setIsUploading}
				setUploadFinished={setUploadFinished}
				setIsEditing={setIsEditing}
			/>
			<Box className={classes.progress}>
				{isUploading && <LinearProgress color="secondary" />}
			</Box>
			<Alerts />
		</div>
	);
};
