import { useAuth0Grants } from 'common/helpers/permissions/useGetAuth0Grants';
import { Redirect, Route } from 'react-router-dom';
import { ROLES } from 'common/helpers/permissions/Roles';
import ProtectedRoute from './ProtectedRoute';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { useSelector } from 'react-redux';

const RedirectHome = (roles, rest) => {
	return (
		<Route
			{...rest}
			render={(props) => (
				<Redirect to={{ pathname: '/', state: { from: props.location } }} />
			)}
		/>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const GrantsProtectedRoute: any = ({ component, roles, ...rest }) => {
	const grants = useSelector(selectDropdown);
	const { currentSponsor } = grants.grants;
	const { allowedSponsor, currentRoles: userRoles } = useAuth0Grants();

	if (!currentSponsor.id) return;

	if (
		!userRoles.includes(ROLES.SUPER_ADMIN) &&
		!allowedSponsor(currentSponsor.id)
	) {
		return <RedirectHome roles={roles} {...rest} />;
	}

	return <ProtectedRoute component={component} roles={roles} {...rest} />;
};

export default GrantsProtectedRoute;
