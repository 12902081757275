import { Box, Modal } from '@material-ui/core';
import { FC, useState } from 'react';
import { ExportModes, IExportUsersCSVModalProps } from './types';
import { ExportMode } from './ExortMode';
import { useStyles } from './styles';
import { ExportByRelationship } from './ExportByRelationship';
import { ExportByUser } from './ExportByUser';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import CloseIcon from '@mui/icons-material/Close';

export const ExportUsersCSVModal: FC<IExportUsersCSVModalProps> = ({
	open,
	onClose,
}) => {
	const classes = useStyles();
	const [mode, setMode] = useState<ExportModes>(ExportModes.BY_RELATIONSHIP);
	const sponsorColor = useSelector(selectSponsorPrimaryColor);

	return (
		<Modal open={open} onClose={onClose}>
			<Box className={classes.boxContainer}>
				<Box display="flex" justifyContent="flex-end">
					<Button
						variant="text"
						onClick={onClose}
						sx={{
							color: `${sponsorColor}`,
						}}
					>
						<CloseIcon />
					</Button>
				</Box>
				<ExportMode
					value={mode}
					handleChange={(mode: ExportModes) => setMode(mode)}
				/>

				<ExportByRelationship visible={mode === ExportModes.BY_RELATIONSHIP} />
				<ExportByUser visible={mode === ExportModes.BY_USER} />
			</Box>
		</Modal>
	);
};
