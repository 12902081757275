import { FC, useEffect } from 'react';
import { useFilters, useGlobalFilter, useTable } from 'react-table';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Sort } from './SortToggle';
import { ESortSource, ICardsTableProps } from './types';
import { useStyles } from './styles';

const CardsTable: FC<ICardsTableProps> = ({
	columns,
	data,
	onRowClick = () => {},
	globalSearchTerm,
	filters,
	onSort,
	sortSource = ESortSource.DOCUMENTS,
}) => {
	const {
		getTableProps,
		headers,
		rows,
		prepareRow,
		setFilter,
		setGlobalFilter,
		setHiddenColumns,
	} = useTable(
		{
			columns,
			data,
		},
		useFilters,
		useGlobalFilter,
	);
	const classes = useStyles();

	useEffect(() => {
		const hiddenColumns = columns.filter((f) => f.isVisible === false);
		if (hiddenColumns.length > 0) {
			const columnNames = hiddenColumns.map((col) => col.accessor) as string[];
			if (columnNames) setHiddenColumns(columnNames);
		}
	}, [columns, setHiddenColumns]);

	useEffect(() => {
		if (filters) {
			Object.entries(filters).forEach(([filterName, values]) => {
				if (columns.some((f) => f.accessor === filterName)) {
					setFilter(filterName, values);
				}
			});
		}
	}, [columns, filters, setFilter]);

	useEffect(() => {
		if (globalSearchTerm) {
			setGlobalFilter(globalSearchTerm);
		}
	}, [globalSearchTerm, setGlobalFilter]);

	return (
		<Table {...getTableProps()} className={classes.tableContainer}>
			<TableHead className={classes.headers}>
				<TableRow>
					{headers.map((column, i) => {
						const hiddenColumns = columns.filter((f) => f.isVisible === false);

						if (hiddenColumns.map((c) => c.Header).includes(column.Header))
							return;

						return (
							<TableCell
								{...column.getHeaderProps()}
								width={column.width}
								style={{ minWidth: column.minWidth }}
								className={classes.headerCell}
								key={i}
							>
								{column.render('Header')}
								<Sort column={column} onSort={onSort} sortSource={sortSource} />
							</TableCell>
						);
					})}
				</TableRow>
			</TableHead>
			<TableBody>
				{rows.map((row, j) => {
					prepareRow(row);
					return (
						<TableRow
							{...row.getRowProps()}
							hover
							key={j}
							onClick={() => onRowClick(row)}
							className={classes.cardRow}
						>
							{row.cells.map((cell, k) => {
								return (
									<TableCell {...cell.getCellProps()} key={k}>
										{cell.render('Cell')}
									</TableCell>
								);
							})}
						</TableRow>
					);
				})}
			</TableBody>
		</Table>
	);
};

export default CardsTable;
