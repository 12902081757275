import { selectDropdown } from 'api/redux/DropdownReducer';
import { useLazyGetInvestorPermissionsQuery } from 'api/redux/services/investors.service';
import { FC, ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAuth0Grants } from './useGetAuth0Grants';

interface IUserInvestorPermissionsWrapperProps {
	children: ReactElement;
	ability: UserInvestorPermissionOptions;
}

export enum UserInvestorPermissionOptions {
	canPrint = 'canPrint',
	canDownload = 'canDownload',
}

export const UserInvestorPermissionsWrapper: FC<IUserInvestorPermissionsWrapperProps> =
	({ ability, children }) => {
		const grants = useSelector(selectDropdown);
		const { currentInvestor } = grants.grants;
		const { isAdmin } = useAuth0Grants();
		const [fetchInvestorPermissions, userInvestorPermissions] =
			useLazyGetInvestorPermissionsQuery();

		useEffect(() => {
			if (!isAdmin && currentInvestor.id !== 0)
				fetchInvestorPermissions({ investorId: currentInvestor.id });
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [currentInvestor.id, fetchInvestorPermissions]);

		const hasAbility = (ability: UserInvestorPermissionOptions): boolean => {
			return (
				isAdmin ||
				(userInvestorPermissions.data !== undefined &&
					userInvestorPermissions.data[ability])
			);
		};

		if (!hasAbility(ability)) return <></>;

		return <>{children}</>;
	};
