import { Typography, makeStyles, TextField, Button } from '@material-ui/core';
import { useCreateAnnouncementMutation } from 'api/redux/services/announcementsApi';
import { Paper } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles({
	wrapper: {
		width: '75%',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: 15,
		marginBottom: 15,
		padding: 15,
		justifyContent: 'center',
		textAlign: 'center',
	},
});

export const CreateAnnouncement: React.FC = () => {
	const { organizationId } = useParams<{ organizationId: string }>();
	const [createAnnouncement] = useCreateAnnouncementMutation();
	const [text, setText] = useState<string>('');

	const classes = useStyles();

	const handleSubmit = async () => {
		await createAnnouncement({ text, sponsorId: parseInt(organizationId) });
		setText('');
	};

	return (
		<Paper>
			<div className={classes.wrapper}>
				<Typography variant={'h3'}>Announcements</Typography>
				<TextField
					multiline
					fullWidth
					variant="outlined"
					value={text}
					onChange={(e) => setText(e.target.value)}
				/>
				<Button
					style={{ marginTop: 15 }}
					variant="contained"
					onClick={handleSubmit}
				>
					Post
				</Button>
			</div>
		</Paper>
	);
};

export default CreateAnnouncement;
