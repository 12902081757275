import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from '@mui/material';
import { Investor } from 'app/types/ui/investor.types';

interface IDeleteInvestorModalProps {
	open: boolean;
	investor: Investor;
	handleSubmit: () => void;
	handleClose: () => void;
}

//TODO take out React.FC, see DeleteStructureModal
export const DeleteInvestorModal: React.FC<IDeleteInvestorModalProps> = ({
	open,
	investor,
	handleSubmit,
	handleClose,
}) => {
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				<strong>{'Confirm Deletion'}</strong>
			</DialogTitle>
			<DialogContent sx={{ pt: 0, pb: 2 }}>
				<DialogContentText id="alert-dialog-description">
					Are you sure you want to permanently delete this{' '}
					<strong>{investor.name}</strong>?
					<br />
					<i>This action cannot be undone.</i>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button variant="contained" onClick={handleSubmit}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
};
