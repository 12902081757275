import React from 'react';
import { INonEditableUserRowProps } from '../types';
import { useStyles } from '../styles';
import { useSelector } from 'react-redux';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import {
	Box,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Paper,
	Tooltip,
} from '@mui/material';
import { Edit, Engineering, Mail, Person } from '@mui/icons-material';
import { IconBtn } from 'common/components/IconBtn';

export const NonEditableUserRow: React.FC<INonEditableUserRowProps> = ({
	user,
	onEditclick,
}) => {
	const classes = useStyles();
	const sponsorColor = useSelector(selectSponsorPrimaryColor);

	return (
		<Paper elevation={0} className={classes.inputWrapper}>
			<Grid container direction="row">
				<Grid item xs={11}>
					<List dense={true}>
						<ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
							<ListItemIcon>
								<Tooltip title={'User Name'}>
									<Person sx={{ color: sponsorColor }} />
								</Tooltip>
							</ListItemIcon>
							<ListItemText
								primary={user.name}
								sx={{ marginRight: '1rem', wordWrap: 'break-word' }}
							/>
						</ListItem>
						<ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
							<ListItemIcon>
								<Tooltip title={'User Email'}>
									<Mail sx={{ color: sponsorColor }} />
								</Tooltip>
							</ListItemIcon>
							<ListItemText
								primary={user.email}
								sx={{ marginRight: '1rem', wordWrap: 'break-word' }}
							/>
						</ListItem>
						<ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
							<ListItemIcon>
								<Tooltip title={'User Role'}>
									<Engineering sx={{ color: sponsorColor }} />
								</Tooltip>
							</ListItemIcon>
							<ListItemText
								primary={user.role}
								sx={{ marginRight: '1rem', wordWrap: 'break-word' }}
							/>
						</ListItem>
					</List>
				</Grid>
				<Grid item xs={1}>
					<Box display="flex" justifyContent="flex-end">
						<IconBtn onClick={onEditclick} tooltip={'Edit user information'}>
							<Edit sx={{ color: `${sponsorColor}` }} />
						</IconBtn>
					</Box>
				</Grid>
			</Grid>
		</Paper>
	);
};
