import { Typography, makeStyles, IconButton } from '@material-ui/core';
import { Paper } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FileCopy } from '@material-ui/icons';
import { ISponsor } from 'app/types/sponsor.types';

const useStyles = makeStyles({
	wrapper: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
	},
	container: {
		marginLeft: 'auto',
		marginRight: 'auto',

		marginTop: 15,
		marginBottom: 15,
		padding: 15,
	},
	buttonContainer: {
		marginTop: 15,
		marginRight: 55,
		display: 'flex',
		justifyContent: 'flex-end',
	},
	progress: {
		width: '80%',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	textWrapper: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
	},
	uneditable: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
	},
});

interface ISubdomainProps {
	sponsor: ISponsor;
}

export const Subdomain: FC<ISubdomainProps> = ({ sponsor }) => {
	const classes = useStyles();
	const [subdomain, setSubdomain] = useState('');
	const [loginUrl, setLoginUrl] = useState('');

	useEffect(() => {
		if (sponsor.subdomain) {
			setSubdomain(sponsor.subdomain);
			updateLoginUrl(sponsor.subdomain);
		}
	}, [sponsor]);

	const updateLoginUrl = (subdomain: string) => {
		const origin = new URL(window.location.href).origin;
		const loginUrl = new URL(origin);
		let host = loginUrl.host;
		if (host.split('.').length > 2) {
			host = host.split('.').slice(1).join('.');
		}
		loginUrl.host = subdomain + '.' + host;
		setLoginUrl(loginUrl.toString());
	};

	return (
		<Paper>
			<div className={classes.container}>
				<div className={classes.uneditable}>
					<div className={classes.textWrapper}>
						<Typography variant={'h4'} style={{ marginRight: '3em' }}>
							Subdomain
						</Typography>
						{subdomain}
					</div>
					<IconButton
						size={'small'}
						onClick={() => navigator.clipboard.writeText(loginUrl)}
					>
						<FileCopy />
					</IconButton>
				</div>
			</div>
		</Paper>
	);
};

export default Subdomain;
