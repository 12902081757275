import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
	wrapper: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
		gap: '1em',
	},
	container: {
		marginLeft: 'auto',
		marginRight: 'auto',

		marginTop: 15,
		marginBottom: 15,
		padding: 15,
	},
	uneditable: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
	},
	buttonContainer: {
		marginTop: 15,
		marginRight: 10,
		display: 'flex',
		justifyContent: 'flex-end',
	},
	inputWrapper: {
		flex: '1 0 auto',
		height: '20rem',
		overflow: 'auto',
		padding: 5,
		margin: 10,
		border: '1px solid #e3e3e3',
	},
	formWrapper: {
		height: '100%',
	},
});
