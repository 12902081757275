import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
	boxContainer: {
		position: 'absolute',
		display: 'flex',
		flexDirection: 'column',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		overflow: 'auto',
		width: '80vw',
		backgroundColor: '#ffffff',
		boxShadow: '24',
		padding: 10,
		borderRadius: 10,
	},
	inputRow: {
		display: 'flex',
		flexGrow: 0,
		flexShrink: 0,
		height: '40%',
		width: '100%',
		padding: 10,
	},
	inputWrapper: {
		flex: '1 0 auto',
		height: '20rem',
		overflow: 'auto',
		padding: 5,
		margin: 10,
		border: '1px solid #e3e3e3',
	},
	label: {
		fontSize: 14,
		color: 'black',
	},
	labelWrapper: {
		width: 250,
	},
	formWrapper: {
		height: '100%',
	},
	header: {
		display: 'flex',
		flexDirection: 'column',
	},
	headerTitle: {
		color: 'black',
		fontSize: '1.6rem',
		paddingLeft: '20px',
	},
	formTitle: {
		color: 'black',
		fontSize: 16,
	},
	buttons: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
	saveButton: {
		textTransform: 'inherit',
		width: 115,
		fontWeight: 'bold',
		fontSize: 14,
		margin: 2,
	},
	disclaimer: {
		display: 'flex',
		flexDirection: 'row',
		paddingLeft: 20,
	},
	subtitle: {
		color: 'black',
		width: '70%',
	},
	withShadow: {
		boxShadow: '-5px 15px 10px -15px',
	},
	selectFund: { width: 150 },
	select: {
		textAlign: 'left',
	},
	closeButton: {
		display: 'flex',
	},
});
