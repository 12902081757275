import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useStyles } from './styles';
import { IBulkUploadInvestorsProps, ICSVInvestorRow } from './types';
import { InvestorRow } from './InvestorRow';
import {
	useBulkCreateInvestorsMutation,
	useGetInvestorsBySponsorQuery,
	useProcessBulkCsvMutation,
} from 'api/redux/services/investors.service';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { useSelector } from 'react-redux';

export const BulkUploadInvestors: FC<IBulkUploadInvestorsProps> = ({
	open,
	handleClose,
}) => {
	const classes = useStyles();
	const grants = useSelector(selectDropdown);
	const { currentSponsor, currentFund } = grants.grants;
	const { data: existingInvestors } = useGetInvestorsBySponsorQuery(
		currentFund.id,
	);
	const [bulkCreateInvestors] = useBulkCreateInvestorsMutation();
	const [
		processBulkCSV,
		{
			data: processedInvestors,
			isLoading: isProcessingCSV,
			isError: IsCSVParseError,
		},
	] = useProcessBulkCsvMutation();
	const [errorMessage, setErrorMessage] = useState<string | undefined>(
		undefined,
	);
	const [investors, setInvestors] = useState<ICSVInvestorRow[]>([]);
	const [validData, setValidData] = useState(true);

	const submitDisabled: boolean =
		errorMessage !== undefined ||
		!validData ||
		investors.some((inv) => inv.hasError);

	const handleSubmit = async () => {
		const investorsPayload = investors.map((investor) => {
			return {
				fundId: currentFund.id,
				name: investor.name,
				phone: investor.phone || undefined,
				email: investor.email || undefined,
				addressLine1: investor.address1 || undefined,
				addressLine2: investor.address2 || undefined,
				city: investor.city || undefined,
				country: investor.country || undefined,
				state: investor.state || undefined,
				zipcode: investor.zip || undefined,
				investorId: investor.investorId,
				alreadyExists: investor.alreadyExists,
				overrideData: investor.overrideData,
				note: investor.note,
			};
		});

		await bulkCreateInvestors({
			fundId: currentFund.id,
			investors: investorsPayload,
		});

		handleClose();
	};

	const handleRejection = () => {
		setErrorMessage('Invalid file type. Please upload a CSV file.');
	};

	const validateCsv = (acceptedFiles) => {
		if (acceptedFiles.length === 0) return;

		const [file] = acceptedFiles;
		const formData = new FormData();
		formData.append('file', file);

		processBulkCSV({ formData, sponsorId: currentSponsor.id });

		acceptedFiles.length = 0;
		setErrorMessage(undefined);
	};

	const setPreview = () => {
		if (!processedInvestors) return;

		setInvestors(processedInvestors);
	};

	const updateInvestor = (index: number, investor: ICSVInvestorRow) => {
		const updatedInvestors = [...investors];
		updatedInvestors[index] = investor;
		setInvestors(updatedInvestors);
	};

	const processValidData = (): boolean => {
		const allValid = investors.every(
			(investor) => investor.name !== '' && investor.investorId !== '',
		);

		if (!allValid) return false;

		const investorIds = investors.map((investor) => investor.investorId);
		const uniqueInvestorIds = new Set(investorIds);

		if (investorIds.length !== uniqueInvestorIds.size) {
			setErrorMessage('There are 2 investors with the same investorId.');
		} else {
			setErrorMessage(undefined);
		}

		return investorIds.length === uniqueInvestorIds.size;
	};

	useEffect(() => {
		if (!processedInvestors) return;

		setPreview();
	}, [processedInvestors]);

	useEffect(() => {
		setValidData(processValidData);
	}, [investors]);

	useEffect(() => {
		if (IsCSVParseError) setErrorMessage('Error while parsing the CSV file');
	}, [IsCSVParseError]);

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
			<DialogTitle id="alert-dialog-title">
				<strong>{'Bulk Upload Investors'}</strong>
			</DialogTitle>
			<DialogContent>
				{errorMessage !== undefined && (
					<Alert severity="error" sx={{ marginBottom: 2 }}>
						{errorMessage}
					</Alert>
				)}
				{isProcessingCSV && (
					<Box>
						<LoadingSpinner />
					</Box>
				)}
				{investors.length == 0 && (
					<Box className={classes.dropZoneBox}>
						<Dropzone
							maxFiles={1}
							accept={{ 'text/csv': ['.csv'] }}
							onDropRejected={handleRejection}
							onDrop={validateCsv}
						>
							{({ getRootProps, getInputProps }) => (
								<div {...getRootProps()} className="dropzone">
									<input {...getInputProps()} />
									<Typography className={classes.fileText}>
										Click here to browse files or drag-and-drop the populated
										CSV template here.
									</Typography>
								</div>
							)}
						</Dropzone>
					</Box>
				)}
				{investors.length > 0 && (
					<Box>
						<Typography variant="h6">Investors Preview</Typography>
						<Box>
							<table>
								<thead>
									<tr>
										<th>Name</th>
										<th>InvestorId</th>
										<th>Phone</th>
										<th>Email</th>
										<th>Address 1</th>
										<th>Address 2</th>
										<th>City</th>
										<th>State</th>
										<th>Zip</th>
										<th>Country</th>
										<th>Note</th>
										<th>Replace contact info?</th>
									</tr>
								</thead>
								<tbody>
									{investors.map((investor, index) => (
										<InvestorRow
											key={index}
											investor={investor}
											index={index}
											updateInvestor={updateInvestor}
											existingInvestors={existingInvestors}
										/>
									))}
								</tbody>
							</table>
						</Box>
					</Box>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button
					variant="contained"
					onClick={handleSubmit}
					disabled={submitDisabled}
				>
					Add Investors
				</Button>
			</DialogActions>
		</Dialog>
	);
};
