import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			position: 'absolute',
			width: '100%',
			height: '100%',
			backgroundColor: '#FFFFFF',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			zIndex: 5,
		},
		spinner: {
			color: '#0092D8',
		},
	}),
);

export const LoadingSpinner: React.FC = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<CircularProgress className={classes.spinner} />
		</div>
	);
};
export default LoadingSpinner;
