import { FC, useEffect, useState } from 'react';
import { DocumentTableKeys, IButtonsRowProps } from './types';
import { useLazyDownloadDocumentQuery } from 'api/redux/services/documentApi';
import {
	downloadFile,
	fileName,
	specificDocumentUrl,
	urlForWaterMarkedDoc,
} from './utils';
import { Grid } from '@mui/material';
import { IconBtn } from 'common/components/IconBtn';
import { ContentCopy, GetApp, Print } from '@mui/icons-material';
import printJS from 'print-js-safari';
import { PopUpAlert } from '../../common/components/PopUpAlert';
import {
	UserInvestorPermissionOptions,
	UserInvestorPermissionsWrapper,
} from 'common/helpers/permissions/UserInvestorPermissionsWrapper';
import { useAuth0Grants } from 'common/helpers/permissions/useGetAuth0Grants';
import {
	useLazyGetOrganizationQuery,
	useLazyGetWatermarkDocTypesQuery,
} from 'api/redux/services/organizations.service';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { DocumentType } from 'app/types/documentTypes.types';

export const ButtonsRow: FC<IButtonsRowProps> = ({
	document,
	tableKey,
	signedUrl,
}) => {
	const { user } = useAuth0Grants();
	const grants = useSelector(selectDropdown);
	const { currentSponsor, currentInvestor } = grants.grants;
	const [fetchWatermarkDocTypes, watermarkDocTypes] =
		useLazyGetWatermarkDocTypesQuery();
	const [allowedWatermarkDocTypes, setAllowedWatermarkDocTypes] = useState<
		DocumentType[]
	>([]);
	const [fetchOrganization, sponsor] = useLazyGetOrganizationQuery();
	const [downloadDocument, file] = useLazyDownloadDocumentQuery();
	const [showDocUrlCopied, setShowDocUrlCopied] = useState<boolean>(false);
	const allowedDocTypeForWatermark = allowedWatermarkDocTypes
		.map((dt) => dt.id)
		.includes(document.documentTypeId);

	const handlePrint = async () => {
		if (!sponsor.data) return;

		if (!sponsor.data.watermarkPdf || !allowedDocTypeForWatermark) {
			printJS({ printable: signedUrl });
			return;
		}

		const fileUrl: string = await urlForWaterMarkedDoc(signedUrl, user);

		printJS({ printable: fileUrl });
	};

	const onCopyClick = () =>
		navigator.clipboard.writeText(
			specificDocumentUrl({
				document,
				withBase: true,
				tableKey: tableKey as DocumentTableKeys,
				investorId: currentInvestor.id,
			}),
		);

	useEffect(() => {
		fetchOrganization(currentSponsor.id);
		fetchWatermarkDocTypes(currentSponsor.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!watermarkDocTypes.data) return;

		setAllowedWatermarkDocTypes(watermarkDocTypes.data);
	}, [watermarkDocTypes.data]);

	/** Executed everytime a file is downloaded */
	useEffect(() => {
		if (!sponsor.data || !file.data || !file.data.signedUrl) return;

		if (!sponsor.data.watermarkPdf || !allowedDocTypeForWatermark) {
			downloadFile({
				fileUrl: file.data.signedUrl,
				fileName: fileName(document),
			});
			return;
		}

		const fetchDocument = async () => {
			if (file.data && file.data.signedUrl) {
				const fileUrl: string = await urlForWaterMarkedDoc(
					file.data.signedUrl,
					user,
				);

				downloadFile({ fileUrl, fileName: fileName(document) });
			}
		};
		fetchDocument();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [file.data, sponsor.data]);

	return (
		<>
			<PopUpAlert
				show={showDocUrlCopied}
				onClose={() => setShowDocUrlCopied(false)}
				severity="success"
				message="Document URL copied!"
			/>
			<Grid container padding={0} sx={{ alignItems: 'flex-end' }}>
				<Grid item xs={4}>
					<IconBtn
						onClick={() => {
							onCopyClick();
							setShowDocUrlCopied(true);
						}}
						customColor={true}
						tooltip="Copy document URL"
					>
						<ContentCopy />
					</IconBtn>
				</Grid>
				<Grid item xs={4} textAlign={'center'}>
					<UserInvestorPermissionsWrapper
						ability={UserInvestorPermissionOptions.canDownload}
					>
						<IconBtn
							onClick={() => downloadDocument(document.gcloudPath)}
							customColor={true}
							tooltip="Download document"
						>
							<GetApp />
						</IconBtn>
					</UserInvestorPermissionsWrapper>
				</Grid>{' '}
				<Grid item xs={4} textAlign={'end'}>
					<UserInvestorPermissionsWrapper
						ability={UserInvestorPermissionOptions.canPrint}
					>
						<IconBtn
							onClick={handlePrint}
							customColor={true}
							tooltip="Print document"
						>
							<Print />
						</IconBtn>
					</UserInvestorPermissionsWrapper>
				</Grid>
			</Grid>
		</>
	);
};
