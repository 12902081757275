import { useAuth0, User } from '@auth0/auth0-react';
import { ROLES } from 'common/helpers/permissions/Roles';
import { PERMISSIONS } from './Permissions';
import { SCOPES } from './Scopes';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { useState } from 'react';

type IReturnType = {
	user?: User;
	allowedSponsor: (sponsorId: number) => boolean;
	allowedFund: (fundId: number) => boolean;
	allowedInvestor: (investorId: number) => boolean;
	currentRoles: string[];
	allSponsorRoles: string[];
	isAdmin: boolean;
	sponsorId: number | undefined;
	hasPermission: (scopes: SCOPES[]) => boolean;
	hasRoles: (roles: ROLES[]) => boolean;
	appMetadata: Auth0AppMetadata;
};

enum Auth0UserAccessors {
	APP_METADATA = 'app_metadata',
	USER_METADATA = 'user_metadata',
	ROLES = 'roles',
}

interface Auth0Grant {
	sponsor: number;
	fund: number;
	investor: number;
}

interface Auth0GrantV2 {
	sponsor: number;
	fund: number;
	investor: number;
	isAdmin: boolean;
}

interface Auth0AppMetadata {
	grants: Auth0Grant[];
	grantsV2: Auth0GrantV2[];
}

interface Auth0UserMetadata {
	initialLogin: boolean;
}

export const useAuth0Grants = (): IReturnType => {
	const { user } = useAuth0();

	let sponsorId: number | undefined = undefined;
	//TODO: Figure out why this needs to be hard coded throughout the app. Fix it asap.
	const audience: string | undefined = 'https://api.stage.lpx.fund';
	if (!audience) throw new Error('Auth0 audience not configured');
	const appMetadata: Auth0AppMetadata =
		user?.[`${audience}/${Auth0UserAccessors.APP_METADATA}`];

	const firstSponsorId = () => {
		const sponsorIds: number[] = appMetadata?.grantsV2?.map(
			(grant) => grant.sponsor,
		);

		return sponsorIds ? sponsorIds[0] : undefined;
	};

	sponsorId = firstSponsorId();

	const grants = useSelector(selectDropdown);

	const getCurrentRoles = (): string[] => {
		const currentSponsor = grants.grants.currentSponsor;
		const currentFund = grants.grants.currentFund;
		const currentInvestor = grants.grants.currentInvestor;
		const auth0Grants = appMetadata.grantsV2;

		if (
			user &&
			user[`${audience}/${Auth0UserAccessors.ROLES}`].includes(
				ROLES.SUPER_ADMIN,
			)
		) {
			return [ROLES.SUPER_ADMIN];
		}

		const sponsorGrant = auth0Grants.find(
			(g) => g.sponsor === currentSponsor.id && !g.fund && !g.investor,
		);
		if (sponsorGrant && sponsorGrant.isAdmin) {
			return [ROLES.SPONSOR_ADMIN];
		}

		if (sponsorGrant) {
			return [ROLES.SPONSOR_USER];
		}

		const investorGrant = auth0Grants.find(
			(g) =>
				g.sponsor === currentSponsor.id &&
				g.fund === currentFund.id &&
				g.investor === currentInvestor.id,
		);

		if (investorGrant && investorGrant.isAdmin) {
			return [ROLES.INVESTOR_ADMIN];
		}

		return [ROLES.INVESTOR_USER];
	};

	const getAllSponsorRoles = (): string[] => {
		const currentSponsor = grants.grants.currentSponsor;
		const roles: string[] = [];
		const auth0Grants = appMetadata.grantsV2;

		if (
			user &&
			user[`${audience}/${Auth0UserAccessors.ROLES}`].includes(
				ROLES.SUPER_ADMIN,
			)
		) {
			roles.push(ROLES.SUPER_ADMIN);
		}

		if (auth0Grants) {
			for (const grant of auth0Grants) {
				if (grant.sponsor !== currentSponsor.id) continue;

				if (grant.sponsor && !grant.fund && !grant.investor && grant.isAdmin) {
					roles.push(ROLES.SPONSOR_ADMIN);
					continue;
				}

				if (grant.sponsor && !grant.fund && !grant.investor) {
					roles.push(ROLES.SPONSOR_USER);
					continue;
				}

				if (grant.sponsor && grant.fund && grant.investor && grant.isAdmin) {
					roles.push(ROLES.INVESTOR_ADMIN);
					continue;
				}

				roles.push(ROLES.INVESTOR_USER);
			}
		}

		return roles;
	};

	const [currentRoles, setCurrentRoles] = useState<string[]>(() => {
		return getCurrentRoles();
	});

	const [allSponsorRoles, setAllSponsorRoles] = useState<string[]>(() => {
		return getAllSponsorRoles();
	});

	const updateCurrentRoles = (newRoles: string[]) => {
		if (!newRoles || newRoles.length === 0) {
			setCurrentRoles([]);
			return;
		}

		if (
			currentRoles.length !== newRoles.length ||
			!newRoles.every((r) => currentRoles.includes(r))
		) {
			setCurrentRoles(newRoles);
		}
	};

	const updateAllSponsorRoles = (newRoles: string[]) => {
		if (
			allSponsorRoles &&
			allSponsorRoles.length > 0 &&
			(!newRoles || newRoles.length === 0)
		) {
			setAllSponsorRoles([]);
			return;
		}

		if (
			allSponsorRoles.length !== newRoles.length ||
			!newRoles.every((r) => allSponsorRoles.includes(r))
		) {
			setAllSponsorRoles(newRoles);
		}
	};

	updateCurrentRoles(getCurrentRoles());
	updateAllSponsorRoles(getAllSponsorRoles());

	const isAdmin: boolean = !user
		? true
		: [ROLES.SUPER_ADMIN, ROLES.SPONSOR_USER, ROLES.SPONSOR_ADMIN].some(
				(role) => currentRoles.includes(role),
		  );

	const hasPermission = (evalScopes: SCOPES[]): boolean => {
		return currentRoles.some((role) =>
			evalScopes.some((evalScope) => PERMISSIONS[role].includes(evalScope)),
		);
	};

	const hasRoles = (rolesToCheck: ROLES[]): boolean => {
		return currentRoles.some((r: string) =>
			rolesToCheck.some((role) => role === r),
		);
	};

	const allowedSponsor = (sponsorId: number): boolean => {
		if (!user) return false;

		return appMetadata.grantsV2.some(
			(grant: Auth0Grant) => grant.sponsor === sponsorId,
		);
	};

	const allowedFund = (fundId: number): boolean => {
		if (!user) return false;

		return appMetadata.grantsV2.some(
			(grant: Auth0Grant) => grant.fund === fundId,
		);
	};

	const allowedInvestor = (investorId: number): boolean => {
		if (!user) return false;

		return appMetadata.grantsV2.some(
			(grant: Auth0Grant) => grant.investor === investorId,
		);
	};

	const userMetadata: Auth0UserMetadata | undefined = user
		? user[`${audience}/${Auth0UserAccessors.USER_METADATA}`]
		: undefined;

	return {
		user,
		allowedSponsor,
		allowedFund,
		allowedInvestor,
		currentRoles,
		allSponsorRoles,
		isAdmin,
		sponsorId,
		hasPermission,
		hasRoles,
		appMetadata,
	};
};
