import { Typography } from '@material-ui/core';
import { Box, ToggleButton } from '@mui/material';
import PermissionsWrapper from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import CheckIcon from '@mui/icons-material/Check';
import { FC } from 'react';

interface INotifyUsersProps {
	checked: boolean;
	onClick: () => void;
}

export const NotifyCheckbox: FC<INotifyUsersProps> = ({ checked, onClick }) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				margin: 2,
			}}
		>
			<PermissionsWrapper
				scopes={[SCOPES.canNotifyInvestorUsersOnUploadedDocuments]}
			>
				<>
					<ToggleButton
						value="check"
						selected={checked}
						color={'primary'}
						onChange={onClick}
					>
						{checked ? (
							<CheckIcon sx={{ fontSize: 10 }} />
						) : (
							<Box style={{ height: 10, width: 10 }} />
						)}
					</ToggleButton>
					<Typography style={{ margin: 10 }}>
						Notify <i>investor level users</i> about this upload
					</Typography>
				</>
			</PermissionsWrapper>
		</Box>
	);
};
