import { FC } from 'react';
import { Box, InputLabel, MenuItem, Select } from '@mui/material';
import { INotificationDelay, notificationDelays } from './types';
import { useDispatch, useSelector } from 'react-redux';
import {
	docsUploadState,
	onSetNotificationDelay,
} from 'api/redux/DocsUploadReducer';
import { useStyles } from './styles';

interface INotificationDelaySelectorProps {
	show: boolean;
}

export const NotificationDelaySelector: FC<INotificationDelaySelectorProps> = ({
	show = true,
}) => {
	const classes = useStyles();
	const { notificationDelay } = useSelector(docsUploadState);
	const dispatch = useDispatch();

	if (!show) return <></>;

	const getTime = (delay: INotificationDelay) => {
		const time = new Date(new Date().getTime() + delay.minutes * 60000);
		const hours = time.getHours();
		const minutes = time.getMinutes().toString().padStart(2, '0');
		const ampm = hours >= 12 ? 'PM' : 'AM';
		let hours12 = hours > 12 ? hours - 12 : hours;
		if (hours12 === 0) hours12 = 12;
		return `${hours12.toString()}:${minutes} ${ampm}`;
	};

	return (
		<Box>
			<InputLabel
				id="notification-delay-select-label"
				htmlFor="select-outlined"
				className={classes.notificationText}
			>
				Send Notifications in:
			</InputLabel>
			<Select
				id="select-outlined"
				value={notificationDelay.id.toString()}
				onChange={(e) => {
					const delay = notificationDelays.find(
						(d) => d.id.toString() === e.target.value,
					);
					dispatch(onSetNotificationDelay(delay));
				}}
			>
				{notificationDelays.map((delay: INotificationDelay) => (
					<MenuItem key={delay.id} value={delay.id.toString()}>
						{delay.name} ({getTime(delay)})
					</MenuItem>
				))}
			</Select>
		</Box>
	);
};
