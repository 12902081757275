import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
	createStyles({
		tableContainer: {
			borderCollapse: 'separate',
			paddingRight: '5px',
		},
		cardRow: {
			backgroundColor: '#ffffff',
			border: '1px solid #D0D7DE',
			cursor: 'pointer',
		},
		headers: {
			backgroundColor: 'unset',
		},
		headerCell: {
			fontWeight: 'bold',
		},
	}),
);
