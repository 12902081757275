import { GroupAdd } from '@mui/icons-material';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { IconBtn } from 'common/components/IconBtn';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export const BulkUploadUsersButton: FC = () => {
	const { grants } = useSelector(selectDropdown);
	const { currentSponsor } = grants;
	const history = useHistory();

	const handleClick = () => {
		history.push(`/organizations/bulk-upload-users`);
	};

	return (
		<IconBtn
			onClick={handleClick}
			customColor={true}
			tooltip="Bulk Upload Users"
		>
			<GroupAdd />
		</IconBtn>
	);
};
