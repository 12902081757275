import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
	boxContainer: {
		position: 'absolute',
		display: 'flex',
		flexDirection: 'column',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		overflow: 'auto',
		width: '80vw',
		backgroundColor: '#ffffff',
		boxShadow: '24',
		borderRadius: 10,
	},
	optionsColumn: {
		border: 'solid 1px #D0D7DE',
		borderRadius: '10px',
		height: '300px',
		overflowY: 'auto',
		padding: '1rem',
		maxWidth: '80%',
	},
	exportButton: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: '2rem',
		gap: '5px',
	},
});
