import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		buttonBox: {
			backgroundColor: 'white',
			border: '#bfbfbf 1px solid',
			maxWidth: 'fit-content',
			margin: '10px',
			padding: '3px',
		},
		select: {
			width: '100%',
			minWidth: 200,
			padding: '10px',
		},
		selectLabel: {
			padding: '4px',
		},
		modalBox: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			width: 400,
			backgroundColor: 'white',
			textAlign: 'center',
			boxShadow: '24',
			p: 4,
		},
		modalText: {
			padding: '20px',
		},
		fileText: {
			marginTop: '15%',
		},
		dropZoneBox: {
			display: 'flex',
			justifyContent: 'center',
			margin: '20px',
			height: '200px',
			border: '2px dashed black',
		},
		actionsContainer: {
			marginBottom: theme.spacing(2),
		},
		nextButton: {
			backgroundColor: '#6559f4',
			color: '#FFFFFF',
			marginLeft: 8,
		},
		backButton: {
			backgroundColor: theme.palette.primary.light,
			color: 'black',
		},
		background: {
			width: '100%',
			height: '100%',
			padding: '3.5em',
		},
		step: {
			color: '#FFFFFF',
		},
		button: {
			backgroundColor: 'white !important',
			textTransform: 'inherit',
			height: '30px !important',
			marginTop: '15px !important',
			marginBottom: '15px !important',
			marginLeft: '10px !important',
			width: '150px !important',
			fontWeight: 'bold',
			padding: '2px !important',
			fontSize: 14,
			color: '#6558f5 !important',
			border: '1px solid #cfcdf9 !important',
		},
		buttonContainer: {
			padding: 2,
			width: '100%',
			display: 'flex',
			justifyContent: 'flex-end',
		},
		notificationDelaySelector: {
			fontSize: '14px !important',
		},
		textBox: {
			borderBottom: 'none',
			paddingLeft: 20,
			fontSize: 12,
		},
		errorTr: {
			borderBottom: 'none',
			marginBottom: 0,
		},
		errorTd: {
			color: 'red',
			colspan: 2,
			paddingTop: 0,
			paddingBottom: 0,
			marginTop: 0,
		},
		csvLink: {
			paddingTop: '5px',
			color: 'rgb(0, 0, 0, 0.54)',
		},
	}),
);
