import { FC } from 'react';
import { InputLabel } from '@mui/material';
import {
	docsUploadState,
	onSetDate,
	onSetDateConfirmed,
} from 'api/redux/DocsUploadReducer';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './styles';
import moment from 'moment';
import { formatDate } from 'modules/documents/utils';

export const DocumentDateSelector: FC = () => {
	const classes = useStyles();
	const { selectedDocDate } = useSelector(docsUploadState);
	const dispatch = useDispatch();

	return (
		<>
			<InputLabel>Select a Document Date</InputLabel>
			<ReactDatePicker
				wrapperClassName={classes.datePickerWrapper}
				className={classes.datePicker}
				selected={selectedDocDate ? new Date(selectedDocDate) : undefined}
				onChange={(date: Date) => {
					dispatch(onSetDate(formatDate(date.toString())));

					const today = new Date();

					if (
						!(
							date.getDate() == today.getDate() &&
							date.getMonth() == today.getMonth() &&
							date.getFullYear() == today.getFullYear()
						)
					) {
						dispatch(onSetDateConfirmed(true));
					}
				}}
			/>
		</>
	);
};
