import { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import {
	selectNavbarTablet,
	selectNavbarMobile,
} from 'api/redux/NavBarStateReducer';
import { NavDrawer } from './NavDrawer';

interface IMainSidebarContainerProps {}

export const MainSidebarContainer: FC<
	PropsWithChildren<IMainSidebarContainerProps>
> = ({ children }) => {
	const tablet = useSelector(selectNavbarTablet);
	const mobile = useSelector(selectNavbarMobile);
	return (
		<Box
			sx={{
				display: 'block',
				marginLeft: `${tablet ? '53px' : '0px'}`,
				marginRight: '5px',
				marginTop: `${mobile ? '75px' : '65px'}`,
			}}
		>
			<NavDrawer />
			<Box
				sx={{
					height: `${mobile ? 'calc(100vh - 85px)' : 'calc(100vh - 75px)'}`,
					width: 'min(100%, 1920px)',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flex: 1,
					marginLeft: 'auto',
					marginTop: '5px',
					marginRight: 'auto',
				}}
			>
				{children}
			</Box>
		</Box>
	);
};
