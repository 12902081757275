import { useState, useEffect, SetStateAction, FC } from 'react';
import { Box, Container, Grid, Typography, Card } from '@material-ui/core';
import { VisibilityOutlined } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import numberFormat from 'common/helpers/Math';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { useSelector } from 'react-redux';
import { IActivitySummaryProps } from './DashboardTypes';
import { useStyles } from './ActivitySummaryStyles';
import ICapitalActivity, {
	ICapitalActivityData,
} from 'modules/reports/CapitalAccountStatement/capital-activity.types';
import { CASValues, ICASSummaryProps } from './types';

export const ActivitySummary: FC<IActivitySummaryProps> = ({
	capitalActivityData,
}) => {
	const classes = useStyles();
	const grants = useSelector(selectDropdown);
	const { currentInvestor, currentFund, currentSponsor } = grants.grants;
	const [netUnrealized, setNetUnrealized] = useState<number>(0);
	const [operatingCosts, setOperatingCosts] = useState<number>(0);
	const [realizedCosts, setRealizedCosts] = useState<number>(0);
	const [capitalValue, setCapitalValue] = useState<number>(0);
	const [reportingPeriod, setReportingPeriod] = useState<string>('');

	const setCASValue = (
		casData: ICapitalActivity,
		casValue: CASValues,
		setData: (value: SetStateAction<number>) => void,
		checkYTD = true,
	) => {
		const attrData: ICapitalActivityData[] = casData.attributeData.filter(
			(attribute) =>
				attribute.name.includes(casValue) &&
				(!checkYTD || (checkYTD && attribute.name.includes('(YTD)'))) &&
				attribute.value !== '',
		);

		const value: number = attrData
			.map((entry) => parseInt(entry.value))
			.reduce((prev, next) => prev + next, 0);

		setData(value);
	};

	const printValue = (value: number): string => {
		return Math.abs(value) !== 0 ? numberFormat(value) : '-';
	};

	useEffect(() => {
		if (
			currentInvestor.id === 0 ||
			currentFund.id === 0 ||
			currentSponsor.id === 0 ||
			!capitalActivityData ||
			capitalActivityData.length === 0
		) {
			setNetUnrealized(0);
			setOperatingCosts(0);
			setRealizedCosts(0);
			setCapitalValue(0);
		}

		const [casData]: ICapitalActivity[] = capitalActivityData;
		if (!casData) return;

		setReportingPeriod(casData.reportingPeriod);
		setCASValue(casData, CASValues.NetOperating, setOperatingCosts);
		setCASValue(casData, CASValues.RealizedInvestmentGain, setRealizedCosts);
		setCASValue(casData, CASValues.UnrealizedInvestmentGain, setNetUnrealized);
		setCASValue(
			casData,
			CASValues.ClosingCapitalAccount,
			setCapitalValue,
			false,
		);
	}, [capitalActivityData, currentFund.id, currentInvestor.id, currentSponsor]);

	const ViewFullStatement: FC = () => {
		const classes = useStyles();
		const sponsorColor = useSelector(selectSponsorPrimaryColor);

		return (
			<Container maxWidth={false} className={classes.viewMoreDetailsContainer}>
				<Link to="/reports">
					<Container maxWidth={false} className={classes.viewMoreDetailsItems}>
						<VisibilityOutlined
							className={classes.viewMoreDetailsIcon}
							style={{ color: `${sponsorColor}`, padding: 1 }}
						/>
						<Typography
							className={classes.viewMoreDetailsText}
							align="center"
							variant="h6"
							style={{ color: `${sponsorColor}`, padding: 5 }}
						>
							View Capital Account Statements
						</Typography>
					</Container>
				</Link>
			</Container>
		);
	};

	const NetOperatingIncomeLabel: FC = () => {
		const classes = useStyles();

		return (
			<>
				<Typography className={classes.capitalActivityHeader} align="center">
					Net Operating
				</Typography>
				<Typography className={classes.capitalActivitySubHeader} align="center">
					Income
				</Typography>
			</>
		);
	};

	const NetUnrealizedGainLabel: FC = () => {
		const classes = useStyles();

		return (
			<>
				<Typography className={classes.capitalActivityHeader} align="center">
					Net Unrealized
				</Typography>
				<Typography className={classes.capitalActivitySubHeader} align="center">
					Gain/Loss
				</Typography>
			</>
		);
	};

	const NetRealizedInvestmentLabel: FC = () => {
		const classes = useStyles();

		return (
			<>
				<Typography className={classes.capitalActivityHeader} align="center">
					Net Realized
				</Typography>
				<Typography className={classes.capitalActivitySubHeader} align="center">
					Gain/Loss
				</Typography>
			</>
		);
	};

	const CapitalAccounValueLabel: FC = () => {
		const classes = useStyles();

		return (
			<>
				<Typography className={classes.capitalActivityHeader} align="center">
					Capital Account
				</Typography>
				<Typography className={classes.capitalActivitySubHeader} align="center">
					Value
				</Typography>
			</>
		);
	};

	const CASSummaryCard: FC<ICASSummaryProps> = ({ value, children }) => {
		const classes = useStyles();
		const sponsorColor = useSelector(selectSponsorPrimaryColor);

		return (
			<Card elevation={0} className={classes.summaryCard}>
				{children}
				<Typography
					className={classes.capitalActivityContent}
					style={{ color: `${sponsorColor}` }}
					align="center"
					variant="h6"
					component="h4"
				>
					{printValue(value)}
				</Typography>
			</Card>
		);
	};

	const Title: FC = () => {
		const classes = useStyles();

		return (
			<Container maxWidth={false} className={classes.headerContainer}>
				<Container maxWidth={false} className={classes.header}>
					<Container className={classes.capitalSummaryTitle}>
						<Typography variant="h4">Capital Account Summary</Typography>
					</Container>
					<ViewFullStatement />
				</Container>
				<Typography className={classes.periodEndingText}>
					{capitalActivityData &&
					capitalActivityData.length > 0 &&
					reportingPeriod !== ''
						? `For the ${reportingPeriod}`
						: '-'}
				</Typography>
			</Container>
		);
	};

	const SummaryCards: FC = () => {
		const classes = useStyles();

		return (
			<Grid container className={classes.summaryGrid}>
				<Grid item xs={3}>
					<CASSummaryCard value={operatingCosts}>
						<NetOperatingIncomeLabel />
					</CASSummaryCard>
				</Grid>
				<Grid item xs={3}>
					<CASSummaryCard value={netUnrealized}>
						<NetUnrealizedGainLabel />
					</CASSummaryCard>
				</Grid>
				<Grid item xs={3}>
					<CASSummaryCard value={realizedCosts}>
						<NetRealizedInvestmentLabel />
					</CASSummaryCard>
				</Grid>
				<Grid item xs={3}>
					<CASSummaryCard value={capitalValue}>
						<CapitalAccounValueLabel />
					</CASSummaryCard>
				</Grid>
			</Grid>
		);
	};

	return (
		<Box className={classes.activitySummaryBox}>
			<Title />
			<SummaryCards />
		</Box>
	);
};

export default ActivitySummary;
