import { useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	TextField,
	Box,
} from '@mui/material';
import { Fund } from 'app/types/ui/fund.types';
import handleEnterPressForDialogs from 'common/helperFunctions/ui/handleEnterPressForDialogs';

interface IDeleteFundModalProps {
	open: boolean;
	fund: Fund;
	handleSubmit: () => void;
	handleClose: () => void;
}

//using react.FC here for consistency
//I really don't think this is needed, may even cause problems
//see: https://github.com/facebook/create-react-app/pull/8177
//need to touch base with rest of team on this one
export const DeleteFundModal: React.FC<IDeleteFundModalProps> = ({
	open,
	fund,
	handleSubmit,
	handleClose,
}) => {
	const [name, setName] = useState<string>('');
	const [confirmed, setConfirmed] = useState<boolean>(false);
	const [incorrectEntry, setIncorrectEntry] = useState<boolean>(false);

	const handleConfirmed = () => {
		setConfirmed(true);
	};

	const checkNameAndHandleClose = async () => {
		if (name === fund.name) {
			await handleSubmit();
		} else {
			setIncorrectEntry(true);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				<strong>{'Confirm Deletion'}</strong>
			</DialogTitle>
			{fund && (
				<>
					<DialogContent sx={{ pt: 0, pb: 2 }}>
						<DialogContentText id="alert-dialog-description">
							Are you sure you want to permanently delete this fund{' '}
							<strong>{' ' + fund.name}</strong>?
							<br />
							<i>This action cannot be undone.</i>
						</DialogContentText>
					</DialogContent>
					{!confirmed && (
						<DialogActions>
							<Button onClick={handleClose}>Cancel</Button>
							<Button variant="contained" onClick={handleConfirmed}>
								Confirm
							</Button>
						</DialogActions>
					)}
					{confirmed && (
						<>
							<DialogContent sx={{ pt: 0, pb: 2 }}>
								<DialogContentText id="alert-dialog-description">
									Please type the name of this fund to confirm this deletion
								</DialogContentText>
							</DialogContent>
							{confirmed && incorrectEntry && (
								<DialogContent sx={{ pt: 0, pb: 2 }}>
									<DialogContentText id="alert-dialog-description">
										Sorry, that entry was incorrect! Please try again
									</DialogContentText>
								</DialogContent>
							)}
							<Box sx={{ pl: 3, pr: 3 }}>
								<TextField
									InputProps={{
										disableUnderline: true,
									}}
									autoFocus
									error={incorrectEntry}
									onKeyPress={(e) =>
										handleEnterPressForDialogs(e, checkNameAndHandleClose)
									}
									id="confirmed-secret-field"
									label={`Enter the name of your fund`}
									fullWidth
									variant="standard"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</Box>
							<DialogActions sx={{ pb: 2, pt: 2 }}>
								<Button onClick={handleClose}>Cancel</Button>
								<Button
									type="submit"
									variant="contained"
									onClick={checkNameAndHandleClose}
								>
									Delete
								</Button>
							</DialogActions>
						</>
					)}
				</>
			)}
			{!fund && (
				<>
					<DialogContent sx={{ pt: 0, pb: 2 }}>
						<DialogContentText id="alert-dialog-description">
							There was an error getting your fund and it can&apos;t be deleted
							at this time.
							<br />
							Please try again later
						</DialogContentText>
					</DialogContent>
				</>
			)}
		</Dialog>
	);
};
