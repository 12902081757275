import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography, Paper } from '@mui/material';
import {
	useDeleteSponsorMutation,
	useGetOrganizationQuery,
	useLazyGetOrganizationsQuery,
} from 'api/redux/services/organizations.service';
import MUIDataTable from 'mui-datatables';
import { useHistory } from 'react-router';
import { CreateOrganization } from './dialogs/CreateOrganization';
import AdminBreadcrumbs from 'common/components/AdminBreadCrumbs';
import { EditButton } from 'common/components/EditButton';
import { DeleteButton } from 'common/components/DeleteButton';
import { EditSponsor } from './dialogs/EditSponsor';
import { DeleteSponsorModal } from './dialogs/DeleteSponsorModal';
import { Sponsor } from 'app/types/ui/organization.types';
import PermissionsWrapper, {
	hasPermission,
} from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth0Grants } from 'common/helpers/permissions/useGetAuth0Grants';
import { selectNavbarTablet } from 'api/redux/NavBarStateReducer';
import { onChangeSponsor, selectDropdown } from 'api/redux/DropdownReducer';

interface IOrganizationsProps {}

export const Organizations: FC<IOrganizationsProps> = () => {
	const tablet = useSelector(selectNavbarTablet);
	const { user } = useAuth0();
	const { currentRoles } = useAuth0Grants();
	const [fetchOrganizations, organizations] = useLazyGetOrganizationsQuery();
	const [deleteSponsor, { data, isLoading }] = useDeleteSponsorMutation();
	const history = useHistory();
	const [createOpen, setCreateOpen] = useState<boolean>(false);
	const [editOpen, setEditOpen] = useState<boolean>(false);
	const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
	const grants = useSelector(selectDropdown);
	const { availableSponsors } = grants.grants;
	//is giving state a default value here a bad idea?
	//I can't find a reason for why it would be, but doesn't seem right
	//doing this so handleDeleteConfirm method
	//doesn't throw error before a sponsor is selected
	//due to possibly undefined value
	const [selectedSponsor, setSelectedSponsor] = useState<Sponsor>({
		id: 0,
		name: '',
	});
	const dispatch = useDispatch();

	const onRowClick = (rowData) => {
		history.push(`/superadmin/sponsors`);
		const sponsor = availableSponsors.find((f) => f.id === rowData[0]);
		dispatch(onChangeSponsor(sponsor));
	};

	useEffect(() => {
		if (user?.sub)
			fetchOrganizations({ auth0UserId: user?.sub, role: currentRoles[0] });
	}, [data, editOpen, fetchOrganizations, currentRoles, user?.sub]);

	const columns = [
		{
			name: 'id',
			options: {
				display: false,
				filter: false,
			},
		},
		{
			name: 'name',
			label: 'Sponsor Name',
			options: { setCellProps: () => ({ style: { minWidth: '80%' } }) },
		},
		{
			name: 'actions',
			options: {
				customBodyRender: (value, tableMeta) => (
					<div style={{ float: 'right' }}>
						<PermissionsWrapper scopes={[SCOPES.canEditSponsor]}>
							<EditButton onClick={(e) => onEdit(tableMeta, e)} />
						</PermissionsWrapper>

						<PermissionsWrapper scopes={[SCOPES.canDeleteSponsor]}>
							<DeleteButton onClick={(e) => onDelete(tableMeta, e)} />
						</PermissionsWrapper>
					</div>
				),
				customHeadRender: () => null,
			},
		},
	];

	const options = {
		onRowClick,
		elevation: 0,
		filter: false,
		pagination: false,
		print: false,
		selectableRows: 'single' as const,
		viewColumns: false,
		// selectableRowsOnClick: true,
		selectableRowsHideCheckboxes: true,
		search: hasPermission({ scopes: [SCOPES.canSearchSponsor] }),
		download: hasPermission({ scopes: [SCOPES.canDownloadSponsor] }),
	};

	const onEdit = (value, e) => {
		e.stopPropagation();
		setSelectedSponsor({
			id: value.rowData[0],
			name: value.rowData[1],
		});
		setEditOpen(true);
	};

	const onDelete = (value, e) => {
		e.stopPropagation();
		setSelectedSponsor({
			id: value.rowData[0],
			name: value.rowData[1],
		});
		setDeleteOpen(true);
	};

	const handleDeleteConfirm = async () => {
		await deleteSponsor(selectedSponsor.id);
		setDeleteOpen(false);
	};

	const handleDeleteClose = () => {
		setDeleteOpen(false);
	};

	return (
		<Box
			sx={{
				height: '100%',
				width: 'min(1200px, 100%)',
				margin: '15px',
				paddingLeft: `${tablet ? '0px' : '55px'}`,
			}}
		>
			<AdminBreadcrumbs list={[{ text: 'Admin', link: '/admin' }]} />
			<Paper
				sx={{
					height: 'max-content',
					width: '100%',
					display: 'inline-block',
					padding: 4,
					marginBottom: '10px',
					overflow: 'auto',
				}}
			>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Typography variant="h5">Sponsors</Typography>
					<PermissionsWrapper scopes={[SCOPES.canCreateSponsor]}>
						<Button
							variant="contained"
							color="primary"
							onClick={() => setCreateOpen(true)}
						>
							Create Sponsor
						</Button>
					</PermissionsWrapper>
				</Box>
				{organizations && (
					<MUIDataTable
						title=""
						columns={columns}
						data={organizations.data || []}
						options={options}
					/>
				)}
				{createOpen && (
					<CreateOrganization
						open={createOpen}
						onClose={() => setCreateOpen(false)}
					/>
				)}
				{editOpen && (
					<EditSponsor
						sponsor={selectedSponsor}
						open={editOpen}
						onClose={() => setEditOpen(false)}
					/>
				)}
				{deleteOpen && (
					<DeleteSponsorModal
						open={deleteOpen}
						sponsor={selectedSponsor}
						handleSubmit={handleDeleteConfirm}
						handleClose={handleDeleteClose}
						isLoading={isLoading}
					/>
				)}
			</Paper>
		</Box>
	);
};
