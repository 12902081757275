import { FC } from 'react';
import { ILabelBoxProps } from './types';
import { Typography } from '@material-ui/core';
import { Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { Edit } from '@material-ui/icons';
import { useStyles } from './styles';

export const LabelBox: FC<ILabelBoxProps> = ({
	show,
	sponsor,
	onEditClick,
}) => {
	const classes = useStyles();

	if (!show) return <></>;

	return (
		<div className={classes.container}>
			<div className={classes.uneditable}>
				<div className={classes.wrapper}>
					<Typography variant={'h4'}>Watermark PDF&apos;s</Typography>
				</div>
				<div className={classes.wrapper}>
					<FormControlLabel
						disabled
						label={'Investor and Fund Documents'}
						id="investor-n-fund-docs-label"
						style={{
							padding: '10px 0px 0px 5px',
						}}
						control={
							<Checkbox
								checked={sponsor.watermarkPdf || false}
								aria-labelledby="investor-n-fund-docs-label"
							/>
						}
					/>
				</div>
				<div className={classes.wrapper}>
					<FormControlLabel
						disabled
						label={'Capital Account Statements'}
						id="cas-label"
						style={{
							padding: '10px 0px 0px 5px',
						}}
						control={
							<Checkbox
								checked={sponsor.watermarkCasPdf || false}
								aria-labelledby="cas-label"
							/>
						}
					/>
				</div>
				<IconButton size={'small'} onClick={onEditClick}>
					<Edit />
				</IconButton>
			</div>
		</div>
	);
};
