import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	grid: {
		margin: '0px',
	},
	paper: {
		padding: theme.spacing(5),
		border: '1px solid #D0D7DE',
		borderRadius: 5,
		backgroundColor: '#FFFFFF',
		overflow: 'hidden',
	},
	root: {
		display: 'flex',
		paddingRight: 0,
		paddingLeft: 0,
		marginRight: 0,
		marginTop: 0,
		overflow: 'hidden',
	},
	investorTitle: {
		display: 'flex',
		flexDirection: 'row',
		fontWeight: 'bold',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '1.5rem',
		marginTop: '-.5rem',
	},
	dataTitles: {
		fontWeight: 'bold',
		marginTop: '1rem',
	},
	typographyData: {
		marginTop: '1rem',
	},
	investorTypographyData: {
		marginTop: '1rem',
		marginLeft: '-5rem',
		wordBreak: 'break-all',
		whiteSpace: 'normal',
	},
	commitmentEntryGrid: {
		width: '50%',
	},
	editIcon: {
		float: 'right',
	},
	sectionTitle: {
		fontWeight: 'bold',
		marginTop: '1rem',
	},
	controlsBox: {
		marginTop: '2rem',
	},
	modalBoxContainer: {
		position: 'absolute',
		display: 'flex',
		flexDirection: 'column',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '45rem',
		boxShadow: '24',
	},
	saveButton: {
		color: 'white',
		textTransform: 'inherit',
		height: 30,
		width: 115,
		fontWeight: 'bold',
		padding: 2,
		fontSize: 14,
		margin: '15px 5px 0px 10px',
		float: 'right',
	},
	cancelButton: {
		textTransform: 'inherit',
		height: 30,
		width: 115,
		fontWeight: 'bold',
		padding: 2,
		fontSize: 14,
		margin: '15px 15px 0px 5px',
		float: 'right',
	},
}));
