import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
	bulkUploadContainer: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: 'min(100%, 1350px) !important',
		margin: '7px 2px 5px 2px',
	},
	uploadCard: {
		padding: '5px',
		borderRadius: 5,
		overflow: 'auto',
		border: '1px solid #D0D7DE',
		width: '100%',
	},
	inviteCard: {
		marginTop: '5px',
	},
	headerBox: {
		height: '10%',
		display: 'flex',
		justifyContent: 'space-between',
	},
	headerTypography: {
		paddingLeft: '5px',
		color: 'black',
	},
	dropZoneContainer: {
		display: 'flex',
		justifyContent: 'center',
		margin: '20px',
		height: '80%',
		border: '2px dashed black',
	},
	dropZoneTypography: {
		marginTop: '25%',
	},
	userRow: {
		cursor: 'pointer',
	},
	dropdownInput: {
		border: 'none',
		width: '100%',
	},
	dropdownBox: {
		height: '100%',
		width: '100%',
		display: 'flex',
		alignItems: 'bottom',
		paddingBottom: '4px',
	},
	deleteButtonBox: {
		height: '100%',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	inviteButton: {
		width: 'max-content',
		textTransform: 'none',
		color: 'white !important',
		fontSize: '1rem',
		'&.Mui-disabled': { backgroundColor: 'grey !important' },
		'&:hover, &.Mui-focusVisible': {
			color: '#0092D8 !important',
		},
	},
	nameOrEmailInput: {
		border: '0px 0px 1px 0px solid #D0D7DE',
		'& .MuiOutlinedInput': {
			margin: '0px 0px 0px 0px !important',
		},
	},
	inviteButtonBox: {
		height: '100%',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	button: {
		color: 'white',
		height: 30,
		width: 115,
		fontWeight: 'bold',
		padding: 2,
		fontSize: 14,
		marginTop: 'auto',
		marginBottom: 'auto',
	},
	chipBox: {
		padding: '5px',
		textAlign: 'end',
	},
	csvLink: {
		color: '#FFFFFF',
	},
	templateButton: {
		fontSize: '1rem',
		textTransform: 'none',
		color: 'white !important',
		margin: '5px 5px 0px 0px',
		'&:hover, &.Mui-focusVisible': {
			color: '#0092D8 !important',
		},
	},
});
