import { FC, useState } from 'react';
import {
	Box,
	FormControl,
	Select,
	MenuItem,
	IconButton,
} from '@material-ui/core';
import { Print } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { useConfig } from 'api/redux/services/config';
import * as PdfService from './pdf.service';
import ICapitalActivity from './capital-activity.types';
import {
	IHeaderOptionsProps,
	IPeriodSelector,
	IPrintCasButtonProps,
} from './types';
import { useStyles } from './styles';
import { makeStyles } from '@material-ui/styles';
import { ConfirmationModal } from 'common/components/UsersTable/user-permissions/ConfirmationModal';
import PermissionsWrapper from 'common/helpers/permissions/PermissionsWrapper';
import { Delete } from '@mui/icons-material';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { useDeleteCasByPeriodMutation } from 'api/redux/services/capitalActivityApi';

const PrintCasButton: FC<IPrintCasButtonProps> = ({ onClick, visible }) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);

	if (!visible) return <></>;

	return (
		<IconButton disableRipple={true} size="medium" onClick={onClick}>
			<Print
				style={{
					height: '2rem',
					width: '2rem',
					color: `${sponsorColor}`,
				}}
			/>
		</IconButton>
	);
};

const PeriodSelector: FC<IPeriodSelector> = ({
	currentReportingPeriod,
	capitalActivityData,
	loadCapitalActivity,
	visible,
}) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const classes = useStyles();
	const useIconStyles = makeStyles({
		periodSelectorIcon: {
			fill: `${sponsorColor}`,
			paddingBottom: 5,
		},
	});
	const iconClass = useIconStyles();

	if (!visible) return <></>;

	return (
		<FormControl size="small" className={classes.periodDropdown}>
			<Select
				inputProps={{
					classes: {
						icon: iconClass.periodSelectorIcon,
					},
				}}
				className={classes.periodSelector}
				labelId="cap-account-date-filter-label"
				id="cap-account-date-filter"
				labelWidth={0}
				value={currentReportingPeriod}
				variant="outlined"
				onChange={(
					e: React.ChangeEvent<{
						name?: string | undefined;
						value: unknown;
					}>,
				) => loadCapitalActivity(e)}
			>
				{capitalActivityData.map((option, i) => (
					<MenuItem value={option.reportingPeriod} key={i}>
						{option.reportingPeriod}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export const HeaderOptions: FC<IHeaderOptionsProps> = ({
	loadCapitalActivity,
	capitalActivityData,
	currentReportingPeriod,
	setError,
	afterDelete,
	hasPermission,
}) => {
	const classes = useStyles();
	const grants = useSelector(selectDropdown);
	const { currentInvestor } = grants.grants;
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const { data: config } = useConfig();
	const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
	const [deleteCas] = useDeleteCasByPeriodMutation();

	const handleDelete = async () => {
		const foundCas: ICapitalActivity | undefined = capitalActivityData.find(
			(cas) => cas.reportingPeriod === currentReportingPeriod,
		);

		if (!foundCas) {
			setError("Couldn't find a report for the corresponsing reporting period");
			return;
		}

		await deleteCas({
			fundId: foundCas.fundId,
			investorId: foundCas.investorId,
			period: foundCas.reportingPeriod,
		});
		setShowConfirmDelete(false);
		afterDelete();
	};

	const createPDF = () => {
		if (capitalActivityData?.length > 0) {
			const foundCas: ICapitalActivity | undefined = capitalActivityData.find(
				(cas) => cas.reportingPeriod === currentReportingPeriod,
			);
			if (!foundCas) {
				setError(
					"Couldn't find a report for the corresponsing reporting period",
				);
				return;
			}

			PdfService.generatePDF(foundCas.id, config?.apiBaseUrl)
				.then((response) => {
					const file = new Blob([response.data], { type: 'application/pdf' });
					const fileURL = URL.createObjectURL(file);
					window.open(fileURL);
				})
				.catch((error) => {
					setError(error);
				});
		}
	};

	if (capitalActivityData.length === 0 || currentInvestor.id === 0)
		return <></>;

	return (
		<Box className={classes.headerOptions}>
			<PeriodSelector
				currentReportingPeriod={currentReportingPeriod}
				capitalActivityData={capitalActivityData}
				loadCapitalActivity={loadCapitalActivity}
				visible={hasPermission}
			/>
			<Box className={classes.buttons}>
				<PrintCasButton onClick={() => createPDF()} visible={hasPermission} />
				<PermissionsWrapper scopes={[SCOPES.canDeleteCas]}>
					<IconButton
						disableRipple={true}
						size="medium"
						onClick={() => setShowConfirmDelete(true)}
					>
						<Delete
							style={{
								height: '2rem',
								width: '2rem',
								color: `${sponsorColor}`,
							}}
						/>
					</IconButton>
				</PermissionsWrapper>
			</Box>
			<ConfirmationModal
				isOpen={showConfirmDelete}
				onAccept={handleDelete}
				onCancel={() => setShowConfirmDelete(false)}
				title="Confirm to delete this Capital Account Statement"
				message="Are you sure to delete this Capital Account Statement?"
				saveText="Confirm"
			/>
		</Box>
	);
};
