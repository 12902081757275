import React, { FC, useState } from 'react';
import { Dialog, Paper, Typography } from '@material-ui/core';
import { Box, Button, TextField } from '@mui/material';
import { Announcement } from 'app/types/Announcements.types';
import { useStyles } from './AnnouncmentsStyles';

export interface IEditAnnouncementModal {
	open: boolean;
	announcement: Announcement;
	handleEditModalSave: (announcement: string) => void;
	handleEditModalClose: () => void;
}
export const EditAnnouncementModal: FC<IEditAnnouncementModal> = ({
	open,
	announcement,
	handleEditModalSave,
	handleEditModalClose,
}) => {
	const classes = useStyles();
	const [announcementText, setAnnouncementText] = useState<string>(
		announcement.text,
	);

	return (
		<Dialog open={open} onClose={handleEditModalClose}>
			<Box className={classes.dialogContainer}>
				<Typography className={classes.textContainer} variant="h5">
					Edit Announcement
				</Typography>
				<Paper>
					<TextField
						multiline
						fullWidth
						variant="outlined"
						value={announcementText}
						onChange={(e) => setAnnouncementText(e.target.value)}
					/>
				</Paper>
				<Box className={classes.buttonContainer}>
					<Button
						variant="contained"
						color="primary"
						onClick={() => handleEditModalSave(announcementText)}
					>
						<Typography>Save</Typography>
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={handleEditModalClose}
					>
						<Typography>Cancel</Typography>
					</Button>
				</Box>
			</Box>
		</Dialog>
	);
};

export default EditAnnouncementModal;
