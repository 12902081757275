import { FC, ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { styled, Theme, CSSObject, List, ListItem } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { makeStyles } from '@material-ui/core';
import HomeIcon from '@mui/icons-material/Home';
import FolderIcon from '@mui/icons-material/Folder';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import PermissionsWrapper from 'common/helpers/permissions/PermissionsWrapper';
import { ERoutes } from 'App';
import { Group, Person } from '@mui/icons-material';
import { selectScreenWidth } from 'api/redux/NavBarStateReducer';
import { DROPDOWNS_TOTAL_LENGTH } from '../navbar/types';
import { useLocalUser } from 'common/helpers/permissions/useLocalUser';

const drawerWidth = 165;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(6)} + 4px)`,
});

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

const useStyles = makeStyles(() => ({
	item: {
		marginRight: 15,
	},
}));

interface INavItem {
	text: string;
	icon: ReactElement;
	to: string;
	isHiddenOnMobile: boolean;
}

export const NavDrawer: FC = () => {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const history = useHistory();
	const classes = useStyles();
	const { currentUser } = useLocalUser();
	const screenWidth = useSelector(selectScreenWidth);
	const isMobile = screenWidth < DROPDOWNS_TOTAL_LENGTH;

	const navItems: INavItem[] = [
		{
			text: 'Home',
			icon: <HomeIcon sx={{ color: '#353535' }} />,
			to: ERoutes.Dashboard,
			isHiddenOnMobile: true,
		},
		{
			text: 'Documents',
			icon: <FolderIcon sx={{ color: '#353535' }} />,
			to: ERoutes.Documents,
			isHiddenOnMobile: false,
		},
		{
			text: 'Reports',
			icon: <AnalyticsIcon sx={{ color: '#353535' }} />,
			to: ERoutes.Reports,
			isHiddenOnMobile: false,
		},
	];

	const blurStyle = !currentUser?.initialLogin ? { filter: 'blur(5px)' } : {};

	return (
		<Drawer
			onMouseEnter={() => setDrawerOpen(true)}
			onMouseLeave={() => setDrawerOpen(false)}
			variant="permanent"
			open={drawerOpen}
			style={{ ...blurStyle }}
		>
			<List style={{ marginTop: '70px' }}>
				{navItems.map((link) => (
					<ListItem
						button
						key={link.text}
						sx={{ paddingY: '15px' }}
						onClick={() => history.replace(link.to)}
						{...(link.isHiddenOnMobile &&
							isMobile && { style: { display: 'none' } })}
					>
						<div className={classes.item}>{link.icon}</div>

						<NavLink
							style={{ fontSize: '16px', color: '#353535' }}
							to={link.to}
							activeStyle={{
								fontWeight: 'bold',
							}}
						>
							{link.text}
						</NavLink>
					</ListItem>
				))}

				<PermissionsWrapper
					scopes={[SCOPES.canSeeInvestorUsersOption]}
					checkAllSponsorRoles={true}
				>
					<ListItem
						sx={{ paddingY: '15px' }}
						button
						onClick={() => history.replace(ERoutes.InvestorsView)}
						{...(isMobile && { style: { display: 'none' } })}
					>
						<div className={classes.item}>
							<Person sx={{ color: '#353535' }} />
						</div>

						<NavLink
							style={{ fontSize: '16px', color: '#353535' }}
							to={ERoutes.InvestorsView}
							activeStyle={{
								fontWeight: 'bold',
							}}
						>
							Investor
						</NavLink>
					</ListItem>
				</PermissionsWrapper>

				<PermissionsWrapper
					scopes={[SCOPES.canSeeOrganizationUsersOption]}
					checkAllSponsorRoles={true}
				>
					<ListItem
						sx={{ paddingY: '15px' }}
						button
						onClick={() => history.replace(ERoutes.OrganizationView)}
					>
						<div className={classes.item}>
							<Group sx={{ color: '#353535' }} />
						</div>

						<NavLink
							style={{ fontSize: '16px', color: '#353535' }}
							to={ERoutes.OrganizationView}
							activeStyle={{
								fontWeight: 'bold',
							}}
						>
							Sponsor
						</NavLink>
					</ListItem>
				</PermissionsWrapper>

				<PermissionsWrapper
					scopes={[SCOPES.canSeeAdminMenuOption]}
					checkAllSponsorRoles={true}
				>
					<ListItem
						sx={{ paddingY: '15px' }}
						button
						onClick={() => history.replace(ERoutes.Admin)}
					>
						<div className={classes.item}>
							<ManageAccountsIcon sx={{ color: '#353535' }} />
						</div>
						<NavLink
							style={{ fontSize: '16px', color: '#353535' }}
							to={ERoutes.Admin}
							activeStyle={{
								fontWeight: 'bold',
							}}
						>
							Admin
						</NavLink>
					</ListItem>
				</PermissionsWrapper>
			</List>
		</Drawer>
	);
};
