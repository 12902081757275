import React, { useState } from 'react';
import {
	makeStyles,
	createStyles,
	Box,
	Typography,
	Button,
	Snackbar,
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useAuth0 } from '@auth0/auth0-react';
import { Alert, FormControl, OutlinedInput } from '@mui/material';
import { defaultColor } from 'api/redux/siteColorReducer';
import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';
import { usePostQuestionMutation } from 'api/redux/services/helpApi';
import LoadingSpinner from 'common/components/LoadingSpinner';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			padding: '25px',
			marginLeft: '55px',
		},
		header: {
			width: '100%',
		},
		text: {
			margin: '25px 0 10px  0 ',
		},
		container: {
			display: 'flex',
			flexDirection: 'column',
			width: '60%',
		},
		inputStyle: {
			borderBottom: 'none !important',
			boxShadow: 'none !important',
			margin: '0 0 0 10px !important',
		},
		select: {
			width: '40%',
		},
		button: {
			marginTop: '25px',
			fontSize: '12px',
			backgroundColor: `${defaultColor}`,
			boxShadow: 'none',
			textTransform: 'none',
		},
		buttonContainer: {
			display: 'flex',
			width: '100%',
			justifyContent: 'flex-end',
			alignItems: 'flex-end',
			alignContents: 'flex-end',
		},
	}),
);

export const HelpPage: React.FC = () => {
	const classes = useStyles();
	const { user } = useAuth0();

	const [postQuestion] = usePostQuestionMutation();

	const [subject, setSubject] = useState('');
	const [topic, setTopic] = useState('');
	const [content, setContent] = useState('');

	const [loading, setLoading] = useState(false);
	const [successMessage, setSuccessMessage] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);

	const submit = async () => {
		if (!user || !user.email || !user.sub) {
			setErrorMessage(true);
			return;
		}

		setLoading(true);
		await postQuestion({
			subject: subject,
			topic: topic,
			userId: user.sub,
			content: content,
		});
		setSubject('');
		setTopic('');
		setContent('');
		setSuccessMessage(true);
		setLoading(false);
	};

	return (
		<MainSidebarContainer>
			{loading && <LoadingSpinner />}
			<Snackbar
				open={successMessage}
				autoHideDuration={6000}
				onClose={() => {
					setSuccessMessage(false);
				}}
			>
				<Alert
					action={
						<IconButton
							aria-label="close"
							color="inherit"
							size="small"
							onClick={() => {
								setSuccessMessage(false);
							}}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
					sx={{ mb: 2 }}
					severity="success"
				>
					Your question was successfully submitted
				</Alert>
			</Snackbar>
			<Snackbar
				open={errorMessage}
				autoHideDuration={6000}
				onClose={() => {
					setErrorMessage(false);
				}}
			>
				<Alert
					action={
						<IconButton
							aria-label="close"
							color="inherit"
							size="small"
							onClick={() => {
								setErrorMessage(false);
							}}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
					sx={{ mb: 2 }}
					severity="error"
				>
					{
						'There was a problem submitting your question. Please try again later'
					}
				</Alert>
			</Snackbar>
			<Box className={classes.root}>
				<Box className={classes.header}>
					<Typography variant="h3">Contact Us</Typography>
				</Box>
				<Box className={classes.container}>
					<FormControl variant="outlined">
						<Typography variant="h4" className={classes.text}>
							Subject
						</Typography>
						<OutlinedInput
							value={subject}
							sx={{ width: '40%' }}
							{...{
								inputProps: {
									className: classes.inputStyle,
									autoComplete: 'off',
								},
							}}
							onChange={(e) => {
								setSubject(e.target.value);
							}}
						/>
					</FormControl>
					<FormControl variant="outlined">
						<Typography variant="h4" className={classes.text}>
							Question or Comment
						</Typography>
						<OutlinedInput
							value={content}
							minRows={3}
							maxRows={8}
							multiline
							{...{
								inputProps: {
									className: classes.inputStyle,
									autoComplete: 'off',
								},
							}}
							onChange={(e) => {
								setContent(e.target.value);
							}}
						/>
					</FormControl>
					<Box className={classes.buttonContainer}>
						<Button
							className={classes.button}
							onClick={submit}
							size="large"
							variant="contained"
							color="secondary"
						>
							Submit
						</Button>
					</Box>
				</Box>
			</Box>
		</MainSidebarContainer>
	);
};
export default HelpPage;
