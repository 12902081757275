import { Typography } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { IExportByUserProps } from './types';
import { Box, Grid } from '@mui/material';
import { ByUserInfo } from './ByUserInfo';
import { RolesList } from './RolesList';
import { ROLES } from 'common/helpers/permissions/Roles';
import { ColumnsList } from './ColumnsList';
import {
	IExportUsersByUserColumns,
	useLazyGetOrganizationUsersByUserCsvQuery,
} from 'api/redux/services/organizations.service';
import { ExportButton } from './ExportButton';
import fileDownload from 'js-file-download';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';

export const ExportByUser: FC<IExportByUserProps> = ({ visible }) => {
	const { grants } = useSelector(selectDropdown);
	const { currentSponsor } = grants;
	const [downloadUsersByUserCSV, file] =
		useLazyGetOrganizationUsersByUserCsvQuery();
	const [selectedRoles, setSelectedRoles] = useState<ROLES[]>([]);
	const [selectedColumns, setSelectedColumns] = useState<
		IExportUsersByUserColumns[]
	>([]);
	const exportDisabled = !selectedColumns.length || !selectedRoles.length;

	const handleExport = () => {
		downloadUsersByUserCSV({
			sponsorId: currentSponsor.id,
			roles: selectedRoles,
			columns: selectedColumns,
		});
	};

	useEffect(() => {
		if (!file.data || file.isFetching) return;

		fileDownload(
			file.data,
			`${currentSponsor.name || currentSponsor.id}.users.export.csv`,
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [file]);

	if (!visible) return <></>;

	return (
		<Box sx={{ padding: '1rem' }}>
			<Grid container direction={'row'}>
				<Grid item sm={4} md={4}>
					<ByUserInfo />
				</Grid>
				<Grid item sm={4} md={4}>
					<Typography variant={'h4'}>Select Roles</Typography>
					<RolesList
						selectedRoles={selectedRoles}
						setSelectedRoles={setSelectedRoles}
					/>
				</Grid>
				<Grid item sm={4} md={4}>
					<Typography variant={'h4'}>Select Columns</Typography>
					<ColumnsList
						availableColumns={Object.values(IExportUsersByUserColumns)}
						selectedColumns={selectedColumns}
						onColumnsChange={(columns: string[]) =>
							setSelectedColumns(columns as IExportUsersByUserColumns[])
						}
					/>
				</Grid>
			</Grid>
			<ExportButton
				exportDisabled={exportDisabled}
				onExportClick={handleExport}
			/>
		</Box>
	);
};
