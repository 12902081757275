import { createTheme } from '@material-ui/core/styles';
import fonts from './fonts';

declare module '@material-ui/core/styles/createTheme' {
	interface ThemeOptions {
		[key: string]: unknown;
	}
}
const theme = createTheme({
	palette: {
		primary: {
			light: '#D2D7DF',
			main: '#84868A',
			dark: '#5C5E60',
		},
		secondary: {
			light: '#0092D8',
			main: '#0092D8',
			dark: '#044DA2',
		},
		error: {
			main: '#FF3131',
		},
		background: {
			default: '#FFFFFF',
		},
		text: {
			primary: '#353535',
			secondary: '#5C5E60',
		},
		headers: {
			title: {
				color: '#000000',
				fontSize: '2rem',
			},
			subHeader: {
				color: '#000000',
				fontSize: '1.6rem',
			},
			content: {
				color: '#000000',
				fontSize: '1rem',
			},
		},
	},
	greyscale: {
		'100': '#D2D7DF',
		'200': '#ABAFB5',
		'300': '#84868A',
		'400': '#5C5E60',
		'500': '#353535',
	},
	buttons: {
		purpleOutline: '#6559f4',
		primary: '#788896',
		secondary: '#e3e7ea',
	},
	sidePanel: {
		dark: '#c3cfd9',
		light: '#dfe6ee',
	},
	backgrounds: {
		primary: '#FFFFFF',
		dark: '#eff3f6',
		light: '#f8f9fa',
	},
	fontColors: {
		primary: '#293845',
		secondary: '#788896',
	},
	typography: {
		// Fonts
		fontFamily: [
			'IBMPlexSansRegular',
			'IBMPlexSansBold',
			'IBMPlexSansLight',
			'Raleway',
		].join(','),
		fontSize: 12,

		h2: {
			color: '#5C5E60',
		},
		h3: {
			color: '#5C5E60',
		},
		h4: {
			color: '#1F1F1F',
			fontSize: '1.7em',
			fontWeight: 500,
		},
		h5: {
			color: '#5C5E60',
		},
		h6: {
			color: '#5C5E60',
		},
	},
	overrides: {
		MuiCssBaseline: {
			'@global': {
				'button:focus': {
					backgroundColor: 'inherit',
				},
				'button:hover': {
					backgroundColor: 'inherit !important',
				},
				'@font-face': [fonts.ibmPlexSansRegular],
			},
		},
		MuiSvgIcon: {
			root: {
				width: '1.8rem',
				height: '1.8rem',
			},
		},
		MuiTableHead: {
			root: {
				backgroundColor: '#FFFFFF', // white
			},
		},
	},
});

export default theme;
