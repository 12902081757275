import { FC } from 'react';
import { useSelector } from 'react-redux';
import { PersonAddAlt1 } from '@mui/icons-material';
import { IconBtn } from 'common/components/IconBtn';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { IAddUserButtonProps } from '../types';

export const AddUserButton: FC<IAddUserButtonProps> = ({
	setAddSponsor,
	canAddUser,
}) => {
	const grants = useSelector(selectDropdown);
	const { currentSponsor } = grants.grants;

	if (!canAddUser() || currentSponsor.id === 0) return <></>;

	return (
		<IconBtn
			customColor={true}
			onClick={() => setAddSponsor(true)}
			tooltip="Add User"
		>
			<PersonAddAlt1 />
		</IconBtn>
	);
};
