import { makeStyles } from '@material-ui/core';
import { defaultColor } from 'api/redux/siteColorReducer';

export const useStyles = makeStyles((theme) => ({
	appbar: {
		backgroundColor: defaultColor,
		color: '#121212',
		shadows: 'none',
		zIndex: theme.zIndex.drawer + 1,
	},
	toolbarBox: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	dropdownBox: {
		height: '100%',
		width: 'max-content',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	menuBox: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	dropdowns: {
		'& .Mui-disabled': {
			color: 'white !important',
			'-webkit-text-fill-color': 'white !important',
		},
	},
	menuButton: {
		color: '#121212',
		marginRight: theme.spacing(2),
	},
	title: {
		display: 'none',
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},
	navlinks: {
		position: 'relative',
		fontSize: '16px',
		color: 'white',
		display: 'flex',
		flexDirection: 'row',
		borderRadius: theme.shape.borderRadius,
		marginRight: theme.spacing(3),
		marginLeft: '2em',
		alignItems: 'center',
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(3),
			width: 'auto',
		},
	},
	links: {
		color: '#F1F2F9',
		margin: '0 1em',
		fontSize: '16px',
		minWidth: '50px',
		display: 'flex',
	},
	searchIcon: {
		color: 'white',
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
		backgroundColor: 'f2f2f2',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '20ch',
		},
	},
	sectionDesktop: {
		display: 'flex',
	},
	sectionMobile: {
		display: 'flex',
	},
	nameListItem: {
		paddingTop: '2px !important',
		paddingBottom: '2px !important',
		textTransform: 'none',
	},
	large: {
		width: '5rem',
		height: '5rem',
		paddingTop: '2px !important',
		paddingBottom: '2px !important',
	},
	buttonText: {
		textTransform: 'none',
	},
	menuWidth: {
		width: 150,
	},
	logo: {
		fontSize: '2em',
		color: '#FFFFFF',
	},
	notificationIcon: {
		color: '#FFFFFF',
	},
	imgSize: {
		maxWidth: '300px',
		maxHeight: '45px',
		minWidth: '50px',
	},
	select: {
		textAlign: 'left',
		color: '#F1F2F9 !important',
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: '#FFFFFF !important',
		},
		'& .MuiSelect-icon': {
			color: '#FFFFFF !important',
		},
	},
}));
