import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	grid: {
		height: '100%',
		width: 'min(100%, 1200px)',
		margin: '7px 2px 5px 2px',
	},
	paperActivitySummary: {
		padding: theme.spacing(2),
		borderRadius: 5,
		height: '100%',
		overflow: 'auto',
		border: '1px solid #D0D7DE',
	},
	paperContributions: {
		padding: theme.spacing(2),
		borderRadius: 5,
		height: '100%',
		overflow: 'hidden',
		border: '1px solid #D0D7DE',
	},
	paperAnnouncements: {
		padding: theme.spacing(2),
		borderRadius: 5,
		height: '100%',
		overflow: 'auto',
		border: '1px solid #D0D7DE',
	},
	legalDisclaimerText: {
		overflow: 'hidden',
		margin: 20,
		height: '25rem',
	},
	textBox: {
		overflow: 'auto',
		height: '300px',
	},
	activitySummary: {
		height: 'calc(100vh/3 - 25px)',
		width: 'calc(100vw - 100px)',
	},
	contributions: {
		height: 'calc(200vh/3 - 50px)',
		width: 'calc(100vw - 100px)',
	},
	announcements: {
		height: 'calc(200vh/3 - 50px)',
		width: 'calc(100vw - 100px)',
	},
}));
