import { selectDropdown } from 'api/redux/DropdownReducer';
import { FC, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

export const CookiesManager: FC = ({ children }) => {
	const [, setCookie] = useCookies(['fundId', 'sponsorId', 'investorId']);
	const grants = useSelector(selectDropdown);
	const { currentSponsor, currentFund, currentInvestor } = grants.grants;

	useEffect(() => {
		if (currentSponsor.id !== 0)
			setCookie('sponsorId', currentSponsor.id, { path: '/' });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSponsor]);

	useEffect(() => {
		if (currentFund.id !== 0)
			setCookie('fundId', currentFund.id, { path: '/' });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentFund]);

	useEffect(() => {
		if (currentInvestor.id !== 0)
			setCookie('investorId', currentInvestor.id, { path: '/' });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentInvestor]);

	return <>{children}</>;
};
