import { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Paper } from '@material-ui/core';
import Announcements from './Announcements';
import ActivitySummary from './ActivitySummary';
import Contributions from './Contributions';
import { useUpdateLastLoginMutation } from 'api/redux/services/userApi';
import { useAuth0 } from '@auth0/auth0-react';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { selectNavbarMobile } from 'api/redux/NavBarStateReducer';
import { useLazyGetInvestorCapitalActivityQuery } from 'api/redux/services/capitalActivityApi';
import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';
import { useStyles } from './DashboardStyles';
import { PrivacyPolicy } from './PrivacyPolicy';
import { useAuth0Grants } from 'common/helpers/permissions/useGetAuth0Grants';
import { ICapitalActivity } from 'modules/reports/CapitalAccountStatement/capital-activity.types';
import { ROLES } from 'common/helpers/permissions/Roles';
import {
	onSetRedirectURL,
	redirectURLState,
} from 'api/redux/RedirectURLReducer';
import { Redirect } from 'react-router-dom';
import { useLocalUser } from 'common/helpers/permissions/useLocalUser';

export const Dashboard: FC = () => {
	const classes = useStyles();
	const grants = useSelector(selectDropdown);
	const mobile = useSelector(selectNavbarMobile);
	const { currentInvestor, currentFund, currentSponsor } = grants.grants;
	const { currentRoles } = useAuth0Grants();
	const { currentUser } = useLocalUser();
	const [updateLastLogin] = useUpdateLastLoginMutation();
	const [getCapitalActivity, capitalActivity] =
		useLazyGetInvestorCapitalActivityQuery();
	const [currentCAS, setCurrentCAS] = useState<ICapitalActivity[]>([]);
	const { isAuthenticated, user } = useAuth0();
	const dispatch = useDispatch();
	const backgroundStyle = !currentUser?.initialLogin
		? { filter: 'blur(5px)' }
		: {};
	const redirectURL = useSelector(redirectURLState);

	useEffect(() => {
		if (currentFund.id === 0 || currentInvestor.id === 0) {
			setCurrentCAS([]);
			return;
		}

		getCapitalActivity({
			investorId: currentInvestor.id,
			fundId: currentFund.id,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentInvestor, currentFund]);

	useEffect(() => {
		if (!capitalActivity.data) return;

		setCurrentCAS(capitalActivity.data);
	}, [capitalActivity.data]);

	useEffect(() => {
		if (
			!isAuthenticated ||
			!user?.sub ||
			currentRoles.includes(ROLES.SUPER_ADMIN) ||
			!currentSponsor.id ||
			currentSponsor.id === 0
		)
			return;

		updateLastLogin({
			sponsorId: currentSponsor.id,
			fundId: currentFund.id,
			investorId: currentInvestor.id,
		});
	}, [
		currentFund.id,
		currentInvestor.id,
		currentSponsor.id,
		isAuthenticated,
		currentRoles,
		updateLastLogin,
		user?.sub,
	]);

	useEffect(() => {
		const url = sessionStorage.getItem('redirectUrl');

		if (url) {
			sessionStorage.removeItem('redirectUrl');
			dispatch(onSetRedirectURL(url));
		}
	});

	useEffect(() => {
		if (mobile) {
			dispatch(onSetRedirectURL('/documents'));
		}
	}, [dispatch, mobile]);

	if (redirectURL && currentUser?.initialLogin)
		return <Redirect to={redirectURL} />;

	return (
		<MainSidebarContainer>
			<PrivacyPolicy />
			{user && capitalActivity && (
				<Grid
					container
					className={classes.grid}
					spacing={1}
					style={backgroundStyle}
				>
					<Grid
						className={classes.activitySummary}
						item
						xs={12}
						style={{
							height: `${
								mobile ? 'calc(100vh/3 - 35px)' : 'calc(100vh/3 - 25px)'
							}`,
						}}
					>
						<Paper elevation={0} className={classes.paperActivitySummary}>
							<ActivitySummary capitalActivityData={currentCAS} />
						</Paper>
					</Grid>
					<Grid
						className={classes.contributions}
						item
						xs={6}
						style={{
							height: `${
								mobile ? 'calc(200vh/3 - 65px)' : 'calc(200vh/3 - 50px)'
							}`,
						}}
					>
						<Paper elevation={0} className={classes.paperContributions}>
							<Contributions capitalActivityData={currentCAS} />
						</Paper>
					</Grid>
					<Grid
						className={classes.announcements}
						item
						xs={6}
						style={{
							height: `${
								mobile ? 'calc(200vh/3 - 65px)' : 'calc(200vh/3 - 50px)'
							}`,
						}}
					>
						<Paper elevation={0} className={classes.paperAnnouncements}>
							<Announcements />
						</Paper>
					</Grid>
				</Grid>
			)}
		</MainSidebarContainer>
	);
};

export default Dashboard;
