import IBMPlexSansRegular from './IBMPlexSans-Regular.ttf';
import IBMPlexSansLight from './IBMPlexSans-Light.ttf';
import IBMPlexSansBold from './IBMPlexSans-Bold.ttf';
import Raleway from './Raleway-Regular.ttf';
import { Fontface } from '@mui/material/styles/createMixins';

const UNICODE_RANGE =
	'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF';

const ibmPlexSansRegular: Fontface = {
	fontFamily: 'IBMPlexSansRegular',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: '1000',
	src: `
    local('IBMPlexSansRegular'),
   local('IBMPlexSans-Regular'),
   url(${IBMPlexSansRegular}) format('truetype')
 `,
	unicodeRange: UNICODE_RANGE,
};

const ibmPlexSansLight: Fontface = {
	fontFamily: 'IBMPlexSansLight',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: '1000',
	src: `
    local('IBMPlexSansLight'),
   local('IBMPlexSans-Light'),
   url(${IBMPlexSansLight}) format('truetype')
 `,
	unicodeRange: UNICODE_RANGE,
};

const ibmPlexSansBold: Fontface = {
	fontFamily: 'IBMPlexSansBold',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: '1000',
	src: `
    local('IBMPlexSansBold'),
   local('IBMPlexSans-Bold'),
   url(${IBMPlexSansBold}) format('truetype')
 `,
	unicodeRange: UNICODE_RANGE,
};

const raleway: Fontface = {
	fontFamily: 'Raleway',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: '400',
	src: `
    local('Raleway'),
   local('Raleway-Regular'),
   url(${Raleway}) format('truetype')
 `,
	unicodeRange: UNICODE_RANGE,
};

export default {
	ibmPlexSansRegular,
	ibmPlexSansBold,
	ibmPlexSansLight,
	raleway,
};
