import { FC, useCallback, useState } from 'react';
import { useGetOrganizationsQuery } from 'api/redux/services/organizations.service';
import AdminSidebar from 'modules/admin/AdminSideBar';
import { Box, Container } from '@material-ui/core';
import { ManageInvestors } from './ManageInvestors';
import { UploadData } from 'modules/admin/UploadData';
import AdminBreadcrumbs from 'common/components/AdminBreadCrumbs';
import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';
import { useStyles } from './FundPageStyles';
import { useGetFundsQuery } from 'api/redux/services/fundApi';
import { UploadDocuments } from 'modules/admin/UploadDocuments/UploadDocuments';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { useSelector } from 'react-redux';

interface IFundPageProps {}

export const FundPage: FC<IFundPageProps> = () => {
	const classes = useStyles();
	const [currentTab, setCurrentTab] = useState('Investors');
	const { data: organizations = [] } = useGetOrganizationsQuery({});
	const grants = useSelector(selectDropdown);
	const { currentSponsor, currentFund } = grants.grants;
	const thisOrganization = organizations.filter(
		//forcing both f.id and organizationId to be Ints, otherwise comparison fails
		(f) => f.id === currentSponsor.id,
	)[0];

	const { data: funds = [] } = useGetFundsQuery(currentSponsor.id);

	const thisFund = funds.filter((f) => f.id === currentFund.id)[0];

	const onClick = (option) => {
		setCurrentTab(option);
	};

	const tabComponent = useCallback(() => {
		switch (currentTab) {
			case 'Investors':
				return <ManageInvestors />;
			case 'Documents':
				return <UploadDocuments />;
			case 'Upload Data':
				return <UploadData />;
			default:
				return <ManageInvestors />;
		}
	}, [currentTab]);

	return (
		<MainSidebarContainer>
			<Container maxWidth={false} classes={{ root: classes.root }}>
				<Container maxWidth={false} className={classes.sidebar}>
					<AdminSidebar onClick={onClick} />
				</Container>
				<Container maxWidth={false} className={classes.contentSection}>
					<AdminBreadcrumbs
						list={[
							{ text: 'Admin', link: '/admin' },
							{
								text: thisOrganization?.name,
								link: `/superadmin/sponsors`,
							},
							{ text: thisFund?.name },
						]}
					/>
					<Box>{tabComponent}</Box>
				</Container>
			</Container>
		</MainSidebarContainer>
	);
};
