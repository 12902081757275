import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		paddingRight: 0,
		paddingLeft: 0,
		maxWidth: '100%',
		height: '100%',
		overflow: 'auto',
	},
	avatar: {
		marginBottom: theme.spacing(2),
		marginTop: 10,
	},
	viewMoreDetails: {
		height: '3.2rem',
		fontSize: '1.2rem',
		fontWeight: 600,
		color: '#6559f4',
		border: '4px solid #c7c2fb',
		marginBottom: '2rem',
	},
	announcementsHeader: {
		display: 'flex',
		justifyContent: 'center',
	},
	announcementsContainer: {
		marginTop: '2rem',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	textContainer: {
		paddingBottom: '10px',
	},
	buttonContainer: {
		paddingTop: '10px',
		display: 'flex',
		justifyContent: 'space-evenly',
	},
	dialogContainer: {
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	contentWrapper: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: 0,
		alignItems: 'end',
		marginRight: 0,
		padding: '0 !important',
		height: '100vh',
	},
	editButton: {
		float: 'right',
		margin: 0,
		padding: 0,
	},
	deleteButton: {
		float: 'right',
		margin: 0,
		padding: 0,
	},
	scrollGrid: {
		display: 'flex',
		flexDirection: 'column',
	},
	bottomPush: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: 10,
		padding: 24,
	},
	alertMessage: {
		display: 'flex',
		flexDirection: 'row',
	},
}));
