import { Box, Container, Toolbar, makeStyles } from '@material-ui/core';
import { hasPermission } from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { FC } from 'react';
import { OvalButton } from '../../common/components/OvalButton';

interface IAdminSidebar {
	onClick: (option: OptionNames) => void;
}

const useStyles = makeStyles({
	root: {
		width: '100%',
		display: 'flex',
		backgroundColor: '#d4d9ec',
		paddingRight: 0,
		paddingLeft: 0,
		maxWidth: '100%',
	},
	title: {
		paddingRight: 15,
		marginLeft: 5,
		fontSize: 18,
		fontWeight: 'bold',
		marginBottom: 10,
	},
	buttonWrapper: {
		color: 'black',
		fontWeight: 'bold',
		paddingRight: 0,
		paddingLeft: 0,
		textAlign: 'right',
		borderBottomLeftRadius: '25px 25px',
		borderTopLeftRadius: '25px 25px',
	},
	contentWrapper: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: 0,
		alignItems: 'end',
		marginRight: 0,
		paddingRight: 0,
		paddingLeft: 0,
		paddingTop: 10,
		height: '100vh',
	},
});

const enum OptionNames {
	Investors = 'Investors',
	Documents = 'Documents',
	UploadData = 'Upload Data',
}

const AdminSidebar: FC<IAdminSidebar> = ({ onClick }) => {
	const classes = useStyles();
	const options = [
		OptionNames.Investors,
		OptionNames.Documents,
		OptionNames.UploadData,
	];

	const hideProtectedMenuOption = (
		optionName: OptionNames,
		scopes: SCOPES[],
	): void => {
		if (!hasPermission({ scopes })) {
			const bulkUploadOptionIndex = options.indexOf(optionName);
			options.splice(bulkUploadOptionIndex, 1);
		}
	};

	hideProtectedMenuOption(OptionNames.Documents, [
		SCOPES.canSeeBulkUploadOption,
	]);

	hideProtectedMenuOption(OptionNames.UploadData, [
		SCOPES.canSeeUploadDataOption,
	]);

	return (
		<Toolbar classes={{ root: classes.root }}>
			<Container className={classes.contentWrapper}>
				<Box className={classes.title}>Fund Management</Box>
				<Box>
					{options.map((option, i) => (
						<OvalButton
							onClick={() => onClick(option)}
							label={option}
							id={i}
							selectedDocumentType={{
								id: 0,
								name: '',
							}}
							key={i}
						/>
					))}
				</Box>
			</Container>
		</Toolbar>
	);
};

export default AdminSidebar;
