import { FC } from 'react';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { useStyles } from './styles';
import { Box, Button } from '@material-ui/core';

export const BulkUploadUserTemplate: FC = () => {
	const classes = useStyles();
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const headers = [
		{ label: 'User Name', key: 'details.UN' },
		{ label: 'User Email', key: 'details.UE' },
		{ label: 'Role', key: 'details.R' },
		{ label: 'INV ID', key: 'details.IID' },
	];
	const data = [
		{
			details: {
				UN: '',
				UE: '',
				R: '',
				IID: '',
			},
		},
	];
	const csvReport = {
		data,
		headers,
		filename: 'BulkUploadUserTemplate.csv',
	};

	return (
		<Box>
			<Button
				variant="outlined"
				className={classes.templateButton}
				style={{ backgroundColor: `${sponsorColor}` }}
			>
				<CSVLink className={classes.csvLink} {...csvReport}>
					Download Template
				</CSVLink>
			</Button>
		</Box>
	);
};
