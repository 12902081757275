import { useStyles } from './styles';
import { IDropAreaProps } from './types';
import { Button, Box, Typography, Card } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { FC } from 'react';

export const DropArea: FC<IDropAreaProps> = ({
	visible,
	sponsor,
	setIsUploading,
	uploadMethod,
	setUploadFinished,
	setIsEditing,
}) => {
	const classes = useStyles();

	const {
		acceptedFiles: acceptedImages,
		getInputProps,
		getRootProps,
	} = useDropzone({
		accept: {
			'image/png': ['.png'],
			'image/gif': ['.gif'],
			'image/jpg': ['.jpg'],
			'image/jpeg': ['.jpeg'],
		},
	});

	const handleLogoUpload = async () => {
		const formData = new FormData();
		setIsUploading(true);

		formData.append('files', acceptedImages[0]);
		formData.append('sponsor', sponsor.name);

		await uploadMethod({ formData });

		setUploadFinished(true);
		setIsUploading(false);
		setIsEditing(false);
		acceptedImages.splice(0, acceptedImages.length + 1);
	};

	const AcceptedFiles: FC = () => {
		if (acceptedImages.length === 0) return <></>;

		return (
			<Box className={classes.fileCardContainer}>
				{acceptedImages.map((file, i) => (
					<Card key={i} className={classes.fileCard}>
						<Typography style={{ fontWeight: 'bold', fontSize: '.8em' }}>
							File Name
						</Typography>
						<Typography>{file.name}</Typography>
					</Card>
				))}
			</Box>
		);
	};

	const UploadButton: FC = () => {
		if (acceptedImages.length === 0) return <></>;

		return (
			<Button
				onClick={handleLogoUpload}
				className={classes.button}
				variant="outlined"
			>
				Upload
			</Button>
		);
	};

	if (!visible) return <></>;

	return (
		<>
			<Box {...getRootProps({ className: classes.dropzone })}>
				<input {...getInputProps({ disabled: false })} />

				<Button>
					<div className={classes.buttonContainer}>
						<p className={`material-icons ${classes.icon}`}>cloud_upload</p>
						<Typography>Drag and drop or browse to choose a file</Typography>
					</div>
				</Button>
			</Box>
			<AcceptedFiles />
			<UploadButton />
		</>
	);
};
