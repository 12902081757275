import { FC } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { NavLink } from 'react-router-dom';
import { useStyles } from './styles';
import { IMenuProps, standardMenuId } from './types';

export const StandardMenu: FC<IMenuProps> = ({
	anchorEl,
	handleClose,
	isOpen,
	logout,
}) => {
	const classes = useStyles();

	return (
		<Menu
			anchorEl={anchorEl}
			getContentAnchorEl={null}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			id={standardMenuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'center' }}
			open={isOpen}
			onClose={handleClose}
		>
			<MenuItem
				onClick={handleClose}
				component={NavLink}
				className={classes.menuWidth}
				to="/help"
			>
				Help
			</MenuItem>
			<MenuItem onClick={logout}>Log Out</MenuItem>
		</Menu>
	);
};
