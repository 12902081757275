import { FC } from 'react';
import { Edit } from '@material-ui/icons';
import { Typography, IconButton } from '@material-ui/core';
import { useStyles } from './UpdateDisclaimerStyles';
import { ILabelBoxProps } from './UpdateDisclaimerTypes';

export const LabelBox: FC<ILabelBoxProps> = ({
	show,
	sponsor,
	isEditing,
	setIsEditing,
}) => {
	const classes = useStyles();

	if (!show) return <></>;

	return (
		<div className={classes.container}>
			<div className={classes.uneditable}>
				<div className={classes.wrapper}>
					<Typography variant={'h4'}>Disclaimer</Typography>
					<span>{sponsor.disclaimer}</span>
				</div>
				<IconButton size={'small'} onClick={() => setIsEditing(!isEditing)}>
					<Edit />
				</IconButton>
			</div>
		</div>
	);
};
