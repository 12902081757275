import { Table as MuiTable, withStyles } from '@material-ui/core';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/styles';
import { ETimePeriodSuffixes, IColumn, ILabel, IRow } from '../types';
import { CSSProperties } from 'react';
import {
	CapitalCommitmentTableLabels,
	CellValueFormats,
	RowNames,
	TemplateType,
} from './types';

export const useStyles = makeStyles({
	columnLabels: {
		fontSize: 'min(2vh, .9rem)',
		color: '#FFFFFF',
		textAlign: 'center',
	},
	columnSubLabels: {
		fontSize: 'min(2vh, 1rem)',
		textAlign: 'center',
	},
	rowLabels: {
		fontSize: 'min(2vh, 1rem)',
	},
	tablePaper: {
		paddingBottom: 10,
		border: '1px solid #D0D7DE',
		borderRadius: '5px 5px 0px 0px',
		overflow: 'hidden',
	},
});

export const Table = withStyles({
	root: {
		borderCollapse: 'separate',
		'& tr.content-row:hover': {
			backgroundColor: '#eee',
		},
		'& .MuiTableCell-sizeSmall': {
			padding: '0px 10px 0px 10px',
		},
	},
})(MuiTable);

export const TableCell = withStyles({
	root: {
		borderBottom: 'none',
		fontSize: 'min(2vh, 1rem)',
		borderRadius: '0px',
	},
})(MuiTableCell);

export const TableRow = withStyles({
	root: {
		borderBottom: 'none',
		fontSize: 'min(2vh, 1rem)',
	},
})(MuiTableRow);

export const cellLabelStyle = (row: IRow): CSSProperties => {
	return {
		whiteSpace: 'nowrap',
		fontWeight: row.label.formats.includes('BOLD') ? 'bold' : 'normal',
		paddingLeft: row.label.formats.includes('INDENT') ? 10 : 0,
	};
};

export const cellFontWeight = (label: ILabel): string =>
	label.formats.includes('BOLD') ? 'bold' : 'normal';

export const underlineCell = (
	row: IRow,
	column: IColumn,
	color: string,
	templateType: TemplateType,
): string => {
	if (
		CapitalCommitmentTableLabels.includes(row.label.text as RowNames) &&
		column.timePeriodSuffix === ETimePeriodSuffixes.PTD &&
		templateType === TemplateType.ITD
	)
		return '';

	if (row.valueFormats.includes(CellValueFormats.DOUBLE_UNDERLINE)) {
		return `double 3.75px ${color}`;
	}

	return '0';
};

export const topLineCell = (
	row: IRow,
	column: IColumn,
	color: string,
	templateType: TemplateType,
): string => {
	if (
		CapitalCommitmentTableLabels.includes(row.label.text as RowNames) &&
		column.timePeriodSuffix === ETimePeriodSuffixes.PTD &&
		templateType === TemplateType.ITD
	)
		return '';

	if (row.valueFormats.includes(CellValueFormats.TOP_LINE)) {
		return `solid 1.25px ${color}`;
	}

	return '0';
};
