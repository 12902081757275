import { FC } from 'react';
import { MenuItem, InputLabel, FormControl, Select } from '@material-ui/core';
import { onChangeInvestor, selectDropdown } from 'api/redux/DropdownReducer';
import { useStyles } from '../styles';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ERoutes } from 'App';
import { IInvestorGrant } from 'api/redux/services/userApi';
import { onSetSelectedDocTypeIds } from 'api/redux/DocumentsReducer';

interface IDynamicInvestors {
	allowedInvestors: IInvestorGrant[];
}

const FixedAllinvestors: FC = () => {
	const classes = useStyles();

	return (
		<FormControl
			variant="outlined"
			size="small"
			style={{ margin: 5, width: 200 }}
			className={classes.dropdowns}
		>
			<InputLabel id="investor-selector" style={{ color: '#F1F2F9' }}>
				All Investors
			</InputLabel>
			<Select
				labelId="investor-selector"
				id="investor-selector"
				value={''}
				label="Investor"
				className={classes.select}
				disabled={true}
			>
				<MenuItem key={0} value={0}>
					All Investors
				</MenuItem>
			</Select>
		</FormControl>
	);
};

const DynamicInvestors: FC<IDynamicInvestors> = ({ allowedInvestors }) => {
	const classes = useStyles();
	const grants = useSelector(selectDropdown);
	const dispatch = useDispatch();
	const { currentInvestor } = grants.grants;

	return (
		<FormControl
			variant="outlined"
			size="small"
			style={{ margin: 5, width: 200 }}
			className={classes.dropdowns}
		>
			<InputLabel id="investor-selector" style={{ color: '#F1F2F9' }}>
				Investor
			</InputLabel>
			<Select
				labelId="investor-selector"
				id="investor-selector"
				value={currentInvestor.id}
				label="Investor"
				className={classes.select}
			>
				{allowedInvestors.map((investor, index) => (
					<MenuItem
						onClick={() => {
							dispatch(onChangeInvestor(investor));
							dispatch(onSetSelectedDocTypeIds([]));
						}}
						key={index}
						value={investor.id}
					>
						{investor.name}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export const InvestorDropdown: FC = () => {
	let allowedInvestors: IInvestorGrant[] = [];
	const { pathname } = useLocation();
	const isOrganizationPage = [
		ERoutes.OrganizationView,
		ERoutes.InvestorsView,
	].includes(pathname as ERoutes);
	const grants = useSelector(selectDropdown);
	const {
		availableInvestors,
		allAvailableInvestors,
		currentSponsor,
		currentFund,
	} = grants.grants;

	if (
		!isOrganizationPage &&
		currentSponsor.id !== 0 &&
		currentFund.id !== 0 &&
		availableInvestors.length > 0
	)
		allowedInvestors = availableInvestors;

	if (
		isOrganizationPage &&
		currentSponsor.id !== 0 &&
		allAvailableInvestors.length > 0
	)
		allowedInvestors = allAvailableInvestors;

	if (allowedInvestors.length < 1) return <></>;

	if ([ERoutes.OrganizationView].includes(pathname as ERoutes))
		return <FixedAllinvestors />;

	return <DynamicInvestors allowedInvestors={allowedInvestors} />;
};
