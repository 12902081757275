import { Investor } from 'app/types/investor.types';
import { SetStateAction } from 'react';

export interface IValidateNameParams {
	name: string;
	setNameError: (value: boolean) => void;
	investor?: Investor;
	investors?: Investor[];
	investorId: string;
}

export interface IValidateInvestorIdParams {
	investorId: string;
	setInvestorIdError: (value: boolean) => void;
	investor?: Investor;
	investors?: Investor[];
	name: string;
	setInvestorId: (value: SetStateAction<string>) => void;
}

const stripSpecialCharacters = (value: string): string => {
	return value.replace(/[^a-zA-Z0-9 ]/g, '');
};

const doesNameExist = (
	name: string,
	investors: Investor[],
	investorId: string,
	currentInvestorId?: number,
): boolean => {
	return investors
		.filter((inv) => inv.id !== currentInvestorId)
		.some(
			(i) =>
				stripSpecialCharacters(i.name.toLowerCase()) ===
					stripSpecialCharacters(name.toLowerCase()) &&
				i.id !== currentInvestorId &&
				i.investorId !== investorId,
		);
};

export const validateName = ({
	name,
	setNameError,
	investor,
	investors,
	investorId,
}: IValidateNameParams): void => {
	if (!name) {
		setNameError(false);
		return;
	}

	const nameExists = doesNameExist(
		name,
		investors || [],
		investorId,
		investor?.id,
	);
	setNameError(nameExists);
};

export const validateInvestorId = ({
	investorId,
	setInvestorIdError,
	investor,
	investors,
	name,
	setInvestorId,
}: IValidateInvestorIdParams): void => {
	if (!investorId) {
		setInvestorIdError(false);
		return;
	}

	const investorExists = investors
		?.filter((inv) => inv.id !== investor?.id)
		.some((i) => i.investorId === investorId && i.name !== name);
	setInvestorIdError(Boolean(investorExists));

	const validInvestorId = `INV${investorId.replace(/\D/g, '').slice(0, 4)}`;
	if (investorId !== validInvestorId) {
		setInvestorId(validInvestorId);
	}
};

export const validateNameType = (name: any): string => {
	if (typeof name === 'string') {
		return name;
	}
	return typeof name === 'object' && !Array.isArray(name) && name !== null
		? ''
		: '';
};
