import { FC } from 'react';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { IDocumentLevel, documentLevels } from './types';
import { useDispatch, useSelector } from 'react-redux';
import {
	docsUploadState,
	onSetDocumentLevel,
} from 'api/redux/DocsUploadReducer';

export const DocumentLevelSelector: FC = () => {
	const { selectedLevel } = useSelector(docsUploadState);
	const dispatch = useDispatch();

	return (
		<>
			<InputLabel id="doc-level-select-label">
				Select a Document Level
			</InputLabel>
			<Select
				id="select-outlined"
				value={selectedLevel.id}
				onChange={(e) => {
					const level = documentLevels.find(
						(l) => l.id === Number(e.target.value),
					);
					if (level) dispatch(onSetDocumentLevel(level));
				}}
				sx={{
					width: '100%',
				}}
			>
				{documentLevels.map((level: IDocumentLevel) => (
					<MenuItem key={level.id} value={level.id}>
						{level.name}
					</MenuItem>
				))}
			</Select>
		</>
	);
};
