import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Box } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import { csvData, csvHeaders } from './types';
import { useGetInvestorsQuery } from 'api/redux/services/investors.service';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() =>
	createStyles({
		csvButton: {
			backgroundColor: 'white',
			border: '#bfbfbf 1px solid',
			maxWidth: 'fit-content',
			margin: '10px',
			padding: '3px',
		},
		select: {
			width: '25%',
			padding: '10px',
		},
		selectLabel: {
			padding: '4px',
		},
	}),
);

export const CSVTemplate: React.FC = () => {
	const grants = useSelector(selectDropdown);
	const { currentFund } = grants.grants;
	const classes = useStyles();
	const { data: investors } = useGetInvestorsQuery(currentFund.id);

	if (!investors) return <></>;

	const csvReport = {
		data: csvData(investors),
		headers: csvHeaders,
		filename: 'CapitalActivityTemplate.csv',
	};

	return (
		<Box>
			<Button className={classes.csvButton}>
				<CSVLink {...csvReport}> Download Template </CSVLink>
			</Button>
		</Box>
	);
};
