import { FC } from 'react';
import { useStyles } from './styles';
import { IInvestorContactInfoProps } from './types';
import { Typography } from '@material-ui/core';
import { Grid, TextField } from '@mui/material';

export const InvestorContactInfo: FC<IInvestorContactInfoProps> = ({
	show,
	investor,
	phone,
	setPhone,
	email,
	setEmail,
	addressLine1,
	setAddressLine1,
	addressLine2,
	setAddressLine2,
	city,
	setCity,
	selectedState,
	setSelectedState,
	selectedCountry,
	setSelectedCountry,
	zipcode,
	setZipcode,
}) => {
	const classes = useStyles();

	if (!show) return <></>;

	return (
		<>
			<Typography variant="subtitle1" className={classes.sectionTitle}>
				Contact Information
			</Typography>
			<Grid container spacing={2} className={classes.controlsBox}>
				<Grid item xs={6}>
					<TextField
						autoFocus={investor?.id !== undefined}
						label="Phone"
						variant="outlined"
						fullWidth
						inputProps={{
							style: {
								borderBottom: 'none',
								paddingLeft: 20,
							},
						}}
						value={phone || ''}
						onChange={(e) => setPhone(e.target.value)}
					/>
				</Grid>

				<Grid item xs={6}>
					<TextField
						label="Email"
						variant="outlined"
						fullWidth
						inputProps={{
							style: {
								borderBottom: 'none',
								paddingLeft: 20,
							},
						}}
						type="email"
						value={email || ''}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</Grid>
			</Grid>

			<Typography variant="subtitle1" className={classes.sectionTitle}>
				Address
			</Typography>
			<Grid container spacing={2} className={classes.controlsBox}>
				<Grid item xs={6}>
					<TextField
						label="Address Line 1"
						variant="outlined"
						fullWidth
						inputProps={{
							style: {
								borderBottom: 'none',
								paddingLeft: 20,
							},
						}}
						value={addressLine1 || ''}
						onChange={(e) => setAddressLine1(e.target.value)}
					/>
				</Grid>

				<Grid item xs={6}>
					<TextField
						label="Address Line 2"
						variant="outlined"
						fullWidth
						inputProps={{
							style: {
								borderBottom: 'none',
								paddingLeft: 20,
							},
						}}
						value={addressLine2 || ''}
						onChange={(e) => setAddressLine2(e.target.value)}
					/>
				</Grid>

				<Grid item xs={6}>
					<TextField
						label="City"
						variant="outlined"
						fullWidth
						inputProps={{
							style: {
								borderBottom: 'none',
								paddingLeft: 20,
							},
						}}
						value={city || ''}
						onChange={(e) => setCity(e.target.value)}
					/>
				</Grid>

				<Grid item xs={6}>
					<TextField
						label="State"
						variant="outlined"
						fullWidth
						inputProps={{
							style: {
								borderBottom: 'none',
								paddingLeft: 20,
							},
						}}
						value={selectedState || ''}
						onChange={(e) => setSelectedState(e.target.value)}
					/>
				</Grid>

				<Grid item xs={6}>
					<TextField
						label="Zip Code"
						variant="outlined"
						fullWidth
						inputProps={{
							style: {
								borderBottom: 'none',
								paddingLeft: 20,
							},
						}}
						value={zipcode || ''}
						onChange={(e) => setZipcode(e.target.value)}
					/>
				</Grid>

				<Grid item xs={6}>
					<TextField
						label="Country"
						variant="outlined"
						fullWidth
						inputProps={{
							style: {
								borderBottom: 'none',
								paddingLeft: 20,
							},
						}}
						value={selectedCountry || ''}
						onChange={(e) => setSelectedCountry(e.target.value)}
					/>
				</Grid>
			</Grid>
		</>
	);
};
