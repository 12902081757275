import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
	wrapper: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
		gap: '1em',
	},
	container: {
		marginLeft: 'auto',
		marginRight: 'auto',

		marginTop: 15,
		marginBottom: 15,
		padding: 15,
	},
	buttonContainer: {
		marginTop: 15,
		marginRight: 55,
		display: 'flex',
		justifyContent: 'flex-end',
	},
	progress: {
		width: '80%',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	uneditable: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
	},
});
