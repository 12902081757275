import {
	Button,
	Box,
	Typography,
	LinearProgress,
	TextField,
	IconButton,
} from '@material-ui/core';
import { Close, Edit } from '@material-ui/icons';
import { FC } from 'react';
import { useStyles } from './UpdateDisclaimerStyles';
import { IEditBoxProps, MAX_DISCLAIMER_CHARS } from './UpdateDisclaimerTypes';

export const EditBox: FC<IEditBoxProps> = ({
	show,
	isLoading,
	isEditing,
	setIsEditing,
	disclaimer,
	setDisclaimer,
	handleSubmit,
}) => {
	const classes = useStyles();

	if (!show) return <></>;

	return (
		<Box className={classes.container}>
			<Box className={classes.progress}>
				{isLoading && <LinearProgress color="secondary" />}
			</Box>

			<Box className={classes.wrapper}>
				<Typography variant={'h4'} style={{ whiteSpace: 'nowrap' }}>
					Disclaimer
				</Typography>
				<TextField
					variant="outlined"
					fullWidth
					inputProps={{
						maxLength: MAX_DISCLAIMER_CHARS,
						style: {
							borderBottom: 'none',
							paddingLeft: 20,
						},
					}}
					value={disclaimer}
					onChange={(event) => setDisclaimer(event.target.value)}
				/>
				<IconButton onClick={() => setIsEditing(!isEditing)}>
					{isEditing ? <Close /> : <Edit />}
				</IconButton>
			</Box>

			<Box className={classes.buttonContainer}>
				<Button variant="contained" onClick={handleSubmit}>
					Save
				</Button>
			</Box>
		</Box>
	);
};
